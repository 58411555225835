import React from 'react'
import Icon from '@material-ui/core/Icon'

interface Props {
    icon: string
}

const MaterialIcon = ({ icon }: Props) => {
    return (
        <Icon color={'primary'}>{icon}</Icon>
    )
}

export default MaterialIcon