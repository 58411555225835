import { Box, BoxProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';
import { useViewport } from '../../hooks/responsive';
import HtmlRenderer from '../../../../common/components/HTMLRenderer/HTMLRenderer';

interface Props extends BoxProps {
    title: string,
    children: ReactNode;
    headerStyles?: React.CSSProperties;
}


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    accordion: {
        borderTop: '1px solid rgba(0,0,0,0.05)',
        borderBottom: '1px solid rgba(0,0,0,0.05)',
    },
    summary: {
        paddingLeft: 0,
        paddingRight: 0,
        margin: '8px 0'
    },
    detailsContainer: {
        paddingLeft: 0,
        paddingTop: 0,
    },
    header: {
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '32px',
        marginBottom: '28px',
        color: "#212B36"
    },
    heading: {
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '32px'
    },
    details: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px'
    }
}));



const AppBox = ({ title, children, headerStyles, ...props }: Props) => {
    const classes = useStyles();
    const { isMobile, isTabletScreen } = useViewport();

    return (
        <Box className={`reservation-section ${isMobile ? 'mobile' : ''} ${isTabletScreen ? 'tablet' : ''} `} {...props}>
            <Typography className={classes.header} style={headerStyles}>
                <HtmlRenderer html={title} />
            </Typography>
            {children}
        </Box>
    )
}

export default AppBox