import { Box, BoxProps, Typography } from '@material-ui/core'
import React from 'react'
import { ExpandMore } from '@material-ui/icons';

interface Props extends BoxProps {
    icon: React.ReactNode,
    withArrow?: boolean,
    title: string,
    disabled?: boolean,
    noBgcolor?: boolean
}

const AccountMenuItem = ({ icon, withArrow, title, disabled, noBgcolor, ...props }: Props) => {
    return (
        <Box p='16px 24px' bgcolor={(disabled || noBgcolor) ? 'transparent' : '#F6F7FA'} borderRadius={'8px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} {...props}>
            <Box display={'flex'} style={{ gap: '12px' }}>
                <Box>
                    {icon}
                </Box>
                <Typography className='root bold'>{title}</Typography>
            </Box>
            {withArrow && <ExpandMore />}
        </Box>
    )
}

export default AccountMenuItem