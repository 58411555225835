import { createSelector } from "reselect";
import { AuthState, State, ProfileDto, Guest } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: AuthState = Object.freeze({
  isRequestInProgress: false,
  isPasswordChanged: false,
  isRecoverCompleted: false,
  authenticated: false,
  verified: false,
  isShowVerificationCodeModal: false,
});

export default createReducer<AuthState>(initialState, {
  [ActionTypeEnum.AuthRequest]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    isPasswordChanged: false,
    error: "",
  }),
  [ActionTypeEnum.AuthSuccess]: (client: ProfileDto) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    guest: undefined,
    authenticated: true,
    client,
    verified: false,
    isShowVerificationCodeModal: false,
    error: "",
  }),
  [ActionTypeEnum.AuthSetClient]: (client: ProfileDto) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    guest: undefined,
    client,
    verified: false,
    isShowVerificationCodeModal: false,
    error: "",
  }),
  [ActionTypeEnum.AuthtorizeSession]: () => (state: AuthState) => ({
    ...state,
    authenticated: true
  }),
  [ActionTypeEnum.AuthFailure]: (error: string) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    authenticated: false,
    client: undefined,
    error,
  }),
  [ActionTypeEnum.AuthRecoverRequest]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    isRecoverCompleted: false,
    error: "",
  }),
  [ActionTypeEnum.AuthRecoverSuccess]:
    (client: ProfileDto) => (state: AuthState) => ({
      ...state,
      isRequestInProgress: false,
      isRecoverCompleted: true,
      guest: undefined,
      error: "",
    }),
  [ActionTypeEnum.AuthRecoverFailure]:
    (error: string) => (state: AuthState) => ({
      ...state,
      isRequestInProgress: false,
      isRecoverCompleted: false,
      error,
    }),
  [ActionTypeEnum.ResetPasswordRequest]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    isPasswordChanged: false,
    error: "",
  }),
  [ActionTypeEnum.ResetPasswordSuccess]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    isPasswordChanged: true,
    error: "",
  }),
  [ActionTypeEnum.ResetPasswordFailure]:
    (error: string) => (state: AuthState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.AuthResetRecoverStatus]: () => (state: AuthState) => ({
    ...state,
    isRecoverCompleted: false,
  }),
  [ActionTypeEnum.Logout]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    authenticated: false,
    client: undefined,
    verified: false,
    isShowVerificationCodeModal: false,
    error: "",
  }),
  [ActionTypeEnum.UpdateProfileRequest]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.UpdateProfileSuccess]:
    (client: ProfileDto) => (state: AuthState) => ({
      ...state,
      isRequestInProgress: false,
      client,
      verified: false,
      isShowVerificationCodeModal: false,
      error: "",
    }),
  [ActionTypeEnum.UpdateProfileFailure]:
    (error: string) => (state: AuthState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.ChangePasswordRequest]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    isPasswordChanged: false,
    error: "",
  }),
  [ActionTypeEnum.ChangePasswordSuccess]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    isPasswordChanged: true,
    error: "",
  }),
  [ActionTypeEnum.ChangePasswordFailure]:
    (error: string) => (state: AuthState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.CleanAuthError]: () => (state: AuthState) => ({
    ...state,
    error: "",
  }),
  [ActionTypeEnum.GetGuest]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.GetGuestSuccess]: (guest: Guest) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    guest,
    verified: false,
    isShowVerificationCodeModal: false,
    error: "",
  }),
  [ActionTypeEnum.GetGuestFailure]: (error: string) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    guest: undefined,
    error,
  }),
  [ActionTypeEnum.ReservationAuthRequest]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.ReservationAuthSuccess]:
    (user: any) => (state: AuthState) => {
      if (user?.isClient) {
        return {
          ...state,
          isRequestInProgress: false,
          client: user,
          verified: false,
          isShowVerificationCodeModal: false,
          authenticated: true,
          guest: undefined,
          error: "",
        };
      } else {
        return {
          ...state,
          isRequestInProgress: false,
          guest: user,
          authenticated: true,
          client: undefined,
          verified: false,
          isShowVerificationCodeModal: false,
          error: "",
        };
      }
    },
  [ActionTypeEnum.ReservationAuthFailure]:
    (error: string) => (state: AuthState) => ({
      ...state,
      isRequestInProgress: false,
      authenticated: false,
      guest: undefined,
      client: undefined,
      error,
    }),
  [ActionTypeEnum.LogoutAsGuest]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    guest: undefined,
    error: "",
  }),
  [ActionTypeEnum.SetIsShowVerificationCodeModal]: (isShowVerificationCodeModal?: boolean) => (state: AuthState) => ({
    ...state,
    isShowVerificationCodeModal,
  }),
  [ActionTypeEnum.GetVerificationCode]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    isShowVerificationCodeModal: false,
    error: "",
  }),
  [ActionTypeEnum.GetVerificationCodeSuccess]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    isShowVerificationCodeModal: true,
    error: "",
  }),
  [ActionTypeEnum.GetVerificationCodeFailure]: (error: string) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.ConfirmVerification]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    verified: false,
    error: "",
  }),
  [ActionTypeEnum.ConfirmVerificationSuccess]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    verified: true,
    error: "",
  }),
  [ActionTypeEnum.ConfirmVerificationFailure]: (error: string) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.UnSubscribeToPlan]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    verified: false,
    error: "",
  }),
  [ActionTypeEnum.UnSubscribeToPlanSuccess]: (client: ProfileDto) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    client,
    error: "",
  }),
  [ActionTypeEnum.UnSubscribeToPlanFailure]: (error: string) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.SubscribeToPlanFailure]: (error: string) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.SubscribeToPlanSuccess]: (error: string) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    error: '',
  }),
  [ActionTypeEnum.GetBillingSession]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.GetBillingSessionSuccess]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    error: "",
  }),
  [ActionTypeEnum.GetBillingSessionFailure]: (error: string) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.ClearAuthError]: () => (state: AuthState) => ({
    ...state,
    error: "",
  }),
});

export const selectAuth = (state: State) => state.auth;
export const selectAuthenticated = createSelector(
  selectAuth,
  (state: AuthState) => state.authenticated
);
export const selectIsPasswordChanged = createSelector(
  selectAuth,
  (state: AuthState) => state.isPasswordChanged
);
export const selectIsRecoverCompleted = createSelector(
  selectAuth,
  (state: AuthState) => state.isRecoverCompleted
);
export const selectIsAuthRequestInProgress = createSelector(
  selectAuth,
  (state: AuthState) => state.isRequestInProgress
);
export const selectClient = createSelector(
  selectAuth,
  (state: AuthState) => state.client
);
export const selectGuest = createSelector(
  selectAuth,
  (state: AuthState) => state.guest
);
export const selectAuthError = createSelector(
  selectAuth,
  (state: AuthState) => state.error
);
export const selectVerified = createSelector(
  selectAuth,
  (state: AuthState) => state.verified
);
export const selectIsShowVerificationCodeModal = createSelector(
  selectAuth,
  (state: AuthState) => state.isShowVerificationCodeModal
);

export const selecAuthtError = createSelector(
  selectAuth,
  (state: AuthState) => state.error
);