import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import auth from "./auth-reducer";
import membership from "./membership";
import reservation from "./reservation";
import uiState from "./ui-reducer";
import venues from "./venues";

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    venues,
    reservation,
    uiState,
    membership
  } as any);

export default rootReducer;
