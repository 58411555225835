import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { CheckInStatus } from '../../../../../server/src/entities/enums'
interface Props {
  status: string
}

const ReservationStatusCaption = ({ status }: Props) => {
  return (
    <Box className={`reservation-caption-v2 ${status}`}>
      <Typography className="caption-small-bold">{status}</Typography>
    </Box>
  )
}

export default ReservationStatusCaption