import { Box, Typography } from "@material-ui/core";
import { getHash, getSearch, goBack, replace } from "connected-react-router";
import dayjs from "dayjs";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import { CONFIRM_DATE_FORMAT } from "../../../../common/constants/timedate";
import { formatDuration, formatOccasionsAndGuestDetails, slotToTime } from "../../../../common/utils/formats";
import ThanksIcon from '../../../assets/thanks.svgr';
import { updateReservationAction } from "../../actions/reservation-actions";
import { backToReservationAction, changeVenuePackageAction, changeVenuePackageWithReservationAction, parseHash } from "../../actions/venue-actions";
import { useViewport } from "../../hooks/responsive";
import { selectIsUpdateReservation, selectParty, selectReservation } from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { selectIsLoadingDateOrVenue, selectIsLoadingVenues, selectVenue, selectVenues } from "../../reducers/venues";
import { State } from "../../store/types";
import "../MakePackageReservation/makePackageReservation.scss";
import MakeReservationSummary from "../MakeReservation/MakeReservationSummary";
import ButtonMain from "../common/ButtonMain/ButtonMain";
import DefaultContainer from "../common/DefaultContainer/DefaultContainer";
import Footer from "../common/Footer/Footer";
import "./makePartyConfirmation.scss";
import Header from "../common/Header/Header";

const mapStateToProps = (state: State) => ({
    party: selectParty(state),
    venue: selectVenue(state),
    uiConfig: selectUIConfig(state),
    venues: selectVenues(state),
    searchParams: getSearch(state),
    hashParams: getHash(state),
    isUpdateReservation: selectIsUpdateReservation(state),
    isLoadingVenues: selectIsLoadingVenues(state),
    isLoadingVenueOrDate: selectIsLoadingDateOrVenue(state),
    reservation: selectReservation(state),
});

const mapDispatchToProps = {
    changeVenuePackage: changeVenuePackageAction,
    updateReservation: updateReservationAction,
    goBack,
    replace,
    changeVenuePackageWithReservation: changeVenuePackageWithReservationAction,
    backToReservation: backToReservationAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const BookingConfirmation = ({ party, venue, uiConfig, backToReservation, venues, reservation, hashParams, searchParams, changeVenuePackage, isLoadingVenueOrDate, isLoadingVenues }: Props) => {
    const { isMobile } = useViewport();
    if (!party || !venue) {
        window.location.href = "/";
        return null;
    }
    const { date, timeSlot, email, occasion, guestCount, duration, } = party!;
    const oc = formatOccasionsAndGuestDetails(uiConfig?.occasions).find((item) => item.key === occasion)
    const occasionText = oc?.key || occasion;
    const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;

    const partyTimeSlotDuration = 30;
    const time = slotToTime(timeSlot, partyTimeSlotDuration, twelveHourClockFormat);
    const formattedDate = dayjs(date).format(CONFIRM_DATE_FORMAT);
    const partyDuration = formatDuration(duration);
    const eventConfirmationPageTitle = venue?.eventConfirmationPageTitle || 'Thank you for inquiring about a party!';
    const eventConfirmationPageCopy = venue?.eventConfirmationPageCopy || "";

    const { packagesTag } = parseHash(hashParams);
    const { guests } = reservation;

    const selectVenueOption = (newVenueId?: number | string) => {
        const newVenue = venues.find((venue) => venue.id == newVenueId);
        if (newVenueId && newVenue) {
            const recountedGuests = newVenue.maxGuests >= guests ? guests : 1;
            if (!!packagesTag) {
                replace({ search: searchParams, hash: hashParams, pathname: `/packages/${newVenueId}` });
            }
            changeVenuePackage({
                updatePackage: undefined,
                venueId: newVenueId.toString(),
                date,
                guests: recountedGuests,
                changeDateOrVenue: true
            });
            backToReservation()
        }
    };
    return (
        <>

            {(isLoadingVenues || isLoadingVenueOrDate) && (
                <div className="loading">
                    <Spinner size={SpinnerSize.large} />
                </div>
            )}
            <div
                className={`make-package-reservation-container main-container main-container-v2 ${isMobile ? "mobile" : ""
                    }`}
            >
                <Header party />
                <div className="makePackageReservation-inner-container main-content main-content-v2">

                    <div className={`makePackageReservation-main-information-v2 main-content main-content-v2`}>
                        <div
                            className={`makePackageReservation-reservation makePackageReservation-reservation-v2 center ${isMobile ? "mobile" : "no-mobile"
                                }`}
                        >
                            <DefaultContainer
                                className="registrationMessage"
                                bgcolor={"transparent"}
                                width={"100%"}
                                borderRadius={'8px'}


                            >
                                <Box display={'flex'} p={'32px'} flexDirection={'column'} style={{ gap: '24px' }} justifyContent={'center'} alignItems={'center'} minHeight={'644px'}>

                                    <ThanksIcon />
                                    <Box display={'flex'} flexDirection={'column'} style={{ gap: '8px' }}>
                                        <Typography className="root-text bold">Request submitted</Typography>
                                        <Typography className="heading-h2">Thank You</Typography>
                                    </Box>
                                    <Box>

                                        <Typography className="root-text bold">
                                            {eventConfirmationPageTitle}
                                        </Typography>
                                        {eventConfirmationPageCopy ? (
                                            <HTMLRenderer html={eventConfirmationPageCopy} />
                                        ) : (
                                            <div>We've received your event request and will reach out shortly to plan your next event.</div>
                                        )}
                                        <Typography className="root-text bold mb-l mt-xs">{email}</Typography>
                                        <a
                                            className={`root-text bold no-underline mb-xs`}
                                            href={venue?.locationUrl}
                                        >{`${uiConfig?.companyName} ${venue?.name}`}</a>
                                        <Typography className="booking-attr root-text mt-xs">{`Occasion: ${occasionText}`}</Typography>
                                        <Typography className="booking-attr root-text">{`Event Date: ${formattedDate}`}</Typography>
                                        <Typography className="booking-attr root-text">{`Event Start Time: ${time}`}</Typography>
                                        <Typography className="booking-attr root-text">{`Event duration: ${partyDuration}`}</Typography>
                                        <Typography className="booking-attr root-text">{`Number of Guests: ${guestCount}`}</Typography>
                                    </Box>
                                    <ButtonMain style={{ maxWidth: '400px', marginTop: '12px' }} href={uiConfig?.homeLink}
                                    >
                                        Go Back to Homepage
                                    </ButtonMain>
                                </Box>
                            </DefaultContainer>
                        </div>
                        {
                            !isMobile && (
                                <MakeReservationSummary venue={venue} venueNames={venues} selectVenueOption={selectVenueOption} withoutPlanning />
                            )
                        }
                    </div>
                </div>
                <Footer />
            </div >
        </>
    );
};

export default connector(BookingConfirmation);
