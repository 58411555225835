import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useViewport } from "../../../hooks/responsive";
import ButtonMain from "../../common/ButtonMain/ButtonMain";
import DefaultContainer from "../../common/DefaultContainer/DefaultContainer";
import Input from "../../common/Input/Input";
import ErrorAlert from "../common/ErrorAlert";
import "./changePassword.scss";

interface Prop {
    changePassword: ({
        newPassword,
        currentPassword,
    }: {
        newPassword: string;
        currentPassword: string;
    }) => void;
    isInProgress: boolean;
    isPasswordChanged: boolean;
    error?: string;
    cleanError: () => void;
    uiConfig: any;
}

export const ChangePassword = ({
    error,
    isInProgress,
    isPasswordChanged,
    changePassword,
    cleanError,
    uiConfig,
}: Prop) => {
    const { isMobile } = useViewport();

    useEffect(() => {
        if (!isInProgress && isPasswordChanged && lastProgress) {
            setConfirmText("Profile is updated.");
            setErrorText("");
        }
    }, [isInProgress, isPasswordChanged]);
    const errorRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        setErrorText(error);
        if (errorRef.current) {
            errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [error]);
    useEffect(() => {
        return () => cleanError();
    }, [cleanError]);

    const [lastProgress, setLastProgress] = useState(false);
    const [currentPassword, setCurrentPassword] = useState<string | undefined>(
        ""
    );
    const [newPassword, setNewPassword] = useState<string | undefined>("");
    const [repeatPassword, setRepeatPassword] = useState<string | undefined>("");

    const [confirmText, setConfirmText] = useState<string | undefined>("");
    const [errorText, setErrorText] = useState<string | undefined>("");
    const [errorPassword, setErrorPassword] = useState<string>("");
    const [errorNewPassword, setErrorNewPassword] = useState<string>("");
    const [errorRepeatPassword, setErrorRepeatPassword] = useState<string>("");

    const onChangePassword = () => {
        setConfirmText("");
        setErrorText("");
        setLastProgress(true);

        if (
            currentPassword &&
            newPassword &&
            repeatPassword &&
            newPassword === repeatPassword
        ) {
            changePassword({ newPassword, currentPassword });
            return;
        }
        if (!currentPassword) {
            setErrorPassword("This field is required.");
        }
        if (newPassword && repeatPassword && newPassword !== repeatPassword) {
            setErrorNewPassword("These fields must match.");
            setErrorRepeatPassword("These fields must match.");
        }
        if (!newPassword) {
            setErrorNewPassword("This field is required.");
        }
        if (!repeatPassword) {
            setErrorRepeatPassword("This field is required.");
        }
    };
    const onPasswordChange = (e: React.ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorPassword("");
        setCurrentPassword(e.target.value);
    };
    const onNewPassword = (e: React.ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorNewPassword("");
        setErrorRepeatPassword("");
        setNewPassword(e.target.value);
    };
    const onRepeatPassword = (e: React.ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorNewPassword("");
        setErrorRepeatPassword("");
        setRepeatPassword(e.target.value);
    };

    return (
        <>
            <DefaultContainer>
                <Typography className="heading-h3 section-title">Change Password</Typography>

                <Box display={'flex'} flexDirection={'column'} style={{ gap: '16px' }}>
                    <Input autoComplete="off" isPassword errorInput={errorPassword} label="Current Password" placeholder='Enter your current password' value={currentPassword} onChange={onPasswordChange} fullWidth />
                    <Input autoComplete="off" isPassword errorInput={errorNewPassword} label="New Password" placeholder='Enter your new password' value={newPassword} onChange={onNewPassword} fullWidth />
                    <Input autoComplete="off" isPassword errorInput={errorRepeatPassword} label="Confirm New Password" placeholder='Repeat your new password' value={repeatPassword} onChange={onRepeatPassword} fullWidth />

                    {errorText && errorText.includes('pass') &&
                        <ErrorAlert error={'Change password issue!'} />}

                    {confirmText && !errorText && (
                        <Box className="confirm">{confirmText}</Box>
                    )}
                    <ButtonMain name="save" style={{ marginTop: '10px' }} onClick={onChangePassword} disabled={(!currentPassword || !newPassword || !repeatPassword)}>SAVE</ButtonMain>
                </Box>
            </DefaultContainer>

        </>
    );
};
