import axios, { AxiosResponse } from "axios";

const TOKEN_ID = "auth"; //todo temp solution, move token to http cookies
const GUEST_TOKEN_ID = "guestAuth";

export const SQUARE_URL = '/api/square'

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_ID);
  return token;
};
export const getGuestToken = () => {
  const guestToken = localStorage.getItem(GUEST_TOKEN_ID);
  return guestToken;
};
export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_ID, token);
};
export const setGuestToken = (guestToken: string) => {
  localStorage.setItem(GUEST_TOKEN_ID, guestToken);
};

export const get = <ResType = any>(url: string, params?: any) =>
  axios.get<any, AxiosResponse<ResType>>(url, params);
export const post = <ResType = any>(url: string, data?: any) =>
  axios.post<any, AxiosResponse<ResType>>(url, data);

export const getWithAuth = <ResType = any>(url: string, params?: any) =>
  axios.get<any, AxiosResponse<ResType>>(url, {
    params,
    headers: {
      authorization: `bearer ${getToken()}`,
    },
  });
export const postWithAuth = <ResType = any>(url: string, body?: any) =>
  axios.post<any, AxiosResponse<ResType>>(url, body, {
    headers: {
      authorization: `bearer ${getToken()}`,
    },
  });

export const getWithGuestAuth = <ResType = any>(url: string, params?: any) =>
  axios.get<any, AxiosResponse<ResType>>(url, {
    params,
    headers: {
      authorization: `bearer ${getGuestToken()}`,
    },
  });

export const postWithGuestAuth = <ResType = any>(url: string, body?: any) =>
  axios.post<any, AxiosResponse<ResType>>(url, body, {
    headers: {
      authorization: `bearer ${getGuestToken()}`,
    },
  });

export const postForm = (url: string, body: any) => {
  var data = new FormData();
  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });
  return axios({
    method: "post",
    url: url,
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
};
