import React, { useEffect, useState } from "react";
import { Credentials } from '../../../actions/auth-actions';
import { useViewport } from "../../../hooks/responsive";

import { Box, Link, Modal, ModalProps, Typography, makeStyles } from "@material-ui/core";
import { MobileFriendly } from "@material-ui/icons";
import ButtonMain from "../../common/ButtonMain/ButtonMain";
import useAccount2Styles from "../Account/useAccount2Styles";
import AuthCode from 'react-auth-code-input';

interface Props extends ModalProps {
    login: (credentials: Credentials) => void;
    isInProgress: boolean;
    toRecover: () => void;
    cleanError: () => void,
    continueReservation: boolean;
    error?: string;
    onCreateAccount: () => void;
    onCheckoutAsGuest: () => void;
    onRecover: () => void;
    showCheckoutAsGuest: boolean;
    onOutsideClick?: () => void;
}
const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(243, 243, 245, 0.80)',
    },
}));
export const PhoneLoginModal = ({
    login,
    toRecover,
    error,
    isInProgress,
    cleanError,
    continueReservation,
    onCreateAccount,
    onCheckoutAsGuest,
    onRecover,
    showCheckoutAsGuest,
    onOutsideClick,
    ...props
}: Props) => {
    const { isMobile } = useViewport();
    const classes = useAccount2Styles();
    const classesModal = useStyles();

    useEffect(() => () => cleanError(), [cleanError])
    const containerRef = React.useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            onOutsideClick && onOutsideClick()
        }
    };

    useEffect(() => {
        // @ts-ignore
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // @ts-ignore
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

    };
    const [result, setResult] = useState('');
    const handleOnChange = (res: string) => {
        setResult(res);
    };

    return (
        <Modal {...props} >
            <Box
                className={classesModal.container}
            >
                <Box display={'flex'} flexDirection={'column'} maxHeight={'99vh'} style={{ overflowY: 'auto' }} borderRadius={'8px'}>
                    <div ref={containerRef}>
                        <Box maxWidth={isMobile ? '100%' : '464px'} marginX={'auto'} textAlign={'center'} width={isMobile ? '100%' : '464px'} bgcolor={'white'} borderRadius={'8px'}
                            boxShadow={' 0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04)'} p='32px 48px' pb={'32px'}>
                            <MobileFriendly style={{ fontSize: '48px', color: '#212B36', marginBottom: '24px' }} />
                            <Typography className={`heading-h1 ${classes.title}`}>Check Mobile Phone</Typography>
                            <Typography className="body-big">
                                We sent a 6-digit code to <strong>number</strong> It expires shortly, so enter it soon.
                            </Typography>
                            <form onSubmit={onSubmit}>

                                <Box display={'flex'} flexDirection={'column'} style={{ gap: '16px' }} my={'32px'}>
                                    <AuthCode allowedCharacters='numeric' autoFocus containerClassName={classes.codeContainer} inputClassName={classes.inputContainer} onChange={handleOnChange} />
                                </Box>


                                <ButtonMain type="submit" disabled={isInProgress || result.length < 6}>Verify phone</ButtonMain>
                                <Typography className={`body-small  ${classes.procceding}`}>
                                    Didn’t receive SMS? {' '}
                                    <Link href={'#'} target="_blank" className={classes.linkBlack}>Click to resend </Link>
                                </Typography>

                            </form>

                        </Box>
                    </div>
                </Box>
            </Box>
        </Modal>
    );
};
