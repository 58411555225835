import { Box, BoxProps, Link, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import AlertIcon from '../../../assets/alert.svgr'
import HtmlRenderer from '../../../../common/components/HTMLRenderer/HTMLRenderer';

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#2E3A48'
    },
    link: {
        color: '#EE4C1A',
        cursor: 'pointer',
    }

}));
interface Props extends BoxProps {
    text?: string | React.ReactNode;
    noSpace?: boolean;
    isHtml?: boolean;
}
const NoAvailiableGuests = ({ text, noSpace, isHtml, ...props }: Props) => {
    const classes = useStyles();

    return (
        <Box display={'flex'} justifyContent={noSpace ? 'flex-start' : 'space-between'} p={'20px'} alignItems={'center'} bgcolor={'rgba(238, 76, 26, 0.1)'} style={{ gap: '12px' }} {...props}>
            <Box display={'flex'}>
                <AlertIcon />
            </Box>
            {isHtml ? <HtmlRenderer className={`${classes.text} error-alert-v2`} html={text as string} /> :
                <Typography className={`${classes.text} error-alert-v2`}>
                    {text ||
                        <>
                            At the moment, there's no online availability based on your selection. Please try a different date or update the number of guests.</>}
                </Typography>}
        </Box>
    )
}

export default NoAvailiableGuests