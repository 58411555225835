import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, BoxProps, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import AppBox from './AppBox';
import HtmlRenderer from '../../../../common/components/HTMLRenderer/HTMLRenderer';

export interface Accordion {
    heading: string,
    details: string
}

interface Props extends BoxProps {
    title: string,
    accordionData: Accordion[]
}

export const AppAccordion = withStyles({
    root: {
        borderTop: '1px solid rgba(0, 0, 0, .05)',
        borderBottom: '1px solid rgba(0, 0, 0, .05)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(Accordion);


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    accordion: {
        borderTop: '1px solid rgba(0,0,0,0.05)',
        borderBottom: '1px solid rgba(0,0,0,0.05)',
    },
    noBorderBottom: {
        borderBottom: 'none',
    },
    noBorderTop: {
        borderTop: 'none',
    },
    summary: {
        paddingLeft: 0,
        paddingRight: 0,
        margin: '8px 0'
    },
    detailsContainer: {
        paddingLeft: 0,
        paddingTop: 0,
    },
    header: {
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '40px',
        marginBottom: '28px',
    },
    heading: {
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '32px'
    },
    details: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
        color: '#3F4852'
    }
}));



const ThingsToKnow = ({ title, accordionData, ...props }: Props) => {
    const classes = useStyles();

    return (
        <AppBox title={title} headerStyles={{ marginBottom: '18px' }} {...props}>
            {accordionData.map((acc, i) => (
                <AppAccordion square elevation={0} className={`${classes.accordion} ${i === accordionData.length - 1 && classes.noBorderBottom} ${i === 0 && classes.noBorderTop}`} key={acc.heading}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.summary}
                    >
                        <Typography className={classes.heading}>{acc.heading}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.detailsContainer}>
                        <HtmlRenderer className={classes.details} html={acc.details} />
                    </AccordionDetails>
                </AppAccordion>
            ))}
        </AppBox>

    )
}

export default ThingsToKnow