import React, { useState, useEffect } from "react";
import {
    Spinner,
    SpinnerSize,
} from "office-ui-fabric-react";
import {
    selectAuthenticated,
    selectAuthError,
    selectIsAuthRequestInProgress,
} from "../../reducers/auth-reducer";
import {
    loginCancellationAction,
    cleanAuthErrorAction,
} from "../../actions/auth-actions";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { connect, ConnectedProps } from "react-redux";
import { State } from "../../store/types";
import { useViewport } from "../../hooks/responsive";
import { InputField } from "../../../../common/components/InputField/InputField";
import { PrimaryButton } from "../../../../common/components/PrimaryButton/PrimaryButton";
import { isValidEmail } from "../../../../common/utils/validate";
import "./accountCancellation.scss";
import { pushUrlPathAction } from "../../actions/reservation-actions";
import Footer from "../common/Footer/Footer";
import Header from "../common/Header/Header";


const mapStateToProps = (state: State) => ({
    authenticated: selectAuthenticated(state),
    authError: selectAuthError(state),
    isInProgress: selectIsAuthRequestInProgress(state),
    uiConfig: selectUIConfig(state),
});

const mapDispatchToProps = {
    loginCancellation: loginCancellationAction,
    cleanAuthError: cleanAuthErrorAction,
    pushUrlPath: pushUrlPathAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Prop = ConnectedProps<typeof connector>;

const AccountCancellation = ({
    authenticated,
    authError,
    isInProgress,
    cleanAuthError,
    pushUrlPath,
    loginCancellation,
    uiConfig,
}: Prop) => {
    const { isMobile } = useViewport();

    useEffect(() => {
        if (authenticated) {
            pushUrlPath("/account");
        }
    }, [authenticated]);

    const [username, setUserName] = useState<string | undefined>("");
    const [password, setPassword] = useState<string | undefined>("");
    const [errorUsername, setErrorUsername] = useState<string>("");
    const [errorPassword, setErrorPassword] = useState<string>("");
    useEffect(() => { cleanAuthError }, [cleanAuthError])

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (username && password && isValidEmail(username)) {
            loginCancellation({ username, password });
            return;
        }
        if (!username) {
            setErrorUsername("This field is required.");
        }
        if (username && !isValidEmail(username)) {
            setErrorUsername("Please enter a valid email address.");
        }
        if (!password) {
            setErrorPassword("This field is required.");
        }
    };
    const onUserNameChange = (_: any, text?: string) => {
        setErrorUsername("");
        setUserName(text);
    };
    const onPasswordChange = (_: any, text?: string) => {
        setErrorPassword("");
        setPassword(text);
    }

    return (
        <div className="accountCancellation-container main-container">
            <Header cancellation />
            <div className="accountCancellation-inner-container main-container">
                <div className="title">Login</div>
                <form className="loginCancellation main-content" onSubmit={onSubmit} >
                    <div className={isMobile ? "mobile-loginCancellation-fields" : "loginCancellation-fields"}>
                        {isInProgress && (
                            <div className="loading">
                                <Spinner size={SpinnerSize.large} />
                            </div>
                        )}
                        <InputField
                            label="Email"
                            name="email"
                            value={username}
                            errorMessage={errorUsername}
                            onChange={onUserNameChange}
                            autoComplete="email"
                            type="email"
                        />
                        <InputField
                            type="password"
                            name="password"
                            label="Password"
                            value={password}
                            errorMessage={errorPassword}
                            onChange={onPasswordChange}
                            autoComplete="current-password"
                        />
                        {authError && <div className="error">{authError}</div>}
                        <PrimaryButton type="submit" name="login" uiConfig={uiConfig}>
                            LOGIN
                        </PrimaryButton>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default connector(AccountCancellation);
