import React from "react";
import { TextField, ITextFieldProps, ITextFieldStyles, mergeStyleSets } from "office-ui-fabric-react";
import { color } from "../../constants/styles";

export const inputFieldStyle: Partial<ITextFieldStyles> = {
  root: {
    width: "100%",
  },
  fieldGroup: {
    padding: 0,
    minHeight: "40px",
    border: "1px solid #D5D8E1",
    borderRadius: "6px",
    outline: "0"
  },
  field: {
    padding: "5px 0px 5px 8px",
    fontSize: 16,
    color: color.mainDark,
  },
  errorMessage: {
    position: "absolute"
  },
};

const FormTextField = ({ styles, ...other }: ITextFieldProps) => {
  const textFieldStyles = mergeStyleSets(inputFieldStyle, styles) as ITextFieldStyles;

  return <TextField styles={textFieldStyles} {...other} />;
};

export default FormTextField