import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeCardElement } from "@stripe/stripe-js";
import { mergeStyleSets, Spinner, SpinnerSize } from "office-ui-fabric-react";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { InfoAlert } from "../../../../../common/components/Alert/Alert";
import GiftCard from "../../../../../common/components/GiftCard";
import HTMLRenderer from "../../../../../common/components/HTMLRenderer/HTMLRenderer";
import { color } from "../../../../../common/constants/styles";
import { calculateDeposit, rem } from "../../../../../common/utils/formats";
import { loadScript } from "../../../../../common/utils/loader";
import GoTabLogo from "../../../../assets/payment/gotab-logo.png";
import {
  addGiftCardAction,
  applyCouponCodeAction,
  checkSquareOrderCalculationAction,
  checkSquarePartialPaymentAction,
  completePaymentAction,
  completePaymentAsGuestAction,
  completePaymentSezzleAction,
  createEmptyPaymentAction,
  createSquarePartialAction,
  getFortisClientTokenAction,
  getGiftCardBalanceAction,
  getGoTabReservationStatusAction,
  getReservationsAction,
  makeStripeReservationAction,
  paymentMethodStripeAction,
  pushUrlPathAction,
  setReservationErrorAction,
  setGoTabIdAction,
  createGoTabId,
  getClientSavedStripeCardsAction,
} from "../../../actions/reservation-actions";
import {
  backToReservationAction,
  getSquareAppIdAction,
} from "../../../actions/venue-actions";
import {
  MAX_MOBILE_WIDTH,
  MAX_WIDTH,
  ViewportContext,
} from "../../../hooks/responsive";
import { selectClient, selectGuest } from "../../../reducers/auth-reducer";
import {
  selectErrorCouponCode,
  selectFortisClientToken,
  selectGiftBalanceError,
  selectGiftCardAmount,
  selectGiftCardBalance,
  selectGoTabReservationId,
  selectIsCardBalanceInProgress,
  selectIsPaymentInProgress,
  selectIsStripePaymentMethodsRequestInProgress,
  selectIsUpdateReservation,
  selectIsUpdateWithVenueChange,
  selectListOfStripePaymentMethods,
  selectOldReservation,
  selectRequestError,
  selectReservation,
  selectReservationAddons,
  selectReservationConfirmation,
} from "../../../reducers/reservation";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { selectCurrentPackage, selectVenue } from "../../../reducers/venues";
import { Payment, State } from "../../../store/types";
import { PMethod } from "../../../store/types/venues";
import ButtonMain from "../../common/ButtonMain/ButtonMain";
import FortisForm from "../../common/FortisForm/FortisForm";
import MobileAttachedButton from "../../common/MobileAttachedButton/MobileAttachedButton";
import { Payments } from "../../common/SquareForm/square";
import SquareForm from "../../common/SquareForm/SquareForm";
import StripeForm from "../../common/StripeForm/StripeForm";
import CouponCodes from "../../OrderSummary/CouponCodes";
import InformationSecureAlert from "./InformationSecureAlert";
import "./payment.scss";
import PaymentButton from "./PaymentButton";
import PaymentContainer from "./PaymentContainer";
import VenuePolicies from "./VenuePolicies";
import NoAvailiableGuests from "../../PackageCard/NoAvailiableGuests";
import { OrderSummary } from "../../OrderSummary/OrderSummary";

interface OwnSezzleProps {
  templatetext?: string;
  bordertype?: string;
  customclass?: string;
}

type SezzleProps = OwnSezzleProps &
  React.HTMLProps<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement>;

function Sezzle({ ...rest }: SezzleProps) {
  return <div {...rest}></div>;
}

interface OwnProps {
  customHandler?: (payment: Payment) => void;
  customStripeHandler?: (stripe: Stripe, elements?: StripeCardElement, otherMethods?: boolean, paymentMethod?: string) => void;
}
const mapDispatchToProps = {
  backToReservation: backToReservationAction,
  completePayment: completePaymentAction,
  setReservationError: setReservationErrorAction,
  pushUrlPath: pushUrlPathAction,
  completePaymentAsGuest: completePaymentAsGuestAction,
  completePaymentSezzle: completePaymentSezzleAction,
  makeStripeReservation: makeStripeReservationAction,
  paymentMethodStripe: paymentMethodStripeAction,
  getGiftCardBalance: getGiftCardBalanceAction,
  addGiftCard: addGiftCardAction,
  createEmptyPayment: createEmptyPaymentAction,
  getFortisClientToken: getFortisClientTokenAction,
  getGoTabReservationStatus: getGoTabReservationStatusAction,
  applyCouponCode: applyCouponCodeAction,
  getSquareAppId: getSquareAppIdAction,
  checkSquareOrderCalculation: checkSquareOrderCalculationAction,
  createSquarePartial: createSquarePartialAction,
  getReservations: getReservationsAction,
  checkSquarePartialPayment: checkSquarePartialPaymentAction,
  setGoTabId: setGoTabIdAction,
  getClientSavedStripeCards: getClientSavedStripeCardsAction
};

const mapStateToProps = (state: State) => ({
  reservation: selectReservation(state),
  client: selectClient(state),
  guest: selectGuest(state),
  venue: selectVenue(state),
  isPaymentInProgress: selectIsPaymentInProgress(state),
  isCardBalanceInProgress: selectIsCardBalanceInProgress(state),
  requestError: selectRequestError(state),
  uiConfig: selectUIConfig(state),
  confirmation: selectReservationConfirmation(state),
  giftBalance: selectGiftCardBalance(state),
  giftBalanceError: selectGiftBalanceError(state),
  giftCardAmount: selectGiftCardAmount(state),
  currentPackage: selectCurrentPackage(state),
  isUpdateReservation: selectIsUpdateReservation(state),
  isUpdateWithVenueChange: selectIsUpdateWithVenueChange(state),
  oldReservation: selectOldReservation(state),
  reservationAddons: selectReservationAddons(state),
  fortisClientToken: selectFortisClientToken(state),
  goTabReservationId: selectGoTabReservationId(state),
  listOfStripeSavedCards: selectListOfStripePaymentMethods(state),
  isStripeSavedCardsRequestInProgress: selectIsStripePaymentMethodsRequestInProgress(state),
  errorCouponCode: selectErrorCouponCode(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & OwnProps;

const checkboxStyle = {
  root: {
    margin: "0 0 30px",
  },
  checkbox: {
    width: rem(20),
    height: rem(20),
  },
  text: {
    fontSize: rem(13),
    lineHeight: "1.5",
  },
  checkmark: {
    fontSize: rem(13),
  },
};

interface OwnState {
  loading: boolean;
  goTabLoading: boolean;
  zipCode: string;
  selected: boolean;
  error: string;
  stripe: Promise<Stripe | null>;
  squarePayments: Payments | null;
  isSquareSandbox: boolean;
  paymentMethod: PMethod;
  showOptions: boolean;
  confirmButtonText: string;
  goTabPaymentCompleted: boolean;
}

class PaymentComponent extends React.Component<Props, OwnState> {
  static contextType = ViewportContext;
  state = {
    loading: true,
    goTabLoading: false,
    selected: false,
    zipCode: "",
    error: "",
    stripe: Promise.resolve(null),
    squarePayments: null,
    isSquareSandbox: true,
    paymentMethod:
      this.props.venue?.paymentType === PMethod.square
        ? PMethod.square
        : PMethod.heartland,
    showOptions: false,
    confirmButtonText: "COMPLETE YOUR RESERVATION",
    goTabPaymentCompleted: false,
  };

  componentDidMount() {
    const {
      venue,
      client,
      guest,
      setReservationError,
      reservation,
      confirmation,
      giftCardAmount,
      isUpdateReservation,
      isUpdateWithVenueChange,
      oldReservation,
      getClientSavedStripeCards,
    } = this.props;

    // remove confirmButtonText state
    if (isUpdateReservation) {
      this.setState({ confirmButtonText: "MODIFY RESERVATION" });
    }

    if (venue?.paymentType === PMethod.stripeV2 && !guest) {
      getClientSavedStripeCards();
    }

    if (venue?.paymentKey) {
      // pre-process heartland form
      if (venue && !venue.showPolicy) {
        this.setState({ selected: true });
      }
      setReservationError();
      this.composeHeartlandPaymentForm();
    } else if (venue?.fortisUrl) {
      if (venue && !venue.showPolicy) {
        this.setState({ selected: true });
      }
      setReservationError();
      this.composeFortisPaymentForm();
    } else if (venue?.paymentType === PMethod.goTab) {
      setReservationError();
      this.setState({ paymentMethod: PMethod.goTab });
    } else if (venue?.paymentType === PMethod.square) {
      setReservationError();
      this.composeSquarePaymentForm();
    } else {
      // pre-process stripe form
      this.setState({ paymentMethod: PMethod.stripe });
      this.loadStripe();
      if (venue && !venue.showPolicy) {
        this.setState({ selected: true });
      }
    }

    //todo refactor it
    if (venue?.sezzlePublishableKey && !isUpdateReservation) {
      setTimeout(() => {
        const publicKey = venue.sezzlePublishableKey;
        const apiMode = (venue?.sezzleUrl || "").includes("sandbox")
          ? "sandbox"
          : "live";
        const checkout = new Checkout({
          mode: "iframe",
          publicKey,
          apiMode,
          apiVersion: "v2",
        });
        checkout.renderSezzleButton("sezzle-smart-button-container");
        this.setState({ showOptions: true });
        const deposit = calculateDeposit({
          reservation,
          giftCardAmount,
          isUpdateReservation,
          isUpdateWithVenueChange,
          oldReservation,
        });
        const amount = deposit * 100;
        const firstName = client
          ? client?.firstName
          : confirmation?.firstName || "";
        const lastName = client
          ? client?.lastName
          : confirmation?.lastName || "";
        const email = client ? client?.email : guest?.email || "";
        const description = `reservation-${email}:${firstName} ${lastName} : ${Date.now()}`;
        const id = `${Date.now()}`;
        checkout.init({
          onClick: () => {
            console.log("on click");
            checkout.startCheckout({
              checkout_payload: {
                order: {
                  intent: "AUTH",
                  reference_id: id,
                  description,
                  order_amount: {
                    amount_in_cents: amount,
                    currency: reservation.currency,
                  },
                },
              },
            });
          },
          onComplete: (event: any) => {
            console.log("on complete", event);
            const payload = {
              capture_amount: {
                amount_in_cents: amount,
                currency: reservation.currency,
              },
              partial_capture: false,
            };
            //maybe do it on server side
            checkout.capturePayment(event.data.order_uuid, payload);
            this.props.completePaymentSezzle(event.data, amount);
          },
          onCancel: () => {
            console.log("on cancel");
            // backToReservation()
          },
          onFailure: (event: any) => {
            console.log("on failure", event);
            this.setState({
              error: "Cannot complete payment, please try another method.",
            });
          },
        });
      }, 100); //timer to let div render
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.gtWindow) {
      try {
        this.gtWindow.close();
      } catch (e) {
        console.log("cannot close window", this.gtWindow);
      }
    }
  }

  render() {
    const {
      venue,
      reservation,
      giftCardAmount,
      isUpdateReservation,
      isUpdateWithVenueChange,
      oldReservation,
    } = this.props;
    const { stripe, paymentMethod, squarePayments } = this.state;
    let deposit = calculateDeposit({
      reservation,
      giftCardAmount,
      isUpdateReservation,
      isUpdateWithVenueChange,
      oldReservation,
    });
    let isSkipPayment = deposit <= 0;

    if (isSkipPayment) {
      return this.renderPayment(this.renderSkipPayment());
    }
    if (paymentMethod === PMethod.goTab) {
      return this.renderPayment(this.renderGoTabPayment());
    } else if (paymentMethod === PMethod.heartland) {
      if (!venue?.paymentKey) {
        return this.renderCompleteErrorMessage();
      }
      return this.renderPayment(this.renderHeartlandPayment());
    } else if (paymentMethod === PMethod.fortis) {
      if (!venue?.fortisUrl) {
        return this.renderCompleteErrorMessage();
      }
      return this.renderPayment(this.renderFortisPayment());
    } else if (paymentMethod === PMethod.stripe) {
      if (!stripe) {
        return this.renderCompleteErrorMessage();
      }
      return this.renderPayment(this.renderStripePayment());
    } else if (paymentMethod === PMethod.square) {
      if (!squarePayments) {
        return this.state.error
          ? this.renderCompleteErrorMessage()
          : this.renderLoader();
      }
      return this.renderPayment(this.renderSquarePayment());
    }
  }

  renderPayment(child: React.ReactNode) {
    const {
      isPaymentInProgress,
      isCardBalanceInProgress,
      uiConfig,
      goTabReservationId,
      isStripeSavedCardsRequestInProgress
    } = this.props;
    const { loading, showOptions, paymentMethod, goTabLoading } = this.state;
    const { width } = this.context;
    const isMobile = width < MAX_MOBILE_WIDTH;
    const isTabletScreen = width > MAX_MOBILE_WIDTH && width < MAX_WIDTH;
    const subtitle = mergeStyleSets({
      backgroundColor: {
        background: uiConfig?.bodyBackgroundColor,
        height: "22px",
        padding: "0 10px",
      },
    });
    let showSpinner = [
      PMethod.stripe,
      PMethod.fortis,
      PMethod.goTab,
      PMethod.square,
    ].includes(paymentMethod)
      ? isPaymentInProgress || isCardBalanceInProgress || (paymentMethod.includes(PMethod.stripe) && isStripeSavedCardsRequestInProgress) || goTabLoading
      : loading || isPaymentInProgress || isCardBalanceInProgress || goTabLoading;
    return (
      <div className="payment-container">
        <div
          className={`payment-inner-container main-content-v2 ${isTabletScreen ? "is-tablet-v2" : ""
            }`}
        >
          {(showSpinner) && (
            <div className="loading">
              <Spinner size={SpinnerSize.large} />
            </div>
          )}
          <div
            className={`payment-paymentInfo v2 ${isMobile ? "mobile" : ""} ${isTabletScreen ? "is-tablet-v2" : ""
              }`}
          >
            {!isMobile && <div className="title ">Payment</div>}
            {showOptions && !goTabReservationId && (
              <div className="delimiter">
                <div className={subtitle.backgroundColor}>Checkout options</div>
              </div>
            )}
            {!goTabReservationId && (
              <Sezzle
                id="sezzle-smart-button-container"
                templatetext="%%logo%%"
                bordertype="semi-rounded"
                customclass="sezzle-button"
              ></Sezzle>
            )}
            {showOptions && !goTabReservationId && (
              <div className="delimiter">
                <div className={subtitle.backgroundColor}>Pay with card</div>
              </div>
            )}
            <div className="payment-information-fields">{child}</div>
          </div>

          {!isMobile && (
            <>
              <div className="column-delimiter"></div>
              <div className="payment-summary">{this.renderOrderSummary()}</div>
            </>
          )}
        </div>
      </div>
    );
  }

  renderCompleteErrorMessage() {
    const { pushUrlPath, uiConfig, currentPackage } = this.props;
    const isPackageReservationMode =
      currentPackage || uiConfig?.isPackageReservationMode;
    const { width } = this.context;
    const isMobile = width < MAX_MOBILE_WIDTH;

    return (
      <div className="payment-container">
        <div className="main-content">
          <div>Sorry, we cannot complete payment. Please try again later.</div>
          {isMobile ? (
            <MobileAttachedButton
              reservation={this.props.reservation}
              onButtonClick={() =>
                pushUrlPath(
                  isPackageReservationMode ? "/package" : "/reservation"
                )
              }
              isSummaryExpanded={false}
              isButtonDisabled={false}
              venueId={this.props.venue?.id}
              giftCardAmount={this.props.giftCardAmount}
              isUpdateReservation={this.props.isUpdateReservation}
            >
              EXIT
            </MobileAttachedButton>
          ) : (
            <ButtonMain
              onClick={() =>
                pushUrlPath(
                  isPackageReservationMode ? "/package" : "/reservation"
                )
              }
            >
              EXIT
            </ButtonMain>
          )}
        </div>
      </div>
    );
  }

  renderLoader() {
    return (
      <div className="payment-container">
        <div className="main-content">
          <div className="loading">
            <Spinner size={SpinnerSize.large} />
          </div>
        </div>
      </div>
    );
  }

  renderOrderSummary(error?: string, block?: boolean) {
    const {
      reservation,
      venue,
      backToReservation,
      uiConfig,
      requestError,
      giftBalance,
      getGiftCardBalance,
      giftBalanceError,
      addGiftCard,
      giftCardAmount,
      createEmptyPayment,
      currentPackage,
      isUpdateReservation,
      isUpdateWithVenueChange,
      oldReservation,
      reservationAddons,
      applyCouponCode,
      errorCouponCode
    } = this.props;
    const { selected, paymentMethod, confirmButtonText } = this.state;
    const { width } = this.context;
    const isMobile = width < MAX_MOBILE_WIDTH;
    const isHideDuration =
      uiConfig?.hideDuration || currentPackage?.hidePackageDuration;
    const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;

    let deposit = calculateDeposit({
      reservation,
      giftCardAmount,
      isUpdateReservation,
      isUpdateWithVenueChange,
      oldReservation,
    });
    let isSkipPayment = deposit <= 0;
    let isRefundPayment = deposit < 0;

    let isShowRefundPaymentInfo =
      isUpdateReservation && isSkipPayment && isRefundPayment;
    let isShowSkipPaymentInfo =
      isUpdateReservation && isSkipPayment && !isRefundPayment;
    let isShowPaymentInfo = isUpdateReservation && !isSkipPayment;
    const isGoTab = venue?.paymentType === PMethod.goTab;

    if (isMobile)
      return (
        <>
          {!!venue?.useGiftCard && isMobile && isGoTab && (
            <GiftCard
              isMobile={!!isMobile}
              deposit={deposit}
              giftBalance={giftBalance}
              getGiftCardBalance={getGiftCardBalance}
              giftBalanceError={giftBalanceError}
              addGiftCard={addGiftCard}
              giftCardAmount={giftCardAmount}
            />
          )}
          {!!venue?.useDiscount && isMobile && isGoTab && (
            <CouponCodes
              reservation={reservation}
              applyCouponCode={applyCouponCode}
              deposit={deposit}
              isSkipPayment={isRefundPayment}
            />
          )}
          <Box pb={'100px'}>
            {(venue?.showPolicy || currentPackage?.showPolicy || currentPackage?.packagePolicyOverride) && (
              <VenuePolicies
                policyInfo={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyText : venue?.venueInfo}
                policyTitle={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyTitle : 'VENUE POLICIES'}
                selected={selected}
                onChange={(_: any, isChecked?: boolean) =>
                  this.setState({ selected: !!isChecked })
                }
                notShowCheckbox={currentPackage?.packagePolicyOverride && !currentPackage?.showPolicy}
              />
            )}

            {isShowRefundPaymentInfo && (
              <InfoAlert text="You will receive a refund for the price difference" />
            )}
            {isShowSkipPaymentInfo && (
              <InfoAlert text="There is no price difference for your new reservation. No additional payment is needed" />
            )}
            {isShowPaymentInfo && (
              <InfoAlert text="Your new reservation requires an additional payment" />
            )}
            {(requestError || error) && (
              <NoAvailiableGuests text={error ? error : requestError} noSpace />
            )}
          </Box>

          {!isSkipPayment &&
            ([PMethod.stripe, PMethod.fortis, PMethod.square].includes(
              paymentMethod
            ) ? (
              <MobileAttachedButton
                reservation={reservation}
                onButtonClick={() => { }}
                isSummaryExpanded={false}
                isButtonDisabled={!!block || !selected}
                venueId={venue?.id}
                giftCardAmount={giftCardAmount}
                isUpdateReservation={isUpdateReservation}
                type="submit"
              >
                {confirmButtonText}
              </MobileAttachedButton>
            ) : (
              <div id="credit-card-submit"></div>
            ))}
          {isSkipPayment && (
            <MobileAttachedButton
              reservation={reservation}
              onButtonClick={() => createEmptyPayment(paymentMethod)}
              isSummaryExpanded={false}
              isButtonDisabled={!selected}
              venueId={venue?.id}
              giftCardAmount={giftCardAmount}
              isUpdateReservation={isUpdateReservation}
            >
              {confirmButtonText}
            </MobileAttachedButton>
          )}
        </>
      );
    return isMobile ? (
      <OrderSummary
        reservation={reservation}
        venue={venue}
        onEdit={backToReservation}
        actionText={uiConfig?.actionText}
        isMobile={isMobile}
        isHideDuration={isHideDuration}
        currentPackage={currentPackage}
        giftCardAmount={giftCardAmount}
        isSkipPayment={isSkipPayment}
        isUpdateReservation={isUpdateReservation}
        oldDeposit={reservation?.payed}
        reservationAddons={reservationAddons}
        twelveHourClockFormat={twelveHourClockFormat}
        notShowContinue
        isSummaryExpanded={false}
        errorCouponCode={errorCouponCode}
      >
        <Box pb={'100px'}>
          {(venue?.showPolicy || currentPackage?.showPolicy || currentPackage?.packagePolicyOverride) && (
            <VenuePolicies
              policyInfo={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyText : venue?.venueInfo}
              policyTitle={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyTitle : 'VENUE POLICIES'} selected={selected}
              onChange={(_: any, isChecked?: boolean) =>
                this.setState({ selected: !!isChecked })
              }
              notShowCheckbox={currentPackage?.packagePolicyOverride && !currentPackage?.showPolicy}

            />
          )}
          {isShowRefundPaymentInfo && (
            <InfoAlert text="You will receive a refund for the price difference" />
          )}
          {isShowSkipPaymentInfo && (
            <InfoAlert text="There is no price difference for your new reservation. No additional payment is needed" />
          )}
          {isShowPaymentInfo && (
            <InfoAlert text="Your new reservation requires an additional payment" />
          )}
          {(requestError || error) && (
            <div className="error">{error ? error : requestError}</div>
          )}
        </Box>
        {!isSkipPayment &&
          ([PMethod.stripe, PMethod.fortis, PMethod.square, PMethod.stripeV2].includes(
            paymentMethod
          ) ? (
            <MobileAttachedButton
              reservation={reservation}
              onButtonClick={() => { }}
              isSummaryExpanded={false}
              isButtonDisabled={!!block || !selected}
              venueId={venue?.id}
              giftCardAmount={giftCardAmount}
              isUpdateReservation={isUpdateReservation}
              type="submit"
              aria-label="submit form"
            >
              {confirmButtonText}
            </MobileAttachedButton>
          ) : (
            <div id="credit-card-submit"></div>
          ))}
        {isSkipPayment && (
          <MobileAttachedButton
            reservation={reservation}
            onButtonClick={() => createEmptyPayment(paymentMethod)}
            isSummaryExpanded={false}
            isButtonDisabled={!selected}
            venueId={venue?.id}
            giftCardAmount={giftCardAmount}
            isUpdateReservation={isUpdateReservation}
          >
            {confirmButtonText}
          </MobileAttachedButton>
        )}
      </OrderSummary>
    ) : (
      <OrderSummary
        reservation={reservation}
        venue={venue}
        onEdit={backToReservation}
        actionText={uiConfig?.actionText}
        isMobile={isMobile}
        isHideDuration={isHideDuration}
        currentPackage={currentPackage}
        showGiftCard={!!venue?.useGiftCard}
        getGiftCardBalance={getGiftCardBalance}
        giftBalance={giftBalance}
        giftBalanceError={giftBalanceError}
        addGiftCard={addGiftCard}
        showDiscounts={venue?.useDiscount}
        applyCouponCode={applyCouponCode}
        giftCardAmount={giftCardAmount}
        isSkipPayment={isSkipPayment}
        isUpdateReservation={isUpdateReservation}
        oldDeposit={reservation?.payed}
        reservationAddons={reservationAddons}
        twelveHourClockFormat={twelveHourClockFormat}
        notShowContinue
        isSummaryExpanded={false}
        errorCouponCode={errorCouponCode}
      />
    );
  }

  disableEnterKey(e: React.KeyboardEvent) {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  async composeHeartlandPaymentForm() {
    try {
      const {
        venue,
        client,
        guest,
        setReservationError,
        uiConfig,
        confirmation,
        isUpdateReservation,
      } = this.props;
      const publicApiKey = venue?.paymentKey;
      const firstName = client
        ? client?.firstName
        : confirmation?.firstName || "";
      const lastName = client ? client?.lastName : confirmation?.lastName || "";
      const { width } = this.context;
      const isMobile = width < MAX_MOBILE_WIDTH;
      const isTabletScreen = width > MAX_MOBILE_WIDTH && width < MAX_WIDTH;
      await loadScript(
        "https://api2.heartlandportico.com/SecureSubmit.v1/token/gp-1.3.0/globalpayments.js"
      );
      GlobalPayments.configure({
        publicApiKey,
        env: "sandbox",
      });

      const inputHeight = isTabletScreen ? "40px" : isMobile ? "60px" : "56px";

      const inputStyle = {
        height: inputHeight,
        "font-size": isMobile ? "18px" : "16px",
        "padding-left": "24px",
        background: color.grayBackground2,
        color: color.text2,
        "border-radius": "4px",
        border: `1px solid ${color.grayBackground2}`,
        outline: "none",
        "box-sizing": "border-box",
      };

      const cardForm = GlobalPayments.ui.form({
        fields: {
          "card-holder-name": {
            placeholder: "Name on Card",
            target: "#credit-card-card-holder",
            value: `${firstName} ${lastName}`,
          },
          "card-number": {
            placeholder: "Credit Card Number",
            target: "#credit-card-card-number",
          },
          "card-expiration": {
            placeholder: "Expiration Date",
            target: "#credit-card-card-expiration",
          },
          "card-cvv": {
            placeholder: "Security Code",
            target: "#credit-card-card-cvv",
          },
          submit: {
            value: "Submit",
            text: isUpdateReservation
              ? "Modify Reservation"
              : "Complete Your Reservation",
            target: "#credit-card-submit",
          },
        },
        styles: {
          ".card-number": {},
          input: inputStyle,
          "input:focus": {
            border: `1px solid #0000001A`,
            background: "#EBEDF4",
          },
          "input:hover": {
            border: `1px solid #0000001A`,
            background: "#EBEDF4",
          },
          button: {
            padding: "14px",
            background: uiConfig?.mainButtonColor,
            color: uiConfig?.mainButtonTextColor,
            "font-size": "16px",
            border: "0",
            "border-radius": "4px",
            "font-weight": "600",
            "line-height": "28px",
            transition: "all .3s ease",
          },
          "button:hover": {
            background: uiConfig?.mainButtonColor,
            color: uiConfig?.mainButtonTextColor,
            opacity: 0.8,
            cursor: "pointer",
          },
          "button:focus": {
            outline: "none",
          },
        },
      });

      // form-level event handlers. examples:
      cardForm.ready(() => {
        console.log("Registration of all credit card fields occurred");
        this.setState({ loading: false, error: "" });
      });
      cardForm.on("token-success", (resp: any) => {
        if (!this.state.zipCode) {
          console.log("zip code is blank, error");
          this.setState({ loading: false, error: "please fill in zip code" });
          return;
        }
        if (!this.state.selected) {
          console.log("policies is not selected");
          this.setState({
            loading: false,
            error: "please confirm policies is read",
          });
          return;
        }
        console.log("token:", JSON.stringify(resp, null, 2));
        if (this.props.customHandler) {
          this.props.customHandler({
            ...resp,
            postalCode: this.state.zipCode,
            paymentType: PMethod.heartland,
          });
          this.setState({ loading: false });
          return;
        }
        guest
          ? this.props.completePaymentAsGuest({
            ...resp,
            postalCode: this.state.zipCode,
            paymentType: PMethod.heartland,
          })
          : this.props.completePayment({
            ...resp,
            postalCode: this.state.zipCode,
            paymentType: PMethod.heartland,
          });
        this.setState({ loading: false });
      });
      cardForm.on("token-error", (resp: any) => {
        console.log("error", resp);
        const error =
          resp?.reasons?.length > 0
            ? resp?.reasons[0].message
            : "payment error";
        this.setState({ loading: false, error });
      });

      // field-level event handlers. example:
      cardForm.on("submit", "click", () => {
        console.log("Start payment flow...");
        this.setState({ loading: true, error: "" });
      });
      cardForm.on("card-number", "register", () => {
        this.setState({ loading: false, error: "" });
        setReservationError();
        console.log("Registration of Card Number occurred");
      });
      if ((window as any).GlobalPayments) {
        this.setState({ loading: false });
        return;
      }
    } catch (e) {
      console.log("set heartland form issue", e);
    }
  }

  async composeFortisPaymentForm() {
    try {
      const { venue, getFortisClientToken } = this.props;
      if (venue) {
        this.setState({ paymentMethod: PMethod.fortis });
        if (venue.fortisUrl.includes("sandbox")) {
          await loadScript(
            "https://js.sandbox.fortis.tech/commercejs-v1.0.0.min.js"
          );
        } else {
          await loadScript("https://js.fortis.tech/commercejs-v1.0.0.min.js");
        }
        getFortisClientToken(venue.id);
      }
    } catch (e) {
      console.log("set fortis form issue", e);
    }
  }

  async composeSquarePaymentForm() {
    try {
      const { venue, getSquareAppId, checkSquareOrderCalculation } = this.props;
      const appId = await getSquareAppId();

      if (!appId || !venue?.squareIsEnable || !venue?.squareLocationId)
        throw new Error("Square Payments is not available");

      this.setState({ paymentMethod: PMethod.square });
      const isSandbox = appId.includes("sandbox");
      this.setState({ loading: true, isSquareSandbox: isSandbox });
      await loadScript(
        `https://${isSandbox ? "sandbox." : ""}web.squarecdn.com/v1/square.js`
      ).catch((error) => this.setState({ error, squarePayments: null }));

      if (!Square) throw new Error("Load Square Error");

      const result = await checkSquareOrderCalculation();
      if (result && !result.isRexSquareEqual) {
        console.log("REX and Square Orders is not equal");
        this.setState({
          error:
            "REX and Square Orders is not equal. Square Orders will not mapped.",
        });
      }
      const payments = Square.payments(
        appId,
        venue.squareLocationId
      ) as Payments;
      this.setState({ squarePayments: payments });
    } catch (e) {
      console.error("Square Error", e);
      this.setState({
        error: `${e?.toString()}. Please try another method`,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  loadStripe() {
    const { venue } = this.props;
    if (venue?.stripePublishableKey) {
      if (venue.stripeAccountId) {
        this.setState({
          stripe: loadStripe(venue?.stripePublishableKey, {
            stripeAccount: venue.stripeAccountId,
          }),
        });
      } else {
        this.setState({ stripe: loadStripe(venue.stripePublishableKey) });
      }
    }
  }

  renderSkipPayment() {
    const { uiConfig, createEmptyPayment, venue, isUpdateReservation, currentPackage } = this.props;
    const { paymentMethod, confirmButtonText, selected } = this.state;
    const { width } = this.context;
    const isMobile = width < MAX_MOBILE_WIDTH;
    return (
      <div>
        {isMobile ?
          <>
            <Box mb={'24px'}>
              <Typography className='heading-h1'>{isUpdateReservation ? 'Modify' : 'Complete'} Reservation</Typography>
            </Box>

            <InfoAlert text="No payment is required at this time. Click the button below to complete your reservation" />

            <MobileAttachedButton
              reservation={this.props.reservation}
              onButtonClick={() => createEmptyPayment(paymentMethod)}
              isSummaryExpanded={false}
              isButtonDisabled={venue?.showPolicy && !selected}
              venueId={venue?.id}
              giftCardAmount={this.props.giftCardAmount}
              isUpdateReservation={this.props.isUpdateReservation}
            >
              {confirmButtonText}
            </MobileAttachedButton>
          </>
          : <ButtonMain
            role="button"
            onClick={() => createEmptyPayment(paymentMethod)}
            disabled={venue?.showPolicy && !selected}
          >
            {confirmButtonText}
          </ButtonMain>}
        {(venue?.showPolicy || currentPackage?.showPolicy || currentPackage?.packagePolicyOverride) && (
          <VenuePolicies
            policyInfo={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyText : venue?.venueInfo}
            policyTitle={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyTitle : 'VENUE POLICIES'}
            selected={selected}
            onChange={(_: any, isChecked?: boolean) =>
              this.setState({ selected: !!isChecked })
            }
            notShowCheckbox={currentPackage?.packagePolicyOverride && !currentPackage?.showPolicy}

          />)}
      </div>
    );
  }

  renderHeartlandPayment() {
    const {
      venue,
      requestError,
      reservation,
      giftBalance,
      getGiftCardBalance,
      giftBalanceError,
      addGiftCard,
      giftCardAmount,
      applyCouponCode,
      isUpdateReservation,
      isUpdateWithVenueChange,
      oldReservation,
      currentPackage
    } = this.props;
    const { zipCode, selected, error } = this.state;
    const { width } = this.context;
    const isMobile = width < MAX_MOBILE_WIDTH;
    let deposit = calculateDeposit({
      reservation,
      giftCardAmount,
      isUpdateReservation,
      isUpdateWithVenueChange,
      oldReservation,
    });
    let isRefundPayment = deposit < 0;
    let isSkipPayment = deposit <= 0;

    let isShowRefundPaymentInfo =
      isUpdateReservation && isSkipPayment && isRefundPayment;
    let isShowSkipPaymentInfo =
      isUpdateReservation && isSkipPayment && !isRefundPayment;
    let isShowPaymentInfo = isUpdateReservation && !isSkipPayment;
    return (
      <Box>
        <PaymentContainer>
          <form id="payment-form" action="/charge" method="get">
            <>
              <div id="credit-card-card-holder"></div>
              <div
                id="credit-card-card-number"
                className={`${isMobile ? "mobile-margin" : ""}`}
              ></div>
              <div className={isMobile ? "" : "row"}>
                <div
                  id="credit-card-card-expiration"
                  className={`labeled-input ${isMobile ? "mobile-margin-low" : ""
                    }`}
                ></div>
                {isMobile ? null : <div className="row-delimiter"></div>}
                <div
                  id="credit-card-card-cvv"
                  className={`labeled-input ${isMobile ? "mobile-margin" : ""}`}
                ></div>
                {!isMobile && (
                  <>
                    <div className="row-delimiter"></div>
                    <div className="labeled-input">
                      <input
                        className="billing-zip-v2"
                        name="billing-zip"
                        type="tel"
                        placeholder="Billing Zip Code"
                        value={zipCode}
                        onChange={(e) =>
                          this.setState({ zipCode: e.target.value })
                        }
                        onKeyPress={(e) => this.disableEnterKey(e)}
                        autoComplete="off"
                      />
                    </div>
                  </>
                )}
              </div>
              {isMobile && (
                <Box className="labeled-input mobile-margin" mb={"16px"}>
                  <input
                    className="billing-zip-v2"
                    name="billing-zip"
                    type="tel"
                    placeholder="Billing Zip Code"
                    value={zipCode}
                    onKeyPress={(e) => this.disableEnterKey(e)}
                    onChange={(e) => this.setState({ zipCode: e.target.value })}
                  />
                </Box>
              )}
            </>
            {!!venue?.useGiftCard && isMobile && (
              <GiftCard
                isMobile={!!isMobile}
                deposit={deposit}
                giftBalance={giftBalance}
                getGiftCardBalance={getGiftCardBalance}
                giftBalanceError={giftBalanceError}
                addGiftCard={addGiftCard}
                giftCardAmount={giftCardAmount}
              />
            )}
            {!!venue?.useDiscount && isMobile && (
              <CouponCodes
                reservation={reservation}
                applyCouponCode={applyCouponCode}
                deposit={deposit}
                isSkipPayment={isRefundPayment}
              />
            )}
            {isMobile ? null : <InformationSecureAlert />}

            {isMobile ? (
              this.renderOrderSummary(error)
            ) : (
              <>
                {(venue?.showPolicy || currentPackage?.showPolicy || currentPackage?.packagePolicyOverride) && (
                  <VenuePolicies
                    policyInfo={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyText : venue?.venueInfo}
                    policyTitle={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyTitle : 'VENUE POLICIES'} selected={selected}
                    onChange={(_: any, isChecked?: boolean) =>
                      this.setState({ selected: !!isChecked })
                    }
                    notShowCheckbox={currentPackage?.packagePolicyOverride && !currentPackage?.showPolicy}

                  />
                )}
                {isShowRefundPaymentInfo && (
                  <InfoAlert text="You will receive a refund for the price difference" />
                )}
                {isShowSkipPaymentInfo && (
                  <InfoAlert text="There is no price difference for your new reservation. No additional payment is needed" />
                )}
                {isShowPaymentInfo && (
                  <InfoAlert text="Your new reservation requires an additional payment" />
                )}
                {(requestError || error) && (
                  <div className="error">{error ? error : requestError}</div>
                )}

                <div id="credit-card-submit"></div>
              </>
            )}
          </form>
          <MobileAttachedButton
            reservation={reservation}
            giftCardAmount={giftCardAmount}
            venueId={venue?.id}
            noButton />
        </PaymentContainer>
      </Box>
    );
  }

  renderStripePayment() {
    const {
      venue,
      uiConfig,
      requestError,
      reservation,
      makeStripeReservation,
      paymentMethodStripe,
      customStripeHandler,
      giftBalance,
      getGiftCardBalance,
      giftBalanceError,
      addGiftCard,
      giftCardAmount,
      createEmptyPayment,
      isUpdateReservation,
      isUpdateWithVenueChange,
      oldReservation,
      applyCouponCode,
      guest,
      listOfStripeSavedCards,
      currentPackage
    } = this.props;
    const { selected, stripe } = this.state;
    const { width } = this.context;
    const isMobile = width < MAX_MOBILE_WIDTH;

    return (
      <Elements stripe={stripe}>
        <StripeForm
          venue={venue}
          reservation={reservation}
          selected={selected}
          isMobile={isMobile}
          showGiftCard={!!venue?.useGiftCard}
          orderSummary={this.renderOrderSummary.bind(this)}
          uiConfig={uiConfig}
          requestError={requestError}
          makeStripeReservation={makeStripeReservation}
          paymentMethodStripe={paymentMethodStripe}
          customStripeHandler={customStripeHandler}
          getGiftCardBalance={getGiftCardBalance}
          giftBalance={giftBalance}
          giftBalanceError={giftBalanceError}
          addGiftCard={addGiftCard}
          giftCardAmount={giftCardAmount}
          showDiscounts={venue?.useDiscount}
          applyCouponCode={applyCouponCode}
          createEmptyPayment={createEmptyPayment}
          isUpdateReservation={isUpdateReservation}
          isUpdateWithVenueChange={isUpdateWithVenueChange}
          oldReservation={oldReservation}
          isGuest={!!guest}
          listOfStripeSavedCards={listOfStripeSavedCards}
          currentPackage={currentPackage}
          setSelected={(selected: boolean) => this.setState({ selected })}
        />
      </Elements>
    );
  }

  renderFortisPayment() {
    const {
      venue,
      uiConfig,
      requestError,
      reservation,
      completePayment,
      completePaymentAsGuest,
      guest,
      giftBalance,
      getGiftCardBalance,
      giftBalanceError,
      addGiftCard,
      giftCardAmount,
      createEmptyPayment,
      isUpdateReservation,
      isUpdateWithVenueChange,
      oldReservation,
      fortisClientToken,
      isPaymentInProgress,
      applyCouponCode,
      currentPackage
    } = this.props;
    const { selected } = this.state;
    const { width } = this.context;
    const isMobile = width < MAX_MOBILE_WIDTH;
    const makeFortisReservation = (payment: Payment) => {
      guest ? completePaymentAsGuest(payment) : completePayment(payment);
    };
    return (
      <FortisForm
        venue={venue}
        reservation={reservation}
        selected={selected}
        isMobile={isMobile}
        showGiftCard={!!venue?.useGiftCard}
        orderSummary={this.renderOrderSummary.bind(this)}
        uiConfig={uiConfig}
        requestError={requestError}
        makeFortisReservation={makeFortisReservation}
        getGiftCardBalance={getGiftCardBalance}
        giftBalance={giftBalance}
        giftBalanceError={giftBalanceError}
        addGiftCard={addGiftCard}
        giftCardAmount={giftCardAmount}
        showDiscounts={venue?.useDiscount}
        applyCouponCode={applyCouponCode}
        createEmptyPayment={createEmptyPayment}
        isUpdateReservation={isUpdateReservation}
        isUpdateWithVenueChange={isUpdateWithVenueChange}
        oldReservation={oldReservation}
        fortisClientToken={fortisClientToken}
        isPaymentInProgress={isPaymentInProgress}
        currentPackage={currentPackage}
      />
    );
  }

  interval: NodeJS.Timeout | null = null;
  gtWindow: any = null;
  renderGoTabPayment() {
    const {
      venue,
      requestError,
      goTabReservationId,
      reservation,
      reservationAddons,
      confirmation,
      guest,
      setGoTabId,
      getGoTabReservationStatus,
      isUpdateReservation,
      currentPackage
    } = this.props;
    const { width } = this.context;
    const { goTabLoading, selected, error, goTabPaymentCompleted } = this.state;
    const isMobile = width < MAX_MOBILE_WIDTH;
    const onClickOpenGoTabPopup = async () => {
      if (!selected && venue?.showPolicy) {
        this.setState({ error: "Please accept the venue policies to proceed." });
        return;
      }
      if (this.interval) {
        clearInterval(this.interval);
      }


      if (this.gtWindow && !this.gtWindow.closed) {
        // The popup is already open; focus on it
        this.gtWindow.focus();
      } else {

        if (!isMobile) {
          // Create a new popup window
          const width = 600;
          const height = 700;
          const left = screen.width / 2 - width / 2;
          const top = screen.height / 2 - height / 2;
          this.gtWindow = window.open(
            "",
            "goTabPopup",
            `width=${width},height=${height},left=${left},top=${top}`
          );
        }
        let pendingGoTabReservation = null
        if (!pendingGoTabReservation) {
          try {
            this.setState({ goTabLoading: true });
            pendingGoTabReservation = await createGoTabId({
              reservation,
              reservationAddons,
              confirmation,
              isClient: !guest,
              isUpdateReservation,
            });
            if (pendingGoTabReservation?.goTabId) {
              setGoTabId(pendingGoTabReservation?.goTabId);
            }
            this.setState({ goTabLoading: false });
          } catch (e) {
            console.log("error", e);
            this.setState({
              goTabLoading: false,
              error: "Sorry, we cannot complete payment. Please try again later.",
            });
          }
        }


        let goTabUrl = `https://gotab.io/.${pendingGoTabReservation?.goTabId || goTabReservationId}?mode=lite`;
        if (isMobile && pendingGoTabReservation) {
          const redirectUrl = `${window.location.origin}/confirm-alternative-reservation?id=${pendingGoTabReservation.id}&token=${pendingGoTabReservation.token}`;
          const encodedRedirectUrl = encodeURIComponent(redirectUrl);
          goTabUrl += `&redirect=${encodedRedirectUrl}`;

          window.location.href = goTabUrl;
        }
        this.gtWindow.location.href = goTabUrl;

        window.addEventListener(
          "message",
          async (event) => {
            if (event.origin !== new URL(goTabUrl).origin) {
              return;
            } else if (event.data.type === "payment-complete") {
              this.setState({ goTabPaymentCompleted: true });
              await getGoTabReservationStatus();
            }
          },
          false
        );

        window.addEventListener("beforeunload", () => {
          if (this.interval) {
            this.gtWindow = null;
            clearInterval(this.interval);
          }
        });

        if (isMobile) {
          const visibilityChangeListener = async () => {
            if (!document.hidden) { // Page has become visible
              try {
                await getGoTabReservationStatus();
              } catch (error) {
                console.error("Error checking reservation status:", error);
              }
            }
          };
          window.addEventListener("visibilitychange", visibilityChangeListener);
        }

        this.interval = setInterval(() => getGoTabReservationStatus(), 5000);
      }
    };


    const handleAcceptPolicy = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ selected: e.target.checked, error: "" });
    };

    return (
      <PaymentContainer isWithoutHeader>
        <div className="go-tab-container">
          {!isMobile && (requestError || error) && <NoAvailiableGuests noSpace text={requestError || error} mb={'24px'} />}

          {isMobile ?
            <>
              <Box mb={'24px'}>
                <Typography className='heading-h1'>{isUpdateReservation ? 'Modify' : 'Complete'} Reservation</Typography>
              </Box>
            </>

            : <div className="go-tab-buttons-container">
              <PaymentButton
                role="button"
                onClick={() => onClickOpenGoTabPopup()}
                disabled={goTabLoading || goTabPaymentCompleted}
              >
                <img src={GoTabLogo} />
              </PaymentButton>
            </div>}


          {isMobile ? (<>
            {this.renderOrderSummary(error || requestError)}
            <MobileAttachedButton
              reservation={this.props.reservation}
              isSummaryExpanded={false}
              isButtonDisabled={false}
              venueId={this.props.venue?.id}
              giftCardAmount={this.props.giftCardAmount}
              isUpdateReservation={this.props.isUpdateReservation}
              button={<PaymentButton
                role="button"
                onClick={() => onClickOpenGoTabPopup()}
                disabled={goTabLoading || goTabPaymentCompleted}
              >
                <img src={GoTabLogo} />
              </PaymentButton>}
            />
          </>
          ) : (
            <>
              {venue?.showPolicy && (
                <>
                  <div>

                    <div className="venue-policy">
                      <div className="heading">{(currentPackage?.packagePolicyOverride && currentPackage?.showPolicy) ? currentPackage?.packagePolicyText : 'VENUE POLICIES'}</div>
                      <HTMLRenderer
                        html={(currentPackage?.packagePolicyOverride && currentPackage?.showPolicy) ? currentPackage?.packagePolicyText : venue?.venueInfo}
                        className="venue-policy-value"
                      />
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selected}
                          onChange={handleAcceptPolicy}
                          name="checked"
                          color="default"
                          style={{
                            color: "#2E3A48",
                          }}
                        />
                      }
                      label={<Typography className='body-small'>I have read and agree to the venue policies.</Typography>}
                      style={{ ...checkboxStyle, marginBottom: rem(16) }}

                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </PaymentContainer>
    );
  }

  renderSquarePayment() {
    const {
      venue,
      uiConfig,
      requestError,
      reservation,
      completePayment,
      completePaymentAsGuest,
      giftBalance,
      getGiftCardBalance,
      giftBalanceError,
      addGiftCard,
      giftCardAmount,
      createEmptyPayment,
      isUpdateReservation,
      isUpdateWithVenueChange,
      oldReservation,
      applyCouponCode,
      client,
      guest,
      createSquarePartial,
      checkSquarePartialPayment,
      customHandler,
      currentPackage
    } = this.props;
    const { squarePayments, isSquareSandbox } = this.state;
    const { width } = this.context;
    const isMobile = width < MAX_MOBILE_WIDTH;
    const completePaymentBoth = (payment: Payment) => {
      guest ? completePaymentAsGuest(payment) : completePayment(payment);
    };

    return (
      <PaymentContainer isWithoutHeader>
        <SquareForm
          squarePayments={squarePayments}
          client={client}
          guest={guest}
          venue={venue}
          reservation={reservation}
          orderSummary={this.renderOrderSummary.bind(this)}
          isMobile={isMobile}
          uiConfig={uiConfig}
          completePayment={completePaymentBoth}
          giftCardAmount={giftCardAmount}
          isUpdateReservation={isUpdateReservation}
          isUpdateWithVenueChange={isUpdateWithVenueChange}
          oldReservation={oldReservation}
          showGiftCard={!!venue?.useGiftCard}
          getGiftCardBalance={getGiftCardBalance}
          giftBalance={giftBalance}
          giftBalanceError={giftBalanceError}
          addGiftCard={addGiftCard}
          showDiscounts={venue?.useDiscount}
          applyCouponCode={applyCouponCode}
          requestError={requestError}
          createEmptyPayment={createEmptyPayment}
          isSandbox={isSquareSandbox}
          createSquarePartial={createSquarePartial}
          check={checkSquarePartialPayment}
          customHandler={customHandler}
          currentPackage={currentPackage}
        />
      </PaymentContainer>
    );
  }
}
export default connector(PaymentComponent);