import { createSelector } from "reselect";
import { CheckInStatus } from "../../../server/src/entities/enums";
import { ActionTypeEnum } from "../actions";
import {
  CreateSquarePartialPaymentDto,
  CurrencyType,
  FeeType,
  GiftCardBalance,
  GiftCardPaymentRequest,
  Pricing,
  Reservation,
  ReservationAddon,
  ReservationConfirmation,
  ReservationState,
  SquareOrderDtoRes,
  State,
} from "../store/types";
import { rexAnalytics } from "../utils/analytics";
import { createReducer } from "./reducer-utils";
import { StripeSavedCard } from "../components/common/StripeForm/utils";

const initialState: ReservationState = Object.freeze({
  confirmed: false,
  isPaymentInProgress: false,
  isRequestInProgress: false,
  isCardBalanceInProgress: false,
  isStripeSavedCardsRequestInProgress: false,
  paymentCompleted: false,
  reservations: [],
  confirmation: {},
  reservation: {
    id: undefined,
    venueId: "",
    date: "",
    slots: 0,
    duration: 2,
    guests: 1,
    lanes: 1,
    price: 0,
    addonsPrice: 0,
    discount: 0,
    deposit: 0,
    depositParam: 100,
    depositType: FeeType.PERCENT,
    textBox: "",
    occasion: "",
    customNotes: "",
    tax: 0,
    serviceFee: 0,
    customFees: "",
    customTaxes: "",
    customFeesTaxes: "",
    addonCustomTaxes: "",
    pricing: Pricing.perLane,
    timeSlotDuration: 30,
    allowCancellation: false,
    allowModification: false,
    timeZone: "America/Los_Angeles",
    venueName: "",
    venueAddress: "",
    timeSlotShifting: 0,
    preBufferTime: 0,
    bufferTime: 0,
    currency: CurrencyType.USD,
    giftCardPaid: 0,
    packageId: undefined,
    modificationFee: 0,
    venueImage: "",
    modified: false,
    createdByAdmin: false,
    firstName: '',
    lastName: '',
    refunded: 0,
    payed: 0,
    checkedIn: false,
    checkInStatus: CheckInStatus.Upcoming,
    discountAmount: 0,
    discounts: ''
  },
  isPartySubmitted: false,
  showCancellation: false,
  giftCardPayment: [],
  giftCardAmount: 0,
  isUpdateReservation: false,
  isUpdateWithVenueChange: false,
  oldReservation: undefined,
  showModification: false,
  feeForModification: undefined,
  modificationFeeType: undefined,
  reservationAddons: [],
  fortisClientToken: undefined,
  goTabReservationId: undefined,
  listOfStripeSavedCards: [],
});

export default createReducer<ReservationState>(initialState, {
  [ActionTypeEnum.ResetReservation]: (reservation: Reservation) => (state: ReservationState) => ({
    ...state,
    confirmed: false,
    paymentCompleted: false,
    reservation,
    giftCardPayment: [],
    giftCardAmount: 0,
    fortisClientToken: undefined,
    goTabReservationId: undefined,
  }),
  [ActionTypeEnum.UpdateReservation]: (patch: Partial<Reservation>) => (state: ReservationState) => ({
    ...state,
    reservation: { ...state.reservation, ...patch },
  }),

  [ActionTypeEnum.StartBookingRequest]: () => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.StartBooking]: (reservation: Reservation) => (state: ReservationState) => {
    rexAnalytics.updateRexAnalyticsTotalPrice(reservation.total?.toString());
    return {
      ...state,
      reservation,
      giftCardPayment: [],
      giftCardAmount: 0,
      isRequestInProgress: false,
      error: undefined,
      fortisClientToken: undefined,
      goTabReservationId: undefined,
    }
  },
  [ActionTypeEnum.StartBookingFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    error,
    isRequestInProgress: false,
  }),
  [ActionTypeEnum.ApplyCoupons]: () => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: true,
    errorCouponCode: undefined,
  }),
  [ActionTypeEnum.ApplyCouponsSuccess]: (reservation: Reservation) => (state: ReservationState) => ({
    ...state,
    reservation,
    isRequestInProgress: false,
    errorCouponCode: undefined,
  }),
  [ActionTypeEnum.ApplyCouponsFailure]: (errorCouponCode: string) => (state: ReservationState) => ({
    ...state,
    errorCouponCode,
    isRequestInProgress: false,
  }),
  [ActionTypeEnum.StopBooking]: () => (state: ReservationState) => ({
    ...state,
    ...initialState,
  }),
  [ActionTypeEnum.ConfirmReservation]: (confirmed) => (state: ReservationState) => ({
    ...state,
    confirmed,
  }),
  [ActionTypeEnum.PaymentRequest]: () => (state: ReservationState) => ({
    ...state,
    isPaymentInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.PaymentSuccess]: ({ number, id }: { number: string, id: string }) => (state: ReservationState) => ({
    ...state,
    isPaymentInProgress: false,
    error: undefined,
    paymentCompleted: true,
    confirmation: {},
    reservation: { ...state.reservation, number, id },
    fortisClientToken: undefined,
    goTabReservationId: undefined,
  }),
  [ActionTypeEnum.PaymentFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    isPaymentInProgress: false,
    error,
  }),
  [ActionTypeEnum.GetReservationsRequest]: () => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GetReservationsSuccess]: (reservations: Reservation[]) => (state: ReservationState) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
    reservations,
  }),
  [ActionTypeEnum.GetReservationsFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.UpdateConfirmation]: (patch: Partial<ReservationConfirmation>) => (state: ReservationState) => ({
    ...state,
    confirmation: { ...state.confirmation, ...patch },
  }),
  [ActionTypeEnum.SubmitPartyRequest]: () => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: true,
    isPartySubmitted: false,
    error: undefined,
    party: undefined,
  }),
  [ActionTypeEnum.SubmitPartySuccess]: (party) => (state: ReservationState) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
    isPartySubmitted: true,
    party,
  }),
  [ActionTypeEnum.SubmitPartyFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: false,
    isPartySubmitted: false,
    error,
  }),
  [ActionTypeEnum.SetIsPartySubmitted]: (isPartySubmitted: boolean) => (state: ReservationState) => ({
    ...state,
    isPartySubmitted,
    party: isPartySubmitted ? state.party : undefined,
  }),
  [ActionTypeEnum.SetReservationError]: (error: string) => (state: ReservationState) => ({
    ...state,
    error,
  }),
  [ActionTypeEnum.CancelReservation]: () => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.CancelReservationSuccess]: (reservation: Reservation) => (state: ReservationState) => {
    return {
      ...state,
      isRequestInProgress: false,
      reservations: state.reservations.map((r) =>
        r.id === reservation.id ? reservation : r
      ),
      error: undefined,
    };
  },
  [ActionTypeEnum.CancelReservationFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.GetReservationCancellationRequest]: () => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GetReservationCancellationSuccess]: (cancellation) => (
    state: ReservationState
  ) => {
    return {
      ...state,
      isRequestInProgress: false,
      cancellationFee: cancellation.cancellationFee,
      cancellationFeeType: cancellation.cancellationFeeType,
      showCancellation: true,
      error: undefined,
    };
  },
  [ActionTypeEnum.GetReservationCancellationFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.GetReservationModificationRequest]: () => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GetReservationModificationSuccess]: (modification) => (
    state: ReservationState
  ) => {
    return {
      ...state,
      isRequestInProgress: false,
      feeForModification: modification.feeForModification,
      modificationFeeType: modification.modificationFeeType,
      showModification: true,
      error: undefined,
    };
  },
  [ActionTypeEnum.GetReservationModificationFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.HideModification]: () => (state: ReservationState) => ({
    ...state,
    showModification: false,
  }),
  [ActionTypeEnum.ClearReservationModification]: () => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: false,
    feeForModification: undefined,
    modificationFeeType: undefined,
  }),
  [ActionTypeEnum.LoadReservation]: () => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.LoadReservationSuccess]: ({ reservation, reservationAddons }: { reservation: Reservation, reservationAddons: ReservationAddon[] }) => (
    state: ReservationState
  ) => {
    rexAnalytics.updateRexAnalyticsPackageName(reservation?.packageName);
    rexAnalytics.updateRexAnalyticsTotalPrice(reservation?.total?.toString());

    return {
      ...state,
      isRequestInProgress: false,
      reservation,
      reservationAddons: reservationAddons || [],
      goTabReservationId: reservation.goTabId || undefined,
      error: undefined,
    };
  },
  [ActionTypeEnum.LoadReservationFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.RePayRequest]: () => (state: ReservationState) => ({
    ...state,
    isPaymentInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.RePayRequestSuccess]: () => (
    state: ReservationState
  ) => {
    return {
      ...state,
      isPaymentInProgress: false,
      error: undefined,
      paymentCompleted: true,
      confirmation: {},
    };
  },
  [ActionTypeEnum.RePayRequestFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    isPaymentInProgress: false,
    error,
  }),
  [ActionTypeEnum.HideCancellation]: () => (state: ReservationState) => ({
    ...state,
    showCancellation: false,
  }),
  [ActionTypeEnum.GetGiftCardBalance]: () => (state: ReservationState) => ({
    ...state,
    isCardBalanceInProgress: true,
    giftCardBalance: undefined,
    giftBalanceError: undefined,
  }),
  [ActionTypeEnum.GetGiftCardBalanceSuccess]: (giftCardBalance: GiftCardBalance) => (state: ReservationState) => ({
    ...state,
    isCardBalanceInProgress: false,
    giftCardBalance,
    giftBalanceError: undefined,
  }),
  [ActionTypeEnum.GetGiftCardBalanceFailure]: (giftBalanceError: string) => (state: ReservationState) => ({
    ...state,
    isCardBalanceInProgress: false,
    giftBalanceError,
  }),
  [ActionTypeEnum.addGiftCardPayment]: ({
    newGiftCard,
    giftCardAmount,
  }: {
    newGiftCard: GiftCardPaymentRequest,
    giftCardAmount: number,
  }) => (state: ReservationState) => ({
    ...state,
    giftCardPayment: [...state.giftCardPayment, newGiftCard],
    giftCardAmount,
    giftCardBalance: undefined,
  }),
  [ActionTypeEnum.SetUpdatingReservation]: (reservation: Reservation) => (state: ReservationState) => ({
    ...state,
    reservation,
    oldReservation: reservation,
    isUpdateReservation: true,
  }),
  [ActionTypeEnum.SetUpdatingWithVenueChange]: () => (state: ReservationState) => ({
    ...state,
    isUpdateWithVenueChange: true,
  }),
  [ActionTypeEnum.SetReservationAddons]: (reservationAddons: ReservationAddon[]) => (state: ReservationState) => ({
    ...state,
    reservationAddons,
  }),
  [ActionTypeEnum.SetFortisClientToken]: (fortisClientToken: string) => (state: ReservationState) => ({
    ...state,
    fortisClientToken,
  }),
  [ActionTypeEnum.GoTabInformationRequest]: () => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GoTabInformationRequestSuccess]: (goTabReservationId: string) => (state: ReservationState) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
    goTabReservationId,
  }),
  [ActionTypeEnum.GoTabInformationRequestFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.GoTabPaymentStatus]: () => (state: ReservationState) => ({
    ...state,
    error: undefined,
  }),
  [ActionTypeEnum.GoTabPaymentStatusSuccess]: ({ number, id }: { number: string, id: string }) => (state: ReservationState) => ({
    ...state,
    error: undefined,
    paymentCompleted: true,
    confirmation: {},
    reservation: { ...state.reservation, number, id },
    fortisClientToken: undefined,
    goTabReservationId: undefined,
  }),
  [ActionTypeEnum.GoTabPaymentStatusFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    error,
  }),
  [ActionTypeEnum.CheckSquareOrderCalculation]: () => (state: ReservationState): ReservationState => ({
    ...state,
    error: undefined,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.CheckSquareOrderCalculationSuccess]: ({ isRexSquareEqual }: SquareOrderDtoRes) => (state: ReservationState): ReservationState => ({
    ...state,
    error: isRexSquareEqual ? undefined : "REX and Square Orders is not equal. Square Orders will not mapped.",
    isRequestInProgress: false,
  }),
  [ActionTypeEnum.CheckSquareOrderCalculationFailure]: (error: string) => (state: ReservationState): ReservationState => ({
    ...state,
    error,
    isRequestInProgress: false,
  }),
  [ActionTypeEnum.CreateSquarePartial]: () => (state: ReservationState): ReservationState => ({
    ...state,
    error: undefined,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.CreateSquarePartialSuccess]: ({ reservation }: CreateSquarePartialPaymentDto) => (state: ReservationState): ReservationState => ({
    ...state,
    reservation: {
      ...state.reservation,
      ...reservation,
    },
    error: undefined,
    isRequestInProgress: false,
  }),
  [ActionTypeEnum.CreateSquarePartialFailure]: (error: string) => (state: ReservationState): ReservationState => ({
    ...state,
    error,
    isRequestInProgress: false,
  }),
  [ActionTypeEnum.GetStripeListOfSavedCardsRequest]: () => (state: ReservationState) => ({
    ...state,
    isStripeSavedCardsRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GetStripeListOfSavedCardsSuccess]: (listOfStripeSavedCards: { data: StripeSavedCard[] }) => (state: ReservationState) => ({
    ...state,
    listOfStripeSavedCards: listOfStripeSavedCards?.data || [],
    isStripeSavedCardsRequestInProgress: false,
    error: undefined,
  }),
  [ActionTypeEnum.GetStripeListOfSavedCardsFailure]: (error: string) => (state: ReservationState) => ({
    ...state,
    isStripeSavedCardsRequestInProgress: false,
    error,
  }),
});

export const selectReservationState = (state: State) => state.reservation;
export const selectReservation = createSelector(
  selectReservationState,
  (state: ReservationState) => state.reservation
);
export const selectConfirmed = createSelector(
  selectReservationState,
  (state: ReservationState) => state.confirmed
);
export const selectPaymentCompleted = createSelector(
  selectReservationState,
  (state: ReservationState) => state.paymentCompleted
);
export const selectIsPaymentInProgress = createSelector(
  selectReservationState,
  (state: ReservationState) => state.isPaymentInProgress
);
export const selectReservations = createSelector(
  selectReservationState,
  (state: ReservationState) => state.reservations
);
export const selectIsRequestInProgress = createSelector(
  selectReservationState,
  (state: ReservationState) => state.isRequestInProgress
);
export const selectRequestError = createSelector(
  selectReservationState,
  (state: ReservationState) => state.error
);
export const selectReservationConfirmation = createSelector(
  selectReservationState,
  (state: ReservationState) => state.confirmation
);
export const selectIsPartySubmitted = createSelector(
  selectReservationState,
  (state: ReservationState) => state.isPartySubmitted
);
export const selectParty = createSelector(
  selectReservationState,
  (state: ReservationState) => state.party
);
export const selectCancellationFee = createSelector(
  selectReservationState,
  (state: ReservationState) => state.cancellationFee
);
export const selectCancellationFeeType = createSelector(
  selectReservationState,
  (state: ReservationState) => state.cancellationFeeType
);
export const selectShowCancellation = createSelector(
  selectReservationState,
  (state: ReservationState) => state.showCancellation
);
export const selectIsCardBalanceInProgress = createSelector(
  selectReservationState,
  (state: ReservationState) => state.isCardBalanceInProgress
);
export const selectGiftCardBalance = createSelector(
  selectReservationState,
  (state: ReservationState) => state.giftCardBalance
);
export const selectGiftBalanceError = createSelector(
  selectReservationState,
  (state: ReservationState) => state.giftBalanceError
);
export const selectGiftCardPayment = createSelector(
  selectReservationState,
  (state: ReservationState) => state.giftCardPayment
);
export const selectGiftCardAmount = createSelector(
  selectReservationState,
  (state: ReservationState) => state.giftCardAmount
);
export const selectIsUpdateReservation = createSelector(
  selectReservationState,
  (state: ReservationState) => state.isUpdateReservation
);
export const selectIsUpdateWithVenueChange = createSelector(
  selectReservationState,
  (state: ReservationState) => state.isUpdateWithVenueChange
);
export const selectOldReservation = createSelector(
  selectReservationState,
  (state: ReservationState) => state.oldReservation
);
export const selectFeeForModification = createSelector(
  selectReservationState,
  (state: ReservationState) => state.feeForModification
);
export const selectModificationFeeType = createSelector(
  selectReservationState,
  (state: ReservationState) => state.modificationFeeType
);
export const selectShowModification = createSelector(
  selectReservationState,
  (state: ReservationState) => state.showModification
);
export const selectReservationAddons = createSelector(
  selectReservationState,
  (state: ReservationState) => state.reservationAddons
);
export const selectFortisClientToken = createSelector(
  selectReservationState,
  (state: ReservationState) => state.fortisClientToken
);
export const selectGoTabReservationId = createSelector(
  selectReservationState,
  (state: ReservationState) => state.goTabReservationId
);

export const selectListOfStripePaymentMethods = createSelector(
  selectReservationState,
  (state: ReservationState) => state.listOfStripeSavedCards
);

export const selectIsStripePaymentMethodsRequestInProgress = createSelector(
  selectReservationState,
  (state: ReservationState) => state.isStripeSavedCardsRequestInProgress
);

export const selectErrorCouponCode = createSelector(
  selectReservationState,
  (state: ReservationState) => state.errorCouponCode
);
