import { AddonDto } from "../../../../../server/src/dto/addon.dto";
import { AddonModifierDto } from "../../../../../server/src/dto/addonModifier.dto";
import { ReservationAddonDto } from "../../../../../server/src/dto/reservationAddon.dto";

export function getSelectedModifiersAndOptions(
    addonsData: AddonDto,
    selectedOptionsData?: ReservationAddonDto[],
) {
    if (!selectedOptionsData) {
        return [];
    }
    const selectedModifiers: AddonModifierDto[] = [];

    selectedOptionsData.forEach((selected) => {
        const { addonId, options: selectedOptions } = selected;

        // Find the corresponding addon
        if (addonsData.id === addonId) {
            addonsData?.modifiers?.forEach((modifier) => {
                const selectedOptionsForModifier = modifier.options.filter((option) =>
                    selectedOptions?.some((selOption) => selOption.optionId === option.id),
                );

                if (selectedOptionsForModifier.length > 0) {
                    selectedModifiers.push({
                        ...modifier,
                        options: selectedOptionsForModifier.map((option) => ({
                            ...option,
                            price: option.price,
                        })),
                    });
                }
            });
        }
    });

    return selectedModifiers;
}

export function filterDisabledResources(
    resourceMappings: Record<string, string[]>,
    categories: { id: string; resources: { id: string; disabled: boolean }[] }[],
) {
    categories.forEach((category) => {
        const resourceIDs = resourceMappings[category.id];
        if (resourceIDs) {
            const activeResourceIDs = category.resources
                .filter(
                    (resource) => !resource.disabled && resourceIDs.includes(resource.id),
                )
                .map((resource) => resource.id);
            resourceMappings[category.id] = activeResourceIDs;
        }
    });
    return resourceMappings;
}
