import { useEffect } from "react";

const useConfirmationScript = (confirmationPageScript?: string) => {
    if (!confirmationPageScript) return;

    useEffect(() => {
        if (confirmationPageScript) {
            try {
                const node = document
                    .createRange()
                    .createContextualFragment(confirmationPageScript);
                document.body.appendChild(node);
            } catch (e) {
                //ignore
                console.log("confirmationPageScript error:", e);
            }
        }
    }, [confirmationPageScript]);
}

export default useConfirmationScript;