import { CSSProperties } from "react";
import { Reservation } from "../../store/types/reservation";
import { PackageName } from "../../store/types";

export const maxTypographyStyle: CSSProperties = {
    maxWidth: '180px',
    overflowWrap: 'break-word'
}


export const getAgeBasedGroups = (
    reservation: Reservation,
    resPackage?: PackageName,
) => {
    if (
        reservation?.guestsWithAgeGroups &&
        reservation.guestsWithAgeGroups.length > 0
    ) {
        const formattedGuests = reservation.guestsWithAgeGroups
            .map((ageGroup) => {
                const matchingGroup = resPackage?.ageGroups?.find(
                    (pkgGroup) => pkgGroup.groupName === ageGroup.ageGroup,
                );
                const ageGroupName = matchingGroup
                    ? matchingGroup.abbreviation
                    : ageGroup.ageGroup;
                return `${ageGroup.count} ${ageGroupName}`;
            })
            .join(', ');
        return ` (${formattedGuests})`;
    }
    return '';
};
