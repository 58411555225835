import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import CloseIcon from '../../../../assets/close.svgr';
import { Venue } from '../../../store/types';
import LinkIcons from '../../LinkIcons/LinkIcons';
import LinkWithIcon from '../../MakeReservation/LinkWithIcon';
import SwipeableBottomDrawer from '../SwipeableDrawer/SwipeableBottomDrawer';
import { VenueAddress } from '../VenueAddress/VenueAddress';

import HTMLRenderer from '../../../../../common/components/HTMLRenderer/HTMLRenderer';
import { formatPhone } from '../../../../../common/utils/formats';
import AccountBlueIcon from '../../../../assets/account-blue.svgr';
import CallIcon from '../../../../assets/call.svgr';
import LocationOnIcon from '../../../../assets/location_on.svgr';
import RestaurantIcon from '../../../../assets/restaurant.svgr';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
interface Props {
    open: boolean;
    onClose: () => void;
    venue?: Venue;
    isLoggedIn?: boolean;
    onLogout?: () => void;
    onLogin?: () => void;
    onAccount?: () => void;
}

const MakeReservationDrawer = ({ open, onClose, venue, isLoggedIn, onLogout, onLogin, onAccount }: Props) => {
    const venuePhone = formatPhone(venue?.phone);

    const mapRef = `${venue?.mapUrl}`;

    return (
        <SwipeableBottomDrawer open={open} onClose={onClose} isMakeReservation>
            <Box position={'relative'} p={'32px 24px'} pt={'28px'} pb={0}>
                <Button className='gallery-btn' onClick={onClose}>
                    <CloseIcon />
                </Button>
                <Box height={'32px'} />
                <Typography className='heading-h1'>{venue?.packagesTitle || 'Make a Reservation'}</Typography>
                <Box height={'4px'} />
                {venue?.packagesDescription && <HTMLRenderer
                    html={venue?.packagesDescription}
                    className="makePackageReservation-reservation-text-v2"
                />}
                <Box pt={'32px'}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                        <Typography className='body-huge bold'>{venue?.name}</Typography>
                        {/* <Link href='#' className='makeReservation-summary-link mb-2px'>
                                Change
                            </Link> */}
                    </Box>
                    <VenueAddress venue={venue} />
                    <Box position={'absolute'} top={'32px'} right={'24px'}>
                        <LinkIcons twitterLink={venue?.twitterUrl} instLink={venue?.instagramUrl} fbLink={venue?.facebookUrl} tiktokLink={venue?.tikTokUrl} />
                    </Box>
                </Box>

                <Box mt={'16px'}>
                    <LinkWithIcon icon={<LocationOnIcon />} link={mapRef} title='Get Directions' target='_blank' containerProps={{ pl: 0 }} />
                    {venuePhone && <LinkWithIcon icon={<CallIcon />} link={`tel:${venuePhone}`} title={venuePhone} containerProps={{ pl: 0 }} />}
                    {venue?.viewMenuUrl && <LinkWithIcon icon={<RestaurantIcon />} link={venue?.viewMenuUrl} title='View Menu' target='_blank' containerProps={{ pl: 0 }} />}
                    {isLoggedIn && <LinkWithIcon withoutLink icon={<AccountBlueIcon />} onClick={onAccount} title={'Account'} containerProps={{ pl: 0 }} />}
                    <LinkWithIcon withoutLink icon={isLoggedIn ? <MaterialIcon icon={'logout'} /> : <AccountBlueIcon />} onClick={isLoggedIn ? onLogout : onLogin} title={isLoggedIn ? 'Logout' : 'Login'} containerProps={{ pl: 0 }} />

                </Box>
            </Box>

        </SwipeableBottomDrawer >
    )
}

export default MakeReservationDrawer