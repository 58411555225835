import React, { useEffect } from 'react';
import { DefaultButton, IButtonStyles, Modal } from "office-ui-fabric-react";
import { useViewport } from "../../../hooks/responsive";
import MobileVerificationIcon from "../../../../assets/mobile-verification-icon.svg";
import CancelButton from '../../../../../common/components/CancelButton';
import { color } from '../../../../../common/constants/styles';
import './verification-code-modal.scss'

const buttonStyle: IButtonStyles = ({
  root: {
    background: 'transparent',
    color: color.blue,
    padding: 0,
    border: 0,
    height: "100%",
    minWidth: "0",
    width: "auto",
    marginRight: "10px",
    fontSize: "inherit",
    textTransform: "uppercase",
  },
  rootHovered: {
    background: "transparent",
  },
  rootPressed: {
    background: "transparent",
  },
  flexContainer: {
    justifyContent: "flex-end",
  },
  textContainer: {
    flexGrow: 0,
  },
  label: {
    border: "1px solid transparent",
  },
  labelHovered: {
    borderBottom: `1px solid ${color.blue}`,
    color: color.blue,
  },
});

type Props = {
  phone?: string;
  onConfirmValidationCode: (code: string) => void;
  onClose: () => void;
  resendCode: (phone: string) => void;
};

const VerificationCodeModal = ({
  phone,
  onConfirmValidationCode,
  onClose,
  resendCode,
}: Props) => {
  const { isMobile } = useViewport()

  const [code, setCode] = React.useState<string[]>(Array(6).fill(''));


  const onCodeChange = (index: number, value?: string) => {
    let newCode = code;
    newCode.splice(index, 1, value || '');
    setCode([...newCode]);
  }

  const handleKeywordKeyPress = (e: React.KeyboardEvent, index: number) => {
    e.preventDefault();
    const regKeywordKey = /\d{1}/;
    if (e.key === "ArrowLeft") {
      if (index > 0) {
        const prevElem = document.getElementById(`code-field${index - 1}`);
        prevElem?.focus();
      }
    }
    if (e.key === "Backspace" || e.key === "Delete") {
      if (!!code[index]) {
        onCodeChange(index, undefined);
      }
    }
    if (regKeywordKey.test(e.key)) {
      onCodeChange(index, e.key);
      if (index < code.length - 1) {
        const nextElem = document.getElementById(`code-field${index + 1}`);
        nextElem?.focus();
      }
      const newCode = code;
      newCode[index] = e.key;
      const isValidCode = newCode.every(item => !!item && !isNaN(Number(item))) ? true : false;
      if (isValidCode) {
        const codeForConfirm = newCode.join('');
        onConfirmValidationCode(codeForConfirm);
      }
    }
  }
  const onResendCode = () => {
    if (phone) {
      setCode(Array(6).fill(''));
      const firstElem = document.getElementById(`code-field0`);
      firstElem?.focus();
      resendCode(phone);
    }
  }


  return (
    <Modal
      isOpen
      onDismiss={() => onClose()}
      isBlocking={true}
      containerClassName={`verification-code-modal ${isMobile ? "mobile" : ""
        }`}
    >
      <div className="container">
        <div className='header-container'>
          <img src={MobileVerificationIcon} className='verification-icon' />
          <CancelButton onClick={() => onClose()} />
        </div>
        <h3 className='bold-text'>
          Verify your mobile phone!
        </h3>
        <p className='verification-code-text'>
          {`We sent a 6-digit confirmation code to ${phone}. It expires shortly, so enter it soon.`}
        </p>
        <div className='fields-container'>
          {code.map((item, index) => {
            return (
              <div className='field-block' key={index}>
                {index === 3 && (<div className='delimiter'> - </div>)}
                <input
                  id={`code-field${index}`}
                  tabIndex={0}
                  autoFocus={index === 0}
                  size={1}
                  type='text'
                  style={{
                    backgroundColor: !!code[index] ? `${color.grayBackground4}` : 'transparent',
                    border: !!code[index] ? 'none' : `2px solid ${color.grayBorder2}`,
                  }}
                  onKeyUp={(e) => handleKeywordKeyPress(e, index)}
                  className='code-field'
                  maxLength={1}
                  value={code[index] as string}
                />
              </div>
            )
          })}
        </div>
        <div className='verification-code-modal-button'>
          <DefaultButton
            className="focusableHeaderButton"
            styles={buttonStyle}
            onClick={() => onResendCode()}
            name="resendCode"
          >
            Resend Code
          </DefaultButton>
        </div>
      </div>
    </Modal>
  );
};

export default VerificationCodeModal;