
const findScript = (url: string): HTMLScriptElement | null => {
  const scripts = document.querySelectorAll<HTMLScriptElement>(
    `script[src^="${url}"]`
  );
  console.log('find ', url, scripts)

  return scripts[0];
};

const injectScript = (url: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.src = url;

  const headOrBody = document.head || document.body;

  if (!headOrBody) {
    throw new Error(
      'Expected document.body not to be null. Stripe.js requires a <body> element.'
    );
  }

  headOrBody.appendChild(script);

  return script;
};


export const loadScript = (url: string): Promise<void> => {

  return new Promise((resolve, reject) => {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      // Resolve to null when imported server side. This makes the module
      // safe to import in an isomorphic code base.
      resolve();
      return;
    }

    try {
      let script = findScript(url);

      if (script) {
        console.warn('looks like script: ', url, ' is loaded');
        resolve()
      } else if (!script) {
        script = injectScript(url);
      }

      script.addEventListener('load', () => {
        // todo, should we check load?
        resolve();
      });

      script.addEventListener('error', () => {
        reject(new Error('Failed to load script'));
      });
    } catch (error) {
      reject(error);
      return;
    }
  });
};

export const loadStylesheet = (url: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (document.querySelector('link[href="' + url + '"]')) {
      resolve(
        `You don't need to load stylesheet with url - ${url}. Because it was loaded earlier`
      );
      return;
    }
    var link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = url;
    link.onload = () =>
      resolve(`Stylesheet with url - ${url} successfully loaded`);
    link.onerror = reject;
    document.head.appendChild(link);
  });
};
