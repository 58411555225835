import { Box, Checkbox, FormControlLabel, FormHelperText, Link, Typography } from "@material-ui/core";
import { MobileFriendly } from "@material-ui/icons";
import React, { ChangeEvent, useEffect, useState } from "react";
import { isValidEmail } from "../../../../../common/utils/validate";
import LoginIcon from '../../../../assets/login.svgr';
import { useViewport } from "../../../hooks/responsive";
import { NewGuest, UIConfigDto } from "../../../store/types";
import ButtonMain from "../../common/ButtonMain/ButtonMain";
import ButtonWithIcon2 from "../../common/ButtonMain/ButtonWithIcon2";
import Input from "../../common/Input/Input";
import useAccount2Styles from "../Account/useAccount2Styles";
import "./continueAsGuest.scss";
import HtmlRenderer from "../../../../../common/components/HTMLRenderer/HTMLRenderer";
import "./continueAsGuest.scss";

interface Props {
    continueAsGuest: (newGuest: NewGuest) => void;
    cleanError: () => void;
    continueReservation: boolean;
    error?: string;
    uiConfig?: UIConfigDto;
    onCreateAccount: () => void;
    onSignIn: () => void;
    keepMeUpdatedText?: string
}

export const ContinueAsGuest = ({
    continueAsGuest,
    cleanError,
    error,
    continueReservation,
    uiConfig,
    onSignIn,
    onCreateAccount,
    keepMeUpdatedText
}: Props) => {
    const { isMobile } = useViewport();
    const [email, setEmail] = useState<string | undefined>("");
    const [confirmEmail, setConfirmEmail] = useState<string | undefined>("");
    const [subscribeToOffers, setSubscribeToOffers] = useState<boolean>(true);
    const classes = useAccount2Styles();

    const [errorEmail, setErrorEmail] = useState<string>("");
    const [errorConfirmEmail, setErrorConfirmEmail] = useState<string>("");
    useEffect(() => () => cleanError(), [cleanError]);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const emailLower = email?.toLowerCase();
        const confirmEmailLower = confirmEmail?.toLowerCase();

        if (
            emailLower &&
            confirmEmailLower &&
            emailLower === confirmEmailLower &&
            isValidEmail(emailLower)
        ) {
            continueAsGuest({
                email: emailLower,
                subscribeToOffers,
            });
            return;
        }
        if (
            emailLower &&
            confirmEmailLower &&
            emailLower === confirmEmailLower &&
            isValidEmail(emailLower) == true
        ) {
            setErrorEmail("");
            setErrorConfirmEmail("");
        }
        if (!emailLower || !isValidEmail(emailLower)) {
            setErrorEmail("Please enter a valid email address.");
        }
        if (!confirmEmailLower || !isValidEmail(confirmEmailLower)) {
            setErrorConfirmEmail("Please enter a valid email address.");
        }
        if (confirmEmailLower && emailLower && emailLower !== confirmEmailLower) {
            setErrorEmail("These fields must match.");
            setErrorConfirmEmail("These fields must match.");
        }
    };

    const onEmailChange = (e: ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorEmail("");
        setErrorConfirmEmail("");
        setEmail(e.target.value);
    };
    const onConfirmEmailChange = (e: ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorEmail("");
        setErrorConfirmEmail("");
        setConfirmEmail(e.target.value);
    };
    return (
        <Box maxWidth={isMobile ? '100%' : '400px'} marginX={'auto'} textAlign={'center'} width={isMobile ? '100%' : '400px'} px={isMobile ? '24px' : 0}>
            <Typography className={`heading-h1 ${classes.title}`}>Guest Checkout</Typography>
            {!uiConfig?.hideCreateAccount && <Typography className="body-big">
                Already have an account?
                <Link className={classes.link} onClick={onSignIn}>Sign in</Link>
            </Typography>}
            <form onSubmit={onSubmit}>

                <Box display={'flex'} flexDirection={'column'} style={{ gap: '16px' }} mt={'32px'}>
                    <Input autoComplete="email" type="email" errorInput={errorEmail} label="Email Address" placeholder='Enter your email' value={email} onChange={onEmailChange} fullWidth />
                    <Input autoComplete="off" type="email" errorInput={errorConfirmEmail} label="Confirm Email Address" placeholder='Confirm your email' value={confirmEmail} onChange={onConfirmEmailChange} fullWidth />
                    {error && <FormHelperText className={`caption ${classes.error}`}>{error}</FormHelperText>}
                </Box>

                <Box mt={'32px'}>
                    <FormControlLabel
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        control={
                            <Checkbox
                                checked={subscribeToOffers}
                                onChange={() => setSubscribeToOffers(!subscribeToOffers)}
                                name="checked"
                                color="default"
                                style={{
                                    color: "#2E3A48",
                                }}
                            />
                        }
                        label={<HtmlRenderer className="body-small" html={keepMeUpdatedText || 'Keep me updated on exclusive offers and events'} />}
                    />
                </Box>

                <Typography className={`body-small ${classes.textAlignLeft} ${classes.procceding}`}>
                    By proceeding, you will create an account and agree to our{' '}
                    <Link href={uiConfig?.privacyPolicy} target="_blank" className={classes.linkBlack}>Privacy Policy</Link>
                    {' '}and{' '}
                    <Link href={uiConfig?.termsOfUse} target="_blank" className={classes.linkBlack}> Terms of Use </Link>
                </Typography>

                <ButtonMain type="submit" isMobileAttached={isMobile} >Continue</ButtonMain>
            </form>

            {!uiConfig?.hideCreateAccount &&
                <>
                    <Box my={'32px'} position={'relative'}>
                        <hr className={classes.lineThrough} />
                        <Typography className={`body-small bold ${classes.or}`}>OR</Typography>
                    </Box>

                    <Box display={'flex'} flexDirection={'column'} style={{ gap: '20px' }}>
                        {!uiConfig?.hideCreateAccount && <ButtonWithIcon2 icon={<MobileFriendly style={{ color: '#212B36' }} />} onClick={onCreateAccount}>Create an account</ButtonWithIcon2>}
                        <ButtonWithIcon2 icon={<LoginIcon />} onClick={onSignIn}>Sign in</ButtonWithIcon2>
                    </Box>
                </>}
        </Box>
    );
};
