import { createSelector } from "reselect";
import { ActionTypeEnum } from "../actions";
import { MembershipPlan } from "../components/Memberships/entity";
import { State } from "../store/types";
import { MembershipBilling, MembershipState } from "../store/types/memberships";
import { createReducer } from "./reducer-utils";

const initialState: MembershipState = Object.freeze({
    stripeClientSecret: "",
    stripePublishableKey: "",
    isRequestInProgress: false,
    plans: [],
    selectedPlan: undefined,
    billing: MembershipBilling.MONTHLY,
});

export default createReducer<MembershipState>(initialState, {
    [ActionTypeEnum.SelectPlan]: (id?: string) => (state: MembershipState) => {
        const selectedPlan = id
            ? state.plans.find((p) => p.id === id)
            : undefined;
        return {
            ...state,
            selectedPlan,
        };
    },

    [ActionTypeEnum.GetPlans]: () => (state: MembershipState) => ({
        ...state,
        isRequestInProgress: true,
    }),
    [ActionTypeEnum.GetPlansSuccess]:
        (plans: MembershipPlan[]) => (state: MembershipState) => {
            return {
                ...state,
                isRequestInProgress: false,
                plans,
            };
        },
    [ActionTypeEnum.GetPlansFailure]:
        (error?: string) => (state: MembershipState) => ({
            ...state,
            isRequestInProgress: false,
            error,
        }),
    [ActionTypeEnum.GetBillingSessionFailure]:
        (error?: string) => (state: MembershipState) => ({
            ...state,
            isRequestInProgress: false,
            error,
        }),
    [ActionTypeEnum.SetSelectedPlan]:
        (plan: MembershipPlan) => (state: MembershipState) => ({
            ...state,
            selectedPlan: plan,
        }),
    [ActionTypeEnum.SetBilling]:
        (billing: MembershipBilling) => (state: MembershipState) => ({
            ...state,
            billing,
        }),
    [ActionTypeEnum.SetSinglePlan]:
        (singlePlan: MembershipPlan) => (state: MembershipState) => ({
            ...state,
            singlePlan,
        }),
});

export const selectMembershipState = (state: State) => state.membership;
export const selectPlans = createSelector(
    selectMembershipState,
    (state: MembershipState) => state.plans
);
export const selectPlan = createSelector(
    selectMembershipState,
    (state: MembershipState) => state.selectedPlan
);
export const selectBilling = createSelector(
    selectMembershipState,
    (state: MembershipState) => state.billing
);
export const selectIsMembershipRequestInProgress = createSelector(
    selectMembershipState,
    (state: MembershipState) => state.isRequestInProgress
);
export const selectError = createSelector(
    selectMembershipState,
    (state: MembershipState) => state.error
);
export const selectSinglePlan = createSelector(
    selectMembershipState,
    (state: MembershipState) => state.singlePlan
);
