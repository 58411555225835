import { Box, Button, ButtonProps, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import React, { ReactNode } from 'react';
import { State } from '../../../store/types';

interface Props extends ButtonProps {
    children: ReactNode;
    style?: React.CSSProperties;
    icon?: ReactNode;
}

const useStyles = makeStyles({
    btnV2: {
        borderRadius: '4px',
        padding: '14px',
        width: '100%',
        border: '1px solid #A3ACBA',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#2E3A48',
        maxHeight: '56px',
        transition: 'all .3s ease',
        boxShadow: 'none',
        textTransform: 'none',
        position: 'relative',

        '&:hover': {
            opacity: 0.8
        },
        '&:disabled': {
            opacity: 0.7
        }
    },
    icon: {
        position: 'absolute',
        left: '16px',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    }
});
const ButtonWithIcon2 = ({ children, style, icon, ...props }: Props) => {
    const classes = useStyles()

    return (
        <Button className={classes.btnV2} {...props}  >
            <Box className={classes.icon}>
                {icon}
            </Box>
            {children}
        </Button>
    )
}

export default ButtonWithIcon2