import { AddonDto as Addon } from "../../../../server/src/dto/addon.dto";
import { PackageNameDto as PackageName } from "../../../../server/src/dto/packageName.dto";
import { Availability, VenueDto as Venue } from "../../../../server/src/dto/venue.dto";
export { AddonDto as Addon } from "../../../../server/src/dto/addon.dto";
export { PackageNameDto as PackageName } from "../../../../server/src/dto/packageName.dto";
export { Availability, VenueDto as Venue } from "../../../../server/src/dto/venue.dto";
export { CustomFieldsType, PaymentType as PMethod, Pricing } from "../../../../server/src/entities/enums";
export { customFields } from "../../../../server/src/utils/package";

export interface VenueName {
  id: string;
  name: string;
  address: string;
  timeZone: string;
  guestSplit: number;
  maxGuests: number;
  closed: boolean;
  isComingSoon: boolean,
  venueImage: string;
  secondaryVenueImages: string[];
  mapImage: string;
  durationInSlots: string;
  sandBoxPayment: boolean;
  preBufferTimeForReservation: number;
  bufferTimeForReservation: number;
  enableAssignDuration: boolean;
  assignDuration: string;
  shiftingForReservationStart: number;
  maxGuestsForAdmin?: number;
  maxNumberOfLanes?: number,
  durationInSlotsForAdmin: string;
  assignDurationForAdmin: string;
  packageTags: string[];
}

export interface TimeSlotDto {
  id: string;
  time: number;
  is21plus: boolean;
  rate: number;
  schedule: string;
}

export interface VenuesState {
  venues: VenueName[];
  availability: Availability;
  isLoadingVenues: boolean;
  isLoadingVenue: boolean;
  isLoadingAvailability: boolean;
  currentPackage?: PackageName;
  venue?: Venue;
  error?: string;
  addonQuantities: AddonQuantity[];
  activeAddons: string[];
  addons: Addon[];
  isLoadingDateOrVenue: boolean;
  isLoadingPackageList: boolean;
}

export interface AddonQuantity {
  addonId: string;
  quantity: number;
}
