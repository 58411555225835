import React, { useState, useEffect } from "react";
import NoAvailiableGuests from "../PackageCard/NoAvailiableGuests";
import PackageSkeletons from "../PackageCard/PackageSkeletons";
import MemberShipCard from "./MemberShipCard";
import { MembershipPlan } from "./entity";
import MembershipContainer from "./memberShipContainer";
import { orderBy } from "lodash";

interface Props {
  plans: MembershipPlan[];
  isAnnual: boolean;
  isLoading: boolean;
  noPlansAvailableMessage: string;
  selectedPlan?: MembershipPlan | null; // Add selectedPlan as a prop
}

const MembershipsList = ({ isLoading, plans, isAnnual, noPlansAvailableMessage, selectedPlan }: Props) => {
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);

  useEffect(() => {
    if (selectedPlan) {
      setSelectedPlanId(selectedPlan.id);
    }
  }, [selectedPlan]);

  const handleSelectPlan = (planId: string) => {
    setSelectedPlanId(planId === selectedPlanId ? null : planId);
  };
  const sortedPlans = orderBy(plans, ['priority'], ['desc']);

  const renderContent = () => {
    if (isLoading) return <PackageSkeletons isMemberships />;
    if (plans?.length === 0)
      return (
        <NoAvailiableGuests
          isHtml
          noSpace
          text={noPlansAvailableMessage}
        />
      );
    return sortedPlans.map((plan) => (
      <MemberShipCard
        key={plan.id}
        plan={plan}
        isAnnual={isAnnual}
        isSelected={plan.id === selectedPlanId}
        onSelectPlan={handleSelectPlan}
      />
    ));
  };

  return <MembershipContainer>{renderContent()}</MembershipContainer>;
};

export default MembershipsList;
