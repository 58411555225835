import { set } from "lodash";

declare global {
  interface Window {
    rex?: {
      analytics?: {
        package?: {
          name?: string;
        };
        total?: {
          price?: string;
        };
      };
    };
  }
}

const updateRexAnalytics = (key: string, value?: string): void => {
  set(window, `window.rex.analytics.${key}`, value);
};

const updateRexAnalyticsTotalPrice = (price?: string): void => {
  updateRexAnalytics("total.price", price);
};

const updateRexAnalyticsPackageName = (name?: string): void => {
  updateRexAnalytics("package.name", name);
};


export const rexAnalytics = {
  updateRexAnalytics,
  updateRexAnalyticsTotalPrice,
  updateRexAnalyticsPackageName,
}
