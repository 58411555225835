import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { formatPriceBasedOnValue } from '../../../../../common/utils/formats';
import { AddonModifierDto } from '../../../../../server/src/dto/addonModifier.dto';
import { CurrencyType } from '../../../store/types';
import { ReservationAddonDto } from '../../../../../server/src/dto/reservationAddon.dto';
import { filter, orderBy } from 'lodash';

interface Props {
    modifier: AddonModifierDto,
    currency: CurrencyType,
    selectedModifiers: AddonModifierDto[],
    setSelectedModifiers: (value: React.SetStateAction<AddonModifierDto[]>) => void;
}

const AddonMultiSelect = ({ modifier, currency, selectedModifiers, setSelectedModifiers }: Props) => {
    let multiSelectIds: string[] = []
    let selectedModifier = selectedModifiers.find((mod) => mod.id === modifier.id)
    if(selectedModifier) {
        multiSelectIds = selectedModifier.options.map(op => op.id)
    }

    const handleChangeMultiSelect = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const selectedIds = [...multiSelectIds];

        if (event.target.checked) {
            selectedIds.push(value);
        } else {
            const index = selectedIds.indexOf(value);
            if (index !== -1) {
                selectedIds.splice(index, 1);
            }
        }
        const selectedOptions = filter(modifier.options, op => selectedIds.includes(op.id))
        if(selectedOptions.length === 0){
            setSelectedModifiers(selectedModifiers.filter(item=>item.id!==modifier.id))
        }else if(!selectedModifier){
            setSelectedModifiers([...selectedModifiers, {...modifier, options: selectedOptions}])
        }else{
            setSelectedModifiers(selectedModifiers.map(item=>item.id===modifier.id?{...modifier, options: selectedOptions}:item))
        }
    };

    const isCheckboxDisabled = (optionId: string) => {
        const { minimum, maximum } = modifier;

        if (maximum !== undefined && multiSelectIds.length >= maximum) {
            return !multiSelectIds.includes(optionId);
        }

        return false;
    };

    const options = orderBy(modifier.options||[], ['priority'], ['desc'])

    return (
        <>
            {options.map((option) => (
                <Box
                    key={option.id}
                    display={'flex'}
                    borderBottom={'1px solid rgba(0, 0, 0, 0.05)'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    pt={'10px'}
                    pb={'10px'}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="default"
                                style={{
                                    color: '#212B36',

                                }}
                                value={option.id}
                                checked={multiSelectIds.includes(option.id)}
                                onChange={(event) => handleChangeMultiSelect(event, option.id)}
                                disabled={isCheckboxDisabled(option.id)}
                            />
                        }
                        label={option.name}
                        labelPlacement="end"
                    />
                    <Typography style={{ fontSize: '16px', fontWeight: 400 }}>
                        +{formatPriceBasedOnValue(option.price, currency)}
                    </Typography>
                </Box>
            ))}</>
    )
}

export default AddonMultiSelect