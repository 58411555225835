import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { formatPrice } from '../../../../../common/utils/formats';
import defaultImage from '../../../../assets/default-image.jpg';
import { useViewport } from '../../../hooks/responsive';
import { CurrencyType } from '../../../store/types';
import ReservationStatusCaption from '../Reservations/ReservationStatusCaption';
import { getSubscriptionTag } from '../Reservations/utils';
import { MembershipSubscription } from './entity';

interface Props {
    subscription: MembershipSubscription;
    currency: CurrencyType;
    onManage: () => void;
}

const AccountMembership = ({ subscription, currency, onManage }: Props) => {
    const { isMobile, isTabletScreen } = useViewport()


    const formattedDate = (date: string) => {
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year.slice(2)}`
    }


    const monthlyPrice = formatPrice(subscription.plan.monthlyRate, currency, false)
    const annualPrice = formatPrice(subscription.plan.annualRate, currency, false)

    const getPriceString = () => {
        if (subscription.plan.annualRate > 0 && subscription.plan.monthlyRate > 0) {
            return `${monthlyPrice}/month, ${annualPrice}/year`
        }
        if (subscription.plan.annualRate > 0) {
            return `${annualPrice}/year`
        }
        if (subscription.plan.monthlyRate > 0) {
            return `${monthlyPrice}/month`
        }
    }

    return (
        <>
            <article className='package-card-v2'>

                <Box className={`package-card-v2-inside ${isMobile ? 'mobile' : ''}`}>

                    <Box className={`account-card__image-container-v2 ${isMobile ? 'mobile' : ''}`}>
                        <img src={subscription?.plan?.image || defaultImage} alt={subscription.plan.name} className='package-card__image-v2' />
                    </Box>

                    <Box className={`account-card__content-v2 ${isMobile ? 'mobile' : ''}`}>
                        <Box>
                            <Box className='account-card__membership'>
                                <Box>
                                    <Box className='package-card__content-top-v2'>
                                        <Typography className='member-card__v2'>
                                            {subscription.plan.name}
                                        </Typography>
                                        {(isMobile) && <ReservationStatusCaption status={getSubscriptionTag(subscription.active)} />}

                                    </Box>
                                    <Typography className='body-small'>{subscription?.priceStripeString || getPriceString()}</Typography>
                                </Box>

                                <Box className=''>
                                    <Typography className={`account-card__mark-v2 ${isMobile ? 'mobile' : ''}`} >Next Payment</Typography>
                                    <Typography className={`package-card__short-description-v2 ${isMobile ? 'mobile' : ''}`} >{subscription.nextPaymentDate}</Typography>
                                </Box>
                            </Box>
                            <Box className={`package-card__actions-v2`}>


                            </Box>
                        </Box>
                        <>
                            <Box className={`account-card__right-container-v2  ${isMobile ? 'mobile' : ''}  ${(isTabletScreen) ? 'tablet' : ''}`}>
                                {!isMobile && <ReservationStatusCaption status={getSubscriptionTag(subscription.active)} />}
                                {subscription?.stripeCustomerId ? <Button onClick={onManage} className={`account-card__btn-v2 ${isMobile ? 'mobile' : ''}`} style={{ marginTop: 'auto' }}>
                                    Manage
                                </Button> : null}
                            </Box>
                        </>
                    </Box>
                </Box>
            </article>

        </>
    )
}
export default AccountMembership