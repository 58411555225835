export interface GiftCardBalanceRequestDto {
    cardNumber: string;
    pin: string;
}

export interface GiftCardBalanceDto {
    cardNumber: string;
    balance: number;
}

export class GiftCardPaymentRequestDto {
    cardNumber: string;
    pin: string;
    amount: number;
}