import { Box, BoxProps, Link, LinkProps } from '@material-ui/core';
import React from 'react';
import { useViewport } from '../../hooks/responsive';

interface Props extends LinkProps {
    icon: React.ReactNode;
    title: string;
    link?: string;
    noTopBorder?: boolean;
    containerProps?: BoxProps;
    withoutLink?: boolean;
}

const LinkWithIcon = ({ icon, link, title, noTopBorder, containerProps, withoutLink, ...props }: Props) => {
    const { isMobile, isTabletScreen } = useViewport()
    return (
        <Box p={isTabletScreen ? '16px 24px' : '16px 32px'} border={isMobile ? 'none' : '1px solid rgba(0, 0, 0, 0.05)'} display={'flex'} alignItems={'center'} style={{ gap: '12px' }} borderTop={noTopBorder ? "none" : "1px solid rgba(0, 0, 0, 0.05)"} {...containerProps}>
            {icon}
            {!withoutLink ? <Link href={link} className='makeReservation-summary-link' {...props}>
                {title}
            </Link> :
                <Box className='makeReservation-summary-link' onClick={props.onClick}>
                    {title}
                </Box>}
        </Box>
    )
}

export default LinkWithIcon