import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../store/types';
import useAccount2Styles from '../Account/useAccount2Styles';

const PaymentDeilimiter = () => {
    const classes = useAccount2Styles();
    const bodyBackgroundColor = useSelector((state: State) => state.uiState.uiConfig?.bodyBackgroundColor)

    return (
        <Box my={'20px'} position={'relative'} display={'flex'} alignItems={'center'} style={{ gap: '8px' }}>
            <Box className={classes.lineThrough} style={{ width: '100%', height: '0.5px' }} />
            <Typography className={`body-small bold`} >OR</Typography>
            <Box className={classes.lineThrough} style={{ width: '100%', height: '0.5px' }} />
        </Box>)
}

export default PaymentDeilimiter