import { PaymentIntentResult, Stripe, StripeCardElement } from "@stripe/stripe-js";
import { getHash, getSearch, push, replace } from "connected-react-router";
import dayjs from "dayjs";
import { ActionTypeEnum, AppThunk } from ".";
import { DATE_FORMAT } from "../../../common/constants/timedate";
import { calculateDeposit, getPackageDurations, getVenueDurations } from "../../../common/utils/formats";
import { ReservationAddonDto } from "../../../server/src/dto/reservationAddon.dto";
import { FeeType, PaymentType, Pricing } from "../../../server/src/entities/enums";
import { selectAuthenticated, selectClient, selectGuest } from "../reducers/auth-reducer";
import {
  selectFortisClientToken,
  selectGiftCardPayment,
  selectGoTabReservationId,
  selectIsUpdateReservation,
  selectOldReservation,
  selectReservation,
  selectReservationAddons,
  selectReservationConfirmation,
} from "../reducers/reservation";
import { selectUIConfig } from "../reducers/ui-reducer";
import { selectCurrentPackage, selectVenue } from "../reducers/venues";
import {
  CreateSquarePartialPaymentDto,
  CurrencyType,
  GiftCardBalanceRequest,
  GiftCardPaymentRequest,
  PMethod,
  PackageName,
  PartyRequest,
  Payment,
  Reservation,
  ReservationConfirmation,
  ReservationStatus,
  SquareOrderDtoRes,
  Venue,
} from "../store/types";
import { parseUrlQuery, toUrlQuery } from "../utils/urlSearchQuery";
import { getWithAuth, getWithGuestAuth, post, postWithAuth, postWithGuestAuth } from "./api";
import { authWithReservationToken } from "./auth-actions";
import { setCurrentPackageAction } from "./venue-actions";
import { getGuestsWithAgeGroups } from "../components/MakePackageReservation/utils";
export const RESERVATION_URL = "/api/reservation";

export const resetReservationAction = (reservation: Reservation) => ({
  type: ActionTypeEnum.ResetReservation,
  payload: reservation,
});
export const updateReservationAction = (patch: Partial<Reservation>): AppThunk => (dispatch) => {
  dispatch({
    type: ActionTypeEnum.UpdateReservation,
    payload: patch,
  });
  dispatch(updateReservationUrlAction());


}
export const updateConfirmationAction = (
  patch: Partial<ReservationConfirmation>
) => ({
  type: ActionTypeEnum.UpdateConfirmation,
  payload: patch,
});

export const startBookingAction = ({ venue, date, currentPackage, isContinue, isNewDesign }: { venue: Venue, date: string, currentPackage?: PackageName, isContinue: boolean, isNewDesign?: boolean, }): AppThunk => async (
  dispatch,
  getState
) => {
  const reservation = selectReservation(getState());
  const reservationAddons = selectReservationAddons(getState());
  const uiConfig = selectUIConfig(getState());
  const isUpdateReservation = selectIsUpdateReservation(getState());
  const day = dayjs(date).day()
  const timeSlotShifting = venue.timeSlotShifting && venue.timeSlotShifting[day] ? +venue.timeSlotShifting[day] : 0
  reservation.timeSlotShifting = timeSlotShifting;
  reservation.timeSlotDuration = venue.timeSlotDuration;
  reservation.currency = uiConfig?.currency || CurrencyType.USD;
  if ((!reservation.guestsWithAgeGroups || reservation.guestsWithAgeGroups.length === 0) && (currentPackage?.method === Pricing.ageGroupsFlatRate || currentPackage?.method === Pricing.ageGroupsPerHour)) {
    reservation.guestsWithAgeGroups = getGuestsWithAgeGroups(currentPackage);
  }

  let durations = getVenueDurations(venue);
  if (currentPackage) {
    durations = currentPackage.enableDurationChoice ? getPackageDurations(currentPackage) : [currentPackage.duration]
  }
  reservation.duration = currentPackage?.duration || venue.enableAssignDuration || currentPackage?.enableAssignDuration || durations.includes(reservation.duration) || uiConfig?.hideDuration
    ? reservation.duration
    : durations[0];
  reservation.packageId = currentPackage?.id;
  reservation.packageName = currentPackage?.name;
  if (!isNewDesign) dispatch(setCurrentPackageAction(currentPackage))
  const client = selectClient(getState());
  const guest = selectGuest(getState());
  const authenticated = selectAuthenticated(getState()) || !!guest;
  try {
    dispatch({ type: ActionTypeEnum.StartBookingRequest });
    let response;
    if (reservation?.id) {
      response = await postWithAuth(
        `${RESERVATION_URL}/price-for-update`,
        { reservation, reservationAddons }
      );
    } else {
      response = await postWithAuth(
        `${RESERVATION_URL}/price`,
        { reservation, reservationAddons }
      );
    }
    const { price, deposit, tax, serviceFee, customFees, customTaxes, addonCustomTaxes, customFeesTaxes, discount, discountName, modificationFee, addonsPrice, couponCodes, discountAmount, total } = response.data;
    dispatch({
      type: ActionTypeEnum.StartBooking,
      payload: {
        ...reservation,
        price,
        addonsPrice,
        deposit,
        tax,
        serviceFee,
        customFees,
        customTaxes,
        customFeesTaxes,
        addonCustomTaxes,
        discount,
        discountName,
        pricing: currentPackage ? currentPackage.method : venue.pricing,
        modificationFee,
        couponCodes,
        discountAmount,
        total,
      },
    });
    if (isUpdateReservation && reservation?.id) {
      const guest = selectGuest(getState());
      const oldReservation = selectOldReservation(getState());
      const isGuest = !!guest;
      let response;
      if (isGuest) {
        response = await getWithGuestAuth(`${RESERVATION_URL}/reservation-information-as-guest/${reservation?.id}`);
      } else {
        response = await getWithAuth(`${RESERVATION_URL}/reservation-information/${reservation?.id}`);
      }
      const reservationInformation = response?.data;
      if (!!oldReservation && (oldReservation?.packageId !== reservation?.packageId || oldReservation?.venueId !== reservation?.venueId)) {
        reservationInformation.customFieldsInformation = undefined;
        reservationInformation.guestDetail = undefined;
      }
      dispatch(
        updateConfirmationAction({
          firstName: reservationInformation?.firstName || "",
          lastName: reservationInformation?.lastName || "",
          phone: reservationInformation?.phone || "",
          email: reservationInformation?.email || "",
          occasion: reservationInformation?.occasion || "",
          guestDetail: reservationInformation?.guestDetail || "",
          customFieldsInformation: reservationInformation?.customFieldsInformation || "",
        })
      );
    } else {
      dispatch(clearReservationModificationAction());
      dispatch(
        updateConfirmationAction({
          firstName: client ? client?.firstName : guest?.firstName,
          lastName: client ? client?.lastName : guest?.lastName,
          phone: client ? client?.phone : guest?.phone,
          email: client ? client?.email : guest?.email,
        })
      );
    }

    if (isNewDesign) dispatch(setCurrentPackageAction(currentPackage));

    if (venue && venue.addons.length > 0 && !isContinue) {
      dispatch(pushUrlPathAction("/reservation-info/addons", {
        venue: venue.name,
      }));
    } else {
      if (authenticated) {
        dispatch(pushUrlPathAction("/reservation-info/reservation-confirm", {
          venue: venue.name,
        }));
      } else {
        dispatch(pushUrlPathAction("/reservation-info/account", { continue: true, venue: venue.name }));
      }
    }
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.StartBookingFailure,
      payload:
        (e as any).response?.data?.message ||
        "Sorry, reservation price cannot be calculated, please use other reservation parameters.",
    });
  }

};

export const confirmReservationAction = (confirm: boolean): AppThunk => async (
  dispatch,
  getState,
) => {
  const venue = selectVenue(getState());
  const client = selectClient(getState());
  const reservation = selectReservation(getState());
  if (client && client.discounts?.length > 0 && (!reservation.couponCodes || reservation.couponCodes?.length < 1)) {
    const discounts = client.discounts
      .filter(discount => discount.packageIds.includes(reservation.packageId))
      .map(discount => discount.couponCode);

    await dispatch(applyCouponCodeAction(discounts, true))
  }
  dispatch({ type: ActionTypeEnum.ConfirmReservation, payload: confirm });
  dispatch(pushUrlPathAction("/reservation-info/payment", {
    venue: venue?.name
  }));
};

export const completePaymentAction = (payment: Payment): AppThunk => async (
  dispatch,
  getState
) => {
  const reservation = selectReservation(getState());
  const confirmation = selectReservationConfirmation(getState());
  const giftCardPayment = selectGiftCardPayment(getState());
  const isUpdateReservation = selectIsUpdateReservation(getState());
  const reservationAddons = selectReservationAddons(getState());
  const fortisClientToken = selectFortisClientToken(getState());
  const currentPackage = selectCurrentPackage(getState());
  const venue = selectVenue(getState());

  try {
    dispatch({ type: ActionTypeEnum.PaymentRequest });
    let updatePayment = payment;
    if (payment.paymentType === PaymentType.square) {
      updatePayment = await getUpdatedSquarePayment(postWithAuth, {
        deposit: reservation.deposit,
        payment,
        reservation,
        reservationAddons,
        currentPackage,
        venue,
      });
    }

    if (fortisClientToken && (window as any).FortisElement) {
      dispatch(clearFortisClientTokenAction());
    }
    let response;
    if (reservation.id && isUpdateReservation) {
      response = await postWithAuth(`${RESERVATION_URL}/update-reservation`, {
        reservation,
        payment: updatePayment,
        confirmation,
        giftCardPayment,
        reservationAddons,
      });
    } else {
      response = await postWithAuth(`${RESERVATION_URL}`, {
        reservation,
        payment: updatePayment,
        confirmation,
        giftCardPayment,
        reservationAddons,
      });
    }
    dispatch({
      type: ActionTypeEnum.PaymentSuccess,
      payload: response.data,
    });
    dispatch(pushUrlPathAction("/reservation-info/confirmed", {
      venue: venue?.name
    }));
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.PaymentFailure,
      payload:
        (e as any).response?.data?.message ||
        "Something went wrong. Reservation could not be completed.",
    });
    // this is workaroud to refresh payment form for Fortis, on payment error
    if (payment.paymentType === PaymentType.fortis) {
      dispatch(getFortisClientTokenAction(reservation.venueId));
    }
  }
};

export const completePaymentAsGuestAction = (payment: Payment): AppThunk => async (
  dispatch,
  getState
) => {
  const reservation = selectReservation(getState());
  const confirmation = selectReservationConfirmation(getState());
  const giftCardPayment = selectGiftCardPayment(getState());
  const isUpdateReservation = selectIsUpdateReservation(getState());
  const reservationAddons = selectReservationAddons(getState());
  const fortisClientToken = selectFortisClientToken(getState());
  const currentPackage = selectCurrentPackage(getState());
  const venue = selectVenue(getState());

  try {
    dispatch({ type: ActionTypeEnum.PaymentRequest });
    let updatePayment = payment;
    if (payment.paymentType === PaymentType.square) {
      updatePayment = await getUpdatedSquarePayment(postWithGuestAuth, {
        deposit: reservation.deposit,
        payment,
        reservation,
        reservationAddons,
        currentPackage,
        venue,
      });
    }

    if (fortisClientToken && (window as any).FortisElement) {
      dispatch(clearFortisClientTokenAction());
    }
    let response;
    if (reservation.id && isUpdateReservation) {
      response = await postWithGuestAuth(`${RESERVATION_URL}/update-reservation-as-guest`, {
        reservation,
        payment: updatePayment,
        confirmation,
        giftCardPayment,
        reservationAddons,
      });
    } else {
      response = await postWithGuestAuth(`${RESERVATION_URL}/reservation-as-guest`, {
        reservation,
        payment: updatePayment,
        confirmation,
        giftCardPayment,
        reservationAddons,
      });
    }
    dispatch({
      type: ActionTypeEnum.PaymentSuccess,
      payload: response.data,
    });
    dispatch(pushUrlPathAction("/reservation-info/confirmed", {
      venue: venue?.name
    }));
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.PaymentFailure,
      payload:
        (e as any).response?.data?.message ||
        "Something went wrong. Reservation could not be completed.",
    });
    // this is workaroud to refresh payment form for Fortis, on payment error
    if (payment.paymentType === PaymentType.fortis) {
      dispatch(getFortisClientTokenAction(reservation.venueId));
    }
  }
};

export const completePaymentSezzleAction = (event: any, amount: number): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const reservation = selectReservation(getState());
    const confirmation = selectReservationConfirmation(getState());
    const client = selectClient(getState());
    const giftCardPayment = selectGiftCardPayment(getState());
    const reservationAddons = selectReservationAddons(getState());
    dispatch({ type: ActionTypeEnum.PaymentRequest });
    const response = client ? await postWithAuth(`${RESERVATION_URL}`, {
      reservation,
      payment: {
        paymentReference: event.order_uuid,
        details: {
          orderId: event.order_uuid,
          amount,
        },
        postalCode: '',
        paymentType: PaymentType.sezzle,
      },
      confirmation,
      giftCardPayment,
      reservationAddons,
    }) : await postWithGuestAuth(`${RESERVATION_URL}/reservation-as-guest`, {
      reservation,
      payment: {

      },
      confirmation,
      giftCardPayment,
      reservationAddons,
    });;
    dispatch({
      type: ActionTypeEnum.PaymentSuccess,
      payload: response.data,
    });
    dispatch(pushUrlPathAction("/reservation-info/confirmed", {
      venue: reservation.venueName
    }));
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.PaymentFailure,
      payload:
        (e as any).response?.data?.message ||
        "Something went wrong. Reservation could not be completed.",
    });
  }
};

export const getReservationsAction = (): AppThunk => async (dispatch, getState) => {
  try {
    const guest = selectGuest(getState());
    const isGuest = !!guest;
    let response;
    dispatch({ type: ActionTypeEnum.GetReservationsRequest });
    if (isGuest) {
      response = await getWithGuestAuth(`${RESERVATION_URL}/account-reservation-as-guest`);
    } else {
      response = await getWithAuth(`${RESERVATION_URL}`);
    }
    dispatch({
      type: ActionTypeEnum.GetReservationsSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get reservation error", e);
    dispatch({
      type: ActionTypeEnum.GetReservationsFailure,
      payload: "GetReservations failure",
    });
  }
};

export const showReservationCancellationAction = (
  id: string
): AppThunk => async (dispatch, getState) => {
  try {
    const guest = selectGuest(getState());
    const isGuest = !!guest;
    let response;
    dispatch({ type: ActionTypeEnum.GetReservationCancellationRequest });
    if (isGuest) {
      response = await getWithGuestAuth(`${RESERVATION_URL}/cancellation-info-as-guest/${id}`);
    } else {
      response = await getWithAuth(`${RESERVATION_URL}/cancellation-info/${id}`);
    }
    const cancellationInfo = response.data.cancellation;
    const cancellationFee = cancellationInfo.cancellationFee || 0;
    const cancellationFeeType = cancellationInfo.cancellationFeeType || FeeType.PERCENT;
    dispatch({
      type: ActionTypeEnum.GetReservationCancellationSuccess,
      payload: { cancellationFee, cancellationFeeType },
    });
  } catch (e) {
    console.log("get reservation error", e);
    dispatch({
      type: ActionTypeEnum.GetReservationCancellationFailure,
      payload: "GetReservations failure",
    });
  }
};

export const showReservationModificationAction = (
  id: string
): AppThunk => async (dispatch, getState) => {
  try {
    const guest = selectGuest(getState());
    const isGuest = !!guest;
    let response;
    dispatch({ type: ActionTypeEnum.GetReservationModificationRequest });
    if (isGuest) {
      response = await getWithGuestAuth(`${RESERVATION_URL}/modification-info-as-guest/${id}`);
    } else {
      response = await getWithAuth(`${RESERVATION_URL}/modification-info/${id}`);
    }
    const modificationInfo = response.data.modification;
    const feeForModification = modificationInfo.modificationFee || 0;
    const modificationFeeType = modificationInfo.modificationFeeType || FeeType.PERCENT;
    dispatch({
      type: ActionTypeEnum.GetReservationModificationSuccess,
      payload: { feeForModification, modificationFeeType },
    });
  } catch (e) {
    console.log("get reservation error", e);
    dispatch({
      type: ActionTypeEnum.GetReservationModificationFailure,
      payload: "GetReservations failure",
    });
  }
};

export const hideCancellationAction = () => ({
  type: ActionTypeEnum.HideCancellation,
});

export const submitPartyAction = (party: PartyRequest): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.SubmitPartyRequest });
    await postWithAuth(`${RESERVATION_URL}/party`, party);
    dispatch({
      type: ActionTypeEnum.SubmitPartySuccess,
      payload: party,
    });
    dispatch(pushUrlPathAction("/thank-you"));
  } catch (e) {
    console.log("party request error", e);
    dispatch({
      type: ActionTypeEnum.SubmitPartyFailure,
      payload:
        "Sorry, I cannot submit party request now, please try again later.",
    });
  }
};

export const setIsPartySubmittedAction = (isSubmitted: boolean) => ({
  type: ActionTypeEnum.SetIsPartySubmitted,
  payload: isSubmitted,
});

export const setReservationErrorAction = (error?: string) => ({
  type: ActionTypeEnum.SetReservationError,
  payload: error,
});

export const cancelReservationAction = (
  reservationId: string,
  reason: string
): AppThunk => async (dispatch, getState) => {
  try {
    const guest = selectGuest(getState());
    const isGuest = !!guest;
    let response;
    dispatch({ type: ActionTypeEnum.CancelReservation });
    if (isGuest) {
      response = await postWithGuestAuth(`${RESERVATION_URL}/cancellation-as-guest`, {
        id: reservationId,
        reason
      });
    } else {
      response = await postWithAuth(`${RESERVATION_URL}/cancellation`, {
        id: reservationId,
        reason
      });
    }
    dispatch({
      type: ActionTypeEnum.CancelReservationSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("cancel reservation error", e);
    dispatch({
      type: ActionTypeEnum.CancelReservationFailure,
      payload: "CancelReservation failure",
    });
  }
};

export const loadReservationAction = (
  reservationId: string,
  token: string
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.LoadReservation });
    const clearToken = (token || '').trim().replace('/', '')
    //auth with token
    await authWithReservationToken({
      id: reservationId,
      resToken: clearToken,
      dispatch
    })
    const response = await postWithAuth(`${RESERVATION_URL}/load-for-payment`, {
      id: reservationId,
      token: clearToken,
    });
    dispatch({
      type: ActionTypeEnum.LoadReservationSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("load reservation error", e);
    dispatch({
      type: ActionTypeEnum.LoadReservationFailure,
      payload: 'Sorry, link does not work.',
    });
  }
};

export const rePayAction = (payment: Payment): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const reservation = selectReservation(getState());
    const deposit = calculateDeposit({
      reservation,
      giftCardAmount: 0,
      isUpdateReservation: false,
      isUpdateWithVenueChange: false,
      oldReservation: undefined,
    });
    dispatch({ type: ActionTypeEnum.RePayRequest });
    const response = await post(`${RESERVATION_URL}/repay`, {
      reservation: { ...reservation, deposit },
      payment,
    });
    dispatch({
      type: ActionTypeEnum.RePayRequestSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.RePayRequestFailure,
      payload:
        (e as any).response?.data?.message ||
        "Something went wrong. Reservation could not be completed.",
    });
  }
};

export const rePayStripeAction = (stripe: Stripe, cardMethod?: StripeCardElement, otherMethods?: boolean, paymentMethod?: string): AppThunk => async (
  dispatch,
  getState
) => {
  const reservation = selectReservation(getState());
  const venue = selectVenue(getState());
  const confirmation: ReservationConfirmation = {
    firstName: reservation.firstName,
    lastName: reservation.lastName,
    email: reservation.email,
  }
  let response;
  try {
    dispatch({ type: ActionTypeEnum.RePayRequest });
    if (!cardMethod && !otherMethods) {
      return dispatch({
        type: ActionTypeEnum.RePayRequestFailure,
        payload: "Something went wrong. Reservation could not be completed.",
      });
    }
    response = await postWithAuth(`${RESERVATION_URL}/repay-pending-stripe`, { ...reservation, otherMethods, paymentMethod });
    const { reservationId, stripeClientSecret } = response.data;

    if (stripeClientSecret && otherMethods && !!paymentMethod) {
      const payload = await stripe.redirectToCheckout({
        sessionId: stripeClientSecret,
      });
      post(`${RESERVATION_URL}/stripe-payment-logger`, {
        payload,
        reservationId,
        confirmation,
      });
      dispatch({
        type: ActionTypeEnum.RePayRequestSuccess,
        payload: response.data,
      });
      return;
    }
    else if (stripeClientSecret && cardMethod) {
      const fullName = `${reservation.firstName} ${reservation.lastName}`;
      const payload = await stripe.confirmCardPayment(stripeClientSecret, {
        payment_method: {
          card: cardMethod,
          billing_details: {
            name: fullName,
          },
        }
      });
      post(`${RESERVATION_URL}/stripe-payment-logger`, {
        payload,
        reservationId,
        confirmation,
      });
      if (!!venue?.enableCaptureStripePayment && payload?.paymentIntent?.status === 'succeeded') {
        const payData = {
          cardLast4: '',
          cardType: '',
          paymentMethodId: payload.paymentIntent.payment_method || '',
        }
        const payment = {
          paymentReference: payload.paymentIntent.id,
          details: payData,
          postalCode: '',
          paymentType: PMethod.stripe
        }

        response = await postWithAuth(`${RESERVATION_URL}/confirm-repay-stripe-payment`, {
          reservationId,
          payment,
        });
        dispatch({
          type: ActionTypeEnum.RePayRequestSuccess,
          payload: response.data,
        });
        return;
      }
      if (!payload.paymentIntent?.id ||
        (!!venue?.enableCaptureStripePayment && payload.paymentIntent.status !== 'succeeded') ||
        (!venue?.enableCaptureStripePayment && payload.paymentIntent.status !== 'requires_capture') ||
        !reservationId ||
        !!payload.error
      ) {
        dispatch({
          type: ActionTypeEnum.RePayRequestFailure,
          payload: "Something went wrong. Reservation could not be completed.",
        });
      } else {
        const payData = {
          cardLast4: '',
          cardType: '',
          paymentMethodId: payload.paymentIntent.payment_method || '',
        }
        const payment = {
          paymentReference: payload.paymentIntent.id,
          details: payData,
          postalCode: '',
          paymentType: PMethod.stripe
        }
        response = await postWithAuth(`${RESERVATION_URL}/confirm-repay-stripe-payment`, {
          reservationId,
          payment,
        });
        dispatch({
          type: ActionTypeEnum.RePayRequestSuccess,
          payload: response.data,
        });
      }
    } else {
      dispatch({
        type: ActionTypeEnum.RePayRequestFailure,
        payload: "Something went wrong. Reservation could not be completed.",
      });
    }
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.RePayRequestFailure,
      payload:
        (e as any).response?.data?.message ||
        "Something went wrong. Reservation could not be completed.",
    });
  }
};

export const backFromAccountAction = (): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const uiConfig = selectUIConfig(getState());
    const currentPackage = selectCurrentPackage(getState());
    const isPackageReservationMode = !!currentPackage || uiConfig?.isPackageReservationMode;
    dispatch(pushUrlPathAction(isPackageReservationMode ? "/package" : "/reservation"));
  } catch (e) {
    console.log("back from account error", e);
  }
};
export const updateReservationUrlAction = (): AppThunk => async (
  dispatch,
  getState
) => {
  const searchParams = getSearch(getState());
  const reservation = selectReservation(getState());
  const venue = selectVenue(getState());
  const currentPackage = selectCurrentPackage(getState());
  const params: { [key: string]: any } = {
    venue: venue?.name,
    date: reservation.date,
    guests: reservation.guests,
  };
  if (currentPackage?.name) {
    params.currentPackage = currentPackage.name;
  }
  const { venue: venueName, date, guests, currentPackage: currentPackageName, sp: targetPackage } = parseUrlQuery(searchParams);
  // if (
  //   // reservation.id ||
  //   // !venue?.name ||
  //   isEqual(params, { venue: venueName, date, guests, currentPackage: currentPackageName })
  // ) {
  //   return;
  // }

  if (targetPackage) {
    delete params.currentPackage;
    params.sp = targetPackage
  }

  dispatch(replaceAppUrlAction(params));

};

const makePendingStripeReservation = async ({
  reservation,
  confirmation,
  giftCardPayment,
  reservationAddons,
  isUpdateReservation,
  isGuest,
  otherMethods,
  paymentMethod,
  isSaveCard,

}: {
  reservation: Reservation,
  confirmation: ReservationConfirmation,
  giftCardPayment: GiftCardPaymentRequest[],
  reservationAddons: ReservationAddonDto[],
  isUpdateReservation: boolean,
  isGuest: boolean,
  otherMethods?: boolean,
  paymentMethod?: string,
  isSaveCard?: boolean,
}) => {
  let response;
  if (isGuest) {
    if (reservation.id && isUpdateReservation) {
      response = await postWithGuestAuth(`${RESERVATION_URL}/make-pending-stripe-for-update-as-guest`, {
        reservation,
        confirmation,
        giftCardPayment,
        reservationAddons,
        isSaveCard,

      });
    } else {
      response = await postWithGuestAuth(`${RESERVATION_URL}/make-pending-stripe-as-guest`, {
        reservation,
        confirmation,
        giftCardPayment,
        reservationAddons,
        otherMethods,
        paymentMethod,
        isSaveCard
      })
    };
  } else {
    if (reservation.id && isUpdateReservation) {
      response = await postWithAuth(`${RESERVATION_URL}/make-pending-stripe-for-update`, {
        reservation,
        confirmation,
        giftCardPayment,
        reservationAddons,
        otherMethods,
        paymentMethod,
        isSaveCard,

      });
    } else {
      response = await postWithAuth(`${RESERVATION_URL}/make-pending-stripe`, {
        reservation,
        confirmation,
        giftCardPayment,
        reservationAddons,
        otherMethods,
        paymentMethod,
        isSaveCard,

      });
    }
  }
  return response.data;
}
const confirmStripeReservation = async ({
  reservation,
  reservationId,
  payment,
  isUpdateReservation,
  isGuest
}: {
  reservation: Reservation,
  reservationId: string,
  payment: any,
  isUpdateReservation: boolean,
  isGuest: boolean
}) => {
  let response;
  if (isGuest) {
    if (reservation.id && isUpdateReservation) {
      response = await postWithGuestAuth(`${RESERVATION_URL}/confirm-stripe-payment-for-update-as-guest`, {
        oldReservationId: reservation.id,
        newReservationId: reservationId,
        payment,
      });
    } else {
      response = await postWithGuestAuth(`${RESERVATION_URL}/confirm-stripe-payment-as-guest`, {
        reservationId,
        payment,
      })
    };
  } else {
    if (reservation.id && isUpdateReservation) {
      response = await postWithAuth(`${RESERVATION_URL}/confirm-stripe-payment-for-update`, {
        oldReservationId: reservation.id,
        newReservationId: reservationId,
        payment,
      });
    } else {
      response = await postWithAuth(`${RESERVATION_URL}/confirm-stripe-payment`, {
        reservationId,
        payment,
      });
    }
  }
  return response.data;
}
export const makeStripeReservationAction = (stripe: Stripe, cardMethod?: StripeCardElement, otherMethods?: boolean, paymentMethod?: string, isSaveCard?: boolean): AppThunk => async (
  dispatch,
  getState
) => {
  const confirmation = selectReservationConfirmation(getState());
  const giftCardPayment = selectGiftCardPayment(getState());
  const reservationAddons = selectReservationAddons(getState());
  const guest = selectGuest(getState());
  const venue = selectVenue(getState());
  const reservation = selectReservation(getState())
  const isUpdateReservation = selectIsUpdateReservation(getState());
  const isGuest = !!guest;
  let pendingStripeResId;

  // temporary fix for venue inside reservation 
  reservation.venueId = venue?.id || reservation.venueId || '';

  if (!confirmation.email && reservation.email) {
    Object.assign(confirmation, {
      email: reservation.email,
      firstName: reservation.firstName,
      lastName: reservation.lastName,
    });
  }

  try {
    dispatch({ type: ActionTypeEnum.PaymentRequest });
    const { reservationId, stripeClientSecret } = await makePendingStripeReservation({
      reservation,
      confirmation,
      giftCardPayment,
      reservationAddons,
      isGuest,
      isUpdateReservation,
      otherMethods,
      paymentMethod,
      isSaveCard,
    });
    pendingStripeResId = reservationId


    if (stripeClientSecret) {
      const fullName = `${confirmation.firstName} ${confirmation.lastName}`;
      let payload: PaymentIntentResult | undefined;
      if (otherMethods && !!paymentMethod) {
        payload = await stripe.redirectToCheckout({
          sessionId: stripeClientSecret,
        });
      }
      else if (cardMethod) {
        payload = await stripe.confirmCardPayment(stripeClientSecret, {
          payment_method: {
            card: cardMethod,
            billing_details: {
              name: fullName,
              email: confirmation.email,
            },
          }
        });
      }
      else if (paymentMethod && !cardMethod) {
        payload = await stripe.confirmCardPayment(stripeClientSecret, {
          payment_method: paymentMethod,
        });
      }

      post(`${RESERVATION_URL}/stripe-payment-logger`, {
        payload,
        reservationId,
        confirmation,
      });
      if (!!venue?.enableCaptureStripePayment && payload?.paymentIntent?.status === 'succeeded') {
        const payData = {
          cardLast4: '',
          cardType: '',
          paymentMethodId: payload.paymentIntent.payment_method || '',
        }
        const payment = {
          paymentReference: payload.paymentIntent.id,
          details: payData,
          postalCode: '',
          paymentType: PMethod.stripe
        }
        const con = await confirmStripeReservation({
          reservation,
          reservationId,
          payment,
          isUpdateReservation,
          isGuest
        })

        dispatch({
          type: ActionTypeEnum.PaymentSuccess,
          payload: con,
        });
        dispatch(pushUrlPathAction("/reservation-info/confirmed", {
          venue: venue?.name
        }));
        return;
      }

      if (!payload?.paymentIntent?.id ||
        (!!venue?.enableCaptureStripePayment && payload.paymentIntent.status !== 'succeeded') ||
        (!venue?.enableCaptureStripePayment && payload.paymentIntent.status !== 'requires_capture') ||
        !reservationId ||
        !!payload.error
      ) {
        dispatch({
          type: ActionTypeEnum.PaymentFailure,
          payload: "Something went wrong. Reservation could not be completed.",
        });
        dispatch(disableStripeReservationAction(reservationId, isGuest))
      } else {
        const payData = {
          cardLast4: '',
          cardType: '',
          paymentMethodId: payload.paymentIntent.payment_method || '',
        }
        const payment = {
          paymentReference: payload.paymentIntent.id,
          details: payData,
          postalCode: '',
          paymentType: PMethod.stripe
        }
        const con = await confirmStripeReservation({
          reservation,
          reservationId,
          payment,
          isUpdateReservation,
          isGuest
        })
        dispatch({
          type: ActionTypeEnum.PaymentSuccess,
          payload: con,
        });
        dispatch(pushUrlPathAction("/reservation-info/confirmed", {
          venue: venue?.name
        }));
      }
    } else {
      dispatch({
        type: ActionTypeEnum.PaymentFailure,
        payload: "Something went wrong. Reservation could not be completed.",
      });
      dispatch(disableStripeReservationAction(reservationId, isGuest))
    }
  } catch (e) {
    dispatch(disableStripeReservationAction(pendingStripeResId, isGuest))
  }
};

export const getClientSavedStripeCardsAction = (): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const venue = selectVenue(getState());

    const body = {
      venueId: venue?.id,
    }

    dispatch({ type: ActionTypeEnum.GetStripeListOfSavedCardsRequest });
    const response = await postWithAuth(`${RESERVATION_URL}/saved-client-stripe-cards`, body);
    dispatch({
      type: ActionTypeEnum.GetStripeListOfSavedCardsSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetStripeListOfSavedCardsFailure,
      payload: "Getting stripe payment methods failure",
    });
  }
}

export const paymentMethodStripeAction = (stripe: Stripe, ev: any): AppThunk => async (
  dispatch,
  getState
) => {
  const reservation = selectReservation(getState());
  const confirmation = selectReservationConfirmation(getState());
  const giftCardPayment = selectGiftCardPayment(getState());
  const reservationAddons = selectReservationAddons(getState());
  const guest = selectGuest(getState());
  const isUpdateReservation = selectIsUpdateReservation(getState());
  const isGuest = !!guest;
  let pendingStripeResId
  try {
    dispatch({ type: ActionTypeEnum.PaymentRequest });
    if (!ev || !ev.paymentMethod) {
      return dispatch({
        type: ActionTypeEnum.PaymentFailure,
        payload: "Something went wrong. Reservation could not be completed.",
      });
    }
    const { reservationId, stripeClientSecret } = await makePendingStripeReservation({
      reservation,
      confirmation,
      giftCardPayment,
      reservationAddons,
      isGuest,
      isUpdateReservation,
    });
    pendingStripeResId = reservationId
    if (!stripeClientSecret) {
      return dispatch({
        type: ActionTypeEnum.PaymentFailure,
        payload: "Something went wrong. Reservation could not be completed.",
      });
    }

    const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(stripeClientSecret,
      { payment_method: ev.paymentMethod.id },
      { handleActions: false });
    post(`${RESERVATION_URL}/stripe-payment-logger`, {
      payload: { paymentIntent, confirmError },
      reservationId,
      confirmation,
    });

    if (confirmError || !paymentIntent) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      dispatch(disableStripeReservationAction(reservationId, isGuest))
      dispatch({
        type: ActionTypeEnum.PaymentFailure,
        payload: "Something went wrong. Reservation could not be completed.",
      });
      return ev.complete('fail');
    }
    // Report to the browser that the confirmation was successful, prompting
    // it to close the browser payment method collection interface.
    ev.complete('success');
    // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
    // handle the flow. If using an API version older than "2019-02-11"
    // instead check for: `paymentIntent.status === "requires_source_action"`.
    if (paymentIntent.status === "requires_action") {
      // Let Stripe.js handle the rest of the payment flow.
      const { error } = await stripe.confirmCardPayment(stripeClientSecret);
      if (error) {
        // The payment failed -- ask your customer for a new payment method.
        dispatch(disableStripeReservationAction(reservationId, isGuest))
        return dispatch({
          type: ActionTypeEnum.RePayRequestFailure,
          payload: "Something went wrong. Reservation could not be completed.",
        });
      }
    }
    // The payment has succeeded -- show a success message to your customer.
    const payData = {
      cardLast4: '',
      cardType: '',
      paymentMethodId: paymentIntent.payment_method || '',
    }
    const payment = {
      paymentReference: paymentIntent.id,
      details: payData,
      postalCode: '',
      paymentType: PMethod.stripe
    }
    const con = await confirmStripeReservation({
      reservation,
      reservationId,
      payment,
      isUpdateReservation,
      isGuest
    })

    dispatch({
      type: ActionTypeEnum.PaymentSuccess,
      payload: con,
    });
    dispatch(pushUrlPathAction("/reservation-info/confirmed", {
      venue: reservation.venueName
    }));
    return;
  } catch (e) {
    dispatch(disableStripeReservationAction(pendingStripeResId, isGuest))
    dispatch({
      type: ActionTypeEnum.RePayRequestFailure,
      payload: "Something went wrong. Reservation could not be completed.",
    });
  }
};

export const disableStripeReservationAction = (reservationId?: string, isGuest?: boolean): AppThunk => async (
  dispatch,
) => {
  dispatch({
    type: ActionTypeEnum.PaymentFailure,
    payload: "Something went wrong. Reservation could not be completed.",
  });
  //if reservation was created
  if (reservationId) {
    if (isGuest) {
      await postWithGuestAuth(`${RESERVATION_URL}/disable-stripe-reservation-as-guest`, { reservationId });
    } else {
      await postWithAuth(`${RESERVATION_URL}/disable-stripe-reservation`, { reservationId });
    }
  }
};

export const getGiftCardBalanceAction = (cardParams: GiftCardBalanceRequest): AppThunk => async (
  dispatch,
  getState,
) => {
  try {
    const reservation = selectReservation(getState());
    const guest = selectGuest(getState());
    const isGuest = !!guest;
    let response;
    dispatch({ type: ActionTypeEnum.GetGiftCardBalance });
    if (isGuest) {
      response = await getWithGuestAuth(`${RESERVATION_URL}/gift-card-balance-as-guest/${reservation.venueId}`, cardParams);
    } else {
      response = await getWithAuth(`${RESERVATION_URL}/gift-card-balance/${reservation.venueId}`, cardParams);
    }
    dispatch({
      type: ActionTypeEnum.GetGiftCardBalanceSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetGiftCardBalanceFailure,
      payload: "Getting gift card balance failure",
    });
  }
};

export const addGiftCardAction = (newGiftCard: GiftCardPaymentRequest): AppThunk => async (
  dispatch,
  getState,
) => {
  const allGiftCards = selectGiftCardPayment(getState());
  let giftCardAmount = 0;
  allGiftCards.forEach(card => {
    giftCardAmount = giftCardAmount + card.amount
  });
  giftCardAmount = giftCardAmount + newGiftCard.amount;
  dispatch({
    type: ActionTypeEnum.addGiftCardPayment,
    payload: { newGiftCard, giftCardAmount },
  });
};

export const createEmptyPaymentAction = (paymentType: PMethod): AppThunk => async (
  dispatch,
  getState,
) => {
  try {
    const reservation = selectReservation(getState());
    const confirmation = selectReservationConfirmation(getState());
    const giftCardPayment = selectGiftCardPayment(getState());
    const reservationAddons = selectReservationAddons(getState());
    const guest = selectGuest(getState());
    const isUpdateReservation = selectIsUpdateReservation(getState());
    const isGuest = !!guest;
    const payment = {
      paymentReference: "",
      details: {},
      postalCode: '',
      paymentType,
    };
    let response;
    dispatch({ type: ActionTypeEnum.PaymentRequest });
    if (isGuest) {
      if (reservation.id && isUpdateReservation) {
        response = await postWithGuestAuth(`${RESERVATION_URL}/update-reservation-as-guest`, {
          reservation,
          payment,
          confirmation,
          giftCardPayment,
          reservationAddons,
        });
      } else {
        response = await postWithGuestAuth(`${RESERVATION_URL}/create-without-payment-as-guest`, {
          reservation,
          paymentType,
          confirmation,
          giftCardPayment,
          reservationAddons,
        })
      };
    } else {
      if (reservation.id && isUpdateReservation) {
        response = await postWithAuth(`${RESERVATION_URL}/update-reservation`, {
          reservation,
          payment,
          confirmation,
          giftCardPayment,
          reservationAddons,
        });
      } else {
        response = await postWithAuth(`${RESERVATION_URL}/create-without-payment`, {
          reservation,
          paymentType,
          confirmation,
          giftCardPayment,
          reservationAddons,
        });
      }
    }
    dispatch({
      type: ActionTypeEnum.PaymentSuccess,
      payload: response.data,
    });
    dispatch(pushUrlPathAction("/reservation-info/confirmed", {
      venue: reservation.venueName
    }));
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.PaymentFailure,
      payload:
        (e as any).response?.data?.message ||
        "Sorry the reservation is not created, please try another params.",
    });
  }
}

export const redirectToAddonsAction = (): AppThunk => async (
  dispatch
) => {
  dispatch(pushUrlPathAction("/addons"));
};

export const replaceAppUrlAction = (params: { [key: string]: any }, noHash?: boolean): AppThunk => async (dispatch, getState) => {
  const hash = noHash ? '' : getHash(getState());
  dispatch(replace({ search: toUrlQuery(params), hash }))
}

export const pushUrlParamsAction = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const uiConfig = selectUIConfig(getState());
  const reservation = selectReservation(getState());
  const venue = selectVenue(getState());
  const currentPackage = selectCurrentPackage(getState());
  let dateShifting = venue?.shiftingForReservationStart || 0;
  if (currentPackage?.shiftingForReservationStart) {
    dateShifting = currentPackage?.shiftingForReservationStart
  }
  const date = dayjs(reservation.date).add(dateShifting, 'day').format(DATE_FORMAT);
  dispatch(updateReservationAction({ date }))
  const params: { [key: string]: any } = {
    venue: venue?.name,
    date,
    guests: reservation.guests,
  };
  if (uiConfig?.isPackageReservationMode) {
    dispatch(pushUrlPathAction("/package", params));
  } else {
    dispatch(pushUrlPathAction("/reservation", params));
  }
}

export const pushUrlPathAction = (pathname: string, params?: { [key: string]: any }): AppThunk => async (
  dispatch,
  getState,
) => {
  const hash = getHash(getState());
  const search = params ? toUrlQuery(params) : '';
  dispatch(
    push({
      pathname,
      search,
      hash,
    })
  );
}

export const editReservationAction = (reservation: Reservation): AppThunk => async (
  dispatch
) => {
  dispatch({
    type: ActionTypeEnum.SetUpdatingReservation,
    payload: reservation,
  });
  dispatch(
    updateReservationAction(reservation)
  );
  const params: { [key: string]: any } = {
    venue: reservation.venueName,
    date: reservation.date,
    guests: reservation.guests,
    currentPackage: reservation.packageName
  };
  if (!!params.currentPackage) {
    dispatch(pushUrlPathAction("/package", params));
  } else {
    dispatch(pushUrlPathAction("/reservation", params));
  }
}

export const hideModificationAction = (): AppThunk => async (
  dispatch
) => {
  dispatch({ type: ActionTypeEnum.HideModification });
}

export const clearReservationModificationAction = (): AppThunk => async (
  dispatch
) => {
  dispatch({ type: ActionTypeEnum.ClearReservationModification });
}

export const clearReservationAddons = (): AppThunk => async (
  dispatch
) => {
  dispatch({
    type: ActionTypeEnum.SetReservationAddons,
    payload: [],
  });
}

export const getFortisClientTokenAction = (venueId: string): AppThunk => async (
  dispatch,
  getState,
) => {
  const guest = selectGuest(getState());
  const isGuest = !!guest;
  let clientTokenResponse;
  if (isGuest) {
    clientTokenResponse = await postWithGuestAuth(`${RESERVATION_URL}/get-fortis-client-token-as-guest`, { venueId });
  } else {
    clientTokenResponse = await postWithAuth(`${RESERVATION_URL}/get-fortis-client-token`, { venueId });
  }
  dispatch({
    type: ActionTypeEnum.SetFortisClientToken,
    payload: clientTokenResponse.data,
  });
  (window as any).FortisElement = new Commerce.elements(clientTokenResponse.data);
};

export const clearFortisClientTokenAction = (): AppThunk => async (
  dispatch,
) => {
  dispatch({
    type: ActionTypeEnum.SetFortisClientToken,
    payload: undefined,
  });
  (window as any).FortisElement.off('ready');
  (window as any).FortisElement.off('error');
  (window as any).FortisElement.off('done');
  (window as any).FortisElement.off('validationError');
  (window as any).FortisElement.remove();
};

export const createGoTabId = async ({
  reservation,
  confirmation,
  reservationAddons,
  isClient,
  isUpdateReservation
}: {
  reservation: Reservation,
  confirmation: ReservationConfirmation,
  reservationAddons: ReservationAddonDto[],
  isClient: boolean,
  isUpdateReservation?: boolean
}): Promise<Reservation> => {
  let response;
  const goTabUrl = isUpdateReservation ? `${RESERVATION_URL}/update-for-go-tab` : `${RESERVATION_URL}/create-for-go-tab`
  if (!isClient) {
    response = await postWithGuestAuth(goTabUrl, {
      reservation,
      confirmation,
      reservationAddons,
      isClient,
    });
  } else {
    response = await postWithAuth(goTabUrl, {
      reservation,
      confirmation,
      reservationAddons,
      isClient: true,
    });
  }
  return response.data;
};

export const setGoTabIdAction = (goTabReservationId: string) => ({ type: ActionTypeEnum.GoTabInformationRequestSuccess, payload: goTabReservationId })

const getGoTabInfo = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const reservation = selectReservation(getState());
  const confirmation = selectReservationConfirmation(getState());
  const reservationAddons = selectReservationAddons(getState());
  const guest = selectGuest(getState());
  try {
    dispatch({ type: ActionTypeEnum.GoTabInformationRequest });
    const goTabReservation = await createGoTabId({
      reservation,
      confirmation,
      reservationAddons,
      isClient: !guest,
    });
    dispatch(setGoTabIdAction(goTabReservation?.goTabId || ''));
    dispatch(pushUrlPathAction("/reservation-info/payment", {
      venue: reservation.venueName
    }));
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GoTabInformationRequestFailure,
      payload:
        (e as any).response?.data?.message ||
        "Something went wrong. Reservation could not be created.",
    });
  }
};

export const getGoTabReservationStatusAction = (goTabReservationId?: string): AppThunk => async (
  dispatch,
  getState,
) => {
  const guest = selectGuest(getState());
  const goTabReservationId = selectGoTabReservationId(getState())
  const venue = selectVenue(getState());
  const isGuest = !!guest;
  try {
    dispatch({ type: ActionTypeEnum.GoTabPaymentStatus });
    let response;
    if (isGuest) {
      response = await getWithGuestAuth(`${RESERVATION_URL}/go-tab-reservation-status/${goTabReservationId}`);
    } else {
      response = await getWithAuth(`${RESERVATION_URL}/go-tab-reservation-status/${goTabReservationId}`);
    }
    if (!!response.data) {
      dispatch({
        type: ActionTypeEnum.GoTabPaymentStatusSuccess,
        payload: response.data,
      });
      dispatch(pushUrlPathAction("/reservation-info/confirmed", {
        venue: venue?.name
      }));
    }
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GoTabPaymentStatusFailure,
      payload:
        (e as any).response?.data?.message ||
        "Reservation not be completed.",
    });
  }
};

export const applyCouponCodeAction = (codes: string[], noErrors?: boolean): AppThunk => async (
  dispatch,
  getState,
) => {
  const reservation = selectReservation(getState());
  const reservationAddons = selectReservationAddons(getState());
  dispatch({ type: ActionTypeEnum.ApplyCoupons });
  try {
    const response = await postWithAuth(
      `${RESERVATION_URL}/apply-coupons`,
      { reservation: { ...reservation, couponCodes: codes }, reservationAddons, noErrors: !!noErrors }
    );

    const { price, deposit, tax, serviceFee, customFees, customTaxes, addonCustomTaxes, customFeesTaxes, discount, discountName, modificationFee, addonsPrice, couponCodes, discountAmount, total, coupons } = response.data;
    dispatch({
      type: ActionTypeEnum.ApplyCouponsSuccess,
      payload: {
        ...reservation,
        price,
        addonsPrice,
        deposit,
        tax,
        serviceFee,
        customFees,
        customTaxes,
        customFeesTaxes,
        addonCustomTaxes,
        discount,
        discountName,
        modificationFee,
        couponCodes,
        discountAmount,
        total,
        coupons,
      },
    });
  } catch (e) {
    //alert
    dispatch({
      type: ActionTypeEnum.ApplyCouponsFailure,
      payload:
        (e as any).response?.data?.message ||
        "Cannot apply coupons.",
    });
  }
};

type SquareOrderReqData = {
  reservation: Reservation;
  reservationAddons: ReservationAddonDto[];
  currentPackage?: PackageName;
  venue?: Venue;
}

const checkSquareOrderCalculation = async (post: typeof postWithAuth, data: SquareOrderReqData): Promise<SquareOrderDtoRes> => {
  const result = await post<SquareOrderDtoRes>(`${RESERVATION_URL}/square-calculate-order`, {
    reservation: data.reservation,
    reservationAddons: data.reservationAddons,
    currentPackage: data.currentPackage,
    venue: data.venue,
  });
  return result.data;
};

export const checkSquareOrderCalculationAction =
  (): AppThunk<Promise<SquareOrderDtoRes | undefined>> => async (dispatch, getState) => {
    try {
      const isPaymentLink = window.location.pathname.includes("pay-reservation")
      if (isPaymentLink) {
        // skip check order calculation if on payment page
        return;
      }

      const reservation = selectReservation(getState());
      const reservationAddons = selectReservationAddons(getState());
      const currentPackage = selectCurrentPackage(getState());
      const venue = selectVenue(getState());

      const guest = selectGuest(getState());
      const isGuest = Boolean(guest);

      const postWith = isGuest ? postWithGuestAuth : postWithAuth;
      dispatch({ type: ActionTypeEnum.CheckSquareOrderCalculation });
      const data = await checkSquareOrderCalculation(postWith, {
        reservation,
        reservationAddons,
        currentPackage,
        venue,
      });
      dispatch({
        type: ActionTypeEnum.CheckSquareOrderCalculationSuccess,
        payload: data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.CheckSquareOrderCalculationFailure,
        payload: "error check Square Order Calculation",
      });
      throw new Error(
        "error check Square Order Calculation"
      );
    }
  };

const createSquareOrder = async (post: typeof postWithAuth, data: SquareOrderReqData): Promise<SquareOrderDtoRes> => {
  const result = await post<SquareOrderDtoRes>(`${RESERVATION_URL}/square-create-order`, data);
  return result.data;
};

export const createSquareOrderAction =
  (): AppThunk<Promise<SquareOrderDtoRes>> => async (dispatch, getState) => {
    try {
      const reservation = selectReservation(getState());
      const reservationAddons = selectReservationAddons(getState());
      const currentPackage = selectCurrentPackage(getState());
      const venue = selectVenue(getState());

      const guest = selectGuest(getState());
      const isGuest = Boolean(guest);

      const postWith = isGuest ? postWithGuestAuth : postWithAuth;
      const data = await createSquareOrder(postWith, {
        reservation,
        reservationAddons,
        currentPackage,
        venue,
      });
      return data;
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.CheckSquareOrderCalculationFailure,
        payload: "error create Square Order",
      });
      throw new Error("error create Square Order");
    }
  };

export const createSquarePartialAction =
  (): AppThunk<Promise<CreateSquarePartialPaymentDto>> => async (dispatch, getState) => {
    try {
      const reservation = selectReservation(getState());
      const reservationAddons = selectReservationAddons(getState());
      const confirmation = selectReservationConfirmation(getState());

      const guest = selectGuest(getState());
      const isGuest = Boolean(guest);

      const postWith = isGuest ? postWithGuestAuth : postWithAuth;

      dispatch({ type: ActionTypeEnum.CreateSquarePartial })

      const { data } = await postWith<CreateSquarePartialPaymentDto>(
        `${RESERVATION_URL}/create-square-partial-payment`,
        {
          reservation,
          confirmation,
          reservationAddons,
          isGuest,
        }
      );

      dispatch({
        type: ActionTypeEnum.CreateSquarePartialSuccess,
        payload: data,
      })
      return data;
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.CreateSquarePartialFailure,
        payload: "error create Square Partial Payment",
      });
      throw new Error("error create Square Partial Payment");
    }
  };

type UpdateSquarePaymentReqData = SquareOrderReqData & {
  payment: Payment;
  deposit: number;
}

const getUpdatedSquarePayment = async (post: typeof postWithAuth, data: UpdateSquarePaymentReqData): Promise<Payment> => {
  const { deposit, payment, ...squareData } = data;
  const { isRexSquareEqual, squareOrder } = await checkSquareOrderCalculation(post, squareData);
  const squareOrderPrice = Number(squareOrder.totalMoney?.amount) / 100;
  if (Number.isNaN(squareOrderPrice) || deposit !== squareOrderPrice)
    return payment;
  if (!isRexSquareEqual) return payment;
  const {
    isRexSquareEqual: createdIsEqual,
    squareOrder: createdSquareOrder,
  } = await createSquareOrder(post, squareData);
  if (!createdIsEqual || !createdSquareOrder.id) return payment;
  if (!createdSquareOrder.id) return payment;
  return {
    ...payment,
    details: {
      ...payment.details,
      orderId: createdSquareOrder.id,
    },
  };
};

export const checkSquarePartialPaymentAction =
  (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
      const reservation = selectReservation(getState());
      const guest = selectGuest(getState());
      const isGuest = Boolean(guest);
      const getWith = isGuest ? getWithGuestAuth : getWithAuth;

      const query = new URLSearchParams({
        isGuest: isGuest ? 'true' : 'false',
      })

      const { data } = await getWith<Reservation>(`${RESERVATION_URL}/account/${reservation.id}?${query.toString()}`);
      if (data.status === ReservationStatus.Ok) {
        dispatch({
          type: ActionTypeEnum.PaymentSuccess,
          payload: data,
        })
        dispatch(pushUrlPathAction("/reservation-info/confirmed", {
          venue: data.venueName
        }));
        return true
      }
      return false
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.CreateSquarePartialFailure,
        payload: "error create Square Partial Payment",
      });
      throw new Error("error create Square Partial Payment");
    }
  };
