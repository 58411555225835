import React, { ChangeEvent, useEffect, useState } from "react";
import { isValidEmail, isValidPhone } from "../../../../../common/utils/validate";
import BoltIcon from '../../../../assets/bolt.svgr';
import LoginIcon from '../../../../assets/login.svgr';
import { useViewport } from "../../../hooks/responsive";
import { NewClient } from "../../../store/types";

import { Box, Checkbox, FormControlLabel, FormHelperText, Link, Typography } from "@material-ui/core";
import ButtonMain from "../../common/ButtonMain/ButtonMain";
import ButtonWithIcon2 from "../../common/ButtonMain/ButtonWithIcon2";
import Input from "../../common/Input/Input";
import PhoneInput from "../../common/Input/PhoneInput";
import useAccount2Styles from "../Account/useAccount2Styles";
import "./registration.scss";
import HtmlRenderer from "../../../../../common/components/HTMLRenderer/HTMLRenderer";

interface Props {
    register: (newClient: NewClient) => void;
    cleanError: () => void;
    continueReservation: boolean;
    error?: string;
    uiConfig: any;
    onSignIn: () => void;
    onContinueAsGuest: () => void;
    showCheckoutAsGuest: boolean;
    keepMeUpdatedText?: string
}

export const Registration = ({
    register,
    cleanError,
    error,
    continueReservation,
    uiConfig,
    onSignIn,
    onContinueAsGuest,
    showCheckoutAsGuest,
    keepMeUpdatedText
}: Props) => {
    const { isMobile } = useViewport();
    const classes = useAccount2Styles();
    const [firstName, setFirstName] = useState<string | undefined>("");
    const [lastName, setLastName] = useState<string | undefined>("");
    const [phone, setPhone] = useState<string | undefined>("");
    const [email, setEmail] = useState<string | undefined>("");
    const [confirmEmail, setConfirmEmail] = useState<string | undefined>("");
    const [password, setPassword] = useState<string | undefined>("");
    const [subscribeToOffers, setSubscribeToOffers] = useState<boolean>(true);

    const [errorFirstName, setErrorFirstName] = useState<string>("");
    const [errorLastName, setErrorLastName] = useState<string>("");
    const [errorPhone, setErrorPhone] = useState<string>("");
    const [errorEmail, setErrorEmail] = useState<string>("");
    const [errorConfirmEmail, setErrorConfirmEmail] = useState<string>("");
    const [errorPassword, setErrorPassword] = useState<string>("");
    useEffect(() => () => cleanError(), [cleanError]);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const emailLower = email?.toLowerCase();
        const confirmEmailLower = confirmEmail?.toLowerCase();

        if (
            firstName &&
            lastName &&
            phone &&
            emailLower &&
            confirmEmailLower &&
            password &&
            emailLower === confirmEmailLower &&
            isValidPhone(phone) &&
            isValidEmail(emailLower)
        ) {
            register({
                firstName,
                lastName,
                phone,
                email: emailLower,
                confirmEmail: confirmEmailLower,
                password,
                subscribeToOffers,
                continueReservation,
            });
            return;
        }

        if (
            emailLower &&
            confirmEmailLower &&
            emailLower === confirmEmailLower &&
            isValidEmail(emailLower) == true
        ) {
            setErrorEmail("");
            setErrorConfirmEmail("");
        }

        if (!firstName) {
            setErrorFirstName("This field is required.");
        }
        if (!lastName) {
            setErrorLastName("This field is required.");
        }
        if (!phone || !isValidPhone(phone)) {
            setErrorPhone("Please enter a valid phone number.");
        }
        if (!emailLower || !isValidEmail(emailLower)) {
            setErrorEmail("Please enter a valid email address.");
        }
        if (!confirmEmailLower || !isValidEmail(confirmEmailLower)) {
            setErrorConfirmEmail("Please enter a valid email address.");
        }
        if (confirmEmailLower && emailLower && emailLower !== confirmEmailLower) {
            setErrorEmail("These fields must match.");
            setErrorConfirmEmail("These fields must match.");
        }
        if (!password) {
            setErrorPassword("This field is required.");
        }
    };

    const onFirstNameChange = (e: ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorFirstName("");
        setFirstName(e.target.value);
    };
    const onLastNameChange = (e: ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorLastName("");
        setLastName(e.target.value);
    };
    const onPhoneChange = (text?: string) => {
        setErrorPhone("");
        setPhone(text);
    };
    const onEmailChange = (e: ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorEmail("");
        setErrorConfirmEmail("");
        setEmail(e.target.value);
    };
    const onConfirmEmailChange = (e: ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorEmail("");
        setErrorConfirmEmail("");
        setConfirmEmail(e.target.value);
    };
    const onPasswordChange = (e: ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorPassword("");
        setPassword(e.target.value);
    };

    return (
        <Box maxWidth={isMobile ? '100%' : '400px'} marginX={'auto'} textAlign={'center'} width={isMobile ? '100%' : '400px'} px={isMobile ? '24px' : 0}>
            <Typography className={`heading-h1 ${classes.title}`}>Create Account</Typography>
            <Typography className="body-big">
                Already have an account?
                <Link className={classes.link} onClick={onSignIn}>Sign in</Link>
            </Typography>
            <form onSubmit={onSubmit}>

                <Box display={'flex'} flexDirection={'column'} style={{ gap: '16px' }} my={'32px'}>
                    <Box display={'flex'} style={{ gap: '16px' }}>
                        <Input errorInput={errorFirstName} label="First Name" placeholder={'Enter first name'} value={firstName} onChange={onFirstNameChange} fullWidth id="first_name" name="first_name" />
                        <Input errorInput={errorLastName} label="Last Name" placeholder={'Enter last name'} value={lastName} onChange={onLastNameChange} fullWidth id="last_name" name="last_name" />
                    </Box>
                    <PhoneInput isReversed error={errorPhone} placeholder="Phone number" defaultCountry="US" value={phone || ""} onChange={onPhoneChange} />
                    <Input type="email" errorInput={errorEmail} label="Email Address" placeholder='Enter your email' value={email} onChange={onEmailChange} fullWidth />
                    <Input type="email" errorInput={errorConfirmEmail} label="Confirm Email Address" placeholder='Confirm your email' value={confirmEmail} onChange={onConfirmEmailChange} fullWidth />
                    <Input errorInput={errorPassword} label="Password" placeholder='Enter your password' value={password} isPassword onChange={onPasswordChange} fullWidth />

                    {error && <FormHelperText className={`caption ${classes.error}`}>{error}</FormHelperText>}
                </Box>

                <Box mt={'32px'}>
                    <FormControlLabel
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        control={
                            <Checkbox
                                checked={subscribeToOffers}
                                onChange={() => setSubscribeToOffers(!subscribeToOffers)}
                                name="checked"
                                color="default"
                                style={{
                                    color: "#2E3A48",
                                }}
                            />
                        }
                        label={<HtmlRenderer className="body-small" html={keepMeUpdatedText || 'Keep me updated on exclusive offers and events'} />}
                    />
                </Box>

                <Typography className={`body-small ${classes.textAlignLeft} ${classes.procceding}`}>
                    By proceeding, you will create an account and agree to our{' '}
                    <Link href={uiConfig.privacyPolicy} target="_blank" className={classes.linkBlack}>Privacy Policy</Link>
                    {' '}and{' '}
                    <Link href={uiConfig.termsOfUse} target="_blank" className={classes.linkBlack}> Terms of Use </Link>
                </Typography>


                <ButtonMain type="submit" isMobileAttached={isMobile} >Continue</ButtonMain>
            </form>

            <Box my={'32px'} position={'relative'}>
                <hr className={classes.lineThrough} />
                <Typography className={`body-small bold ${classes.or}`}>OR</Typography>
            </Box>

            <Box display={'flex'} flexDirection={'column'} style={{ gap: '20px' }}>
                {showCheckoutAsGuest && <ButtonWithIcon2 icon={<BoltIcon />} onClick={onContinueAsGuest}>Checkout as guest</ButtonWithIcon2>}
                <ButtonWithIcon2 icon={<LoginIcon />} onClick={onSignIn}>Sign in</ButtonWithIcon2>
            </Box>
        </Box>
    );
};
