import React from "react";
import { extractInnerTags } from "../../../common/utils/extractInnerText";

import "./htmlRenderer.scss";

type HtmlRendererProps = {
  html?: string;
  className?: string;
  noDefaultClassName?: boolean;
  withExtractInnerTags?: boolean;
};
const HtmlRenderer = ({
  html = "",
  className = "",
  noDefaultClassName,
  withExtractInnerTags = false,
}: HtmlRendererProps) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: withExtractInnerTags ? extractInnerTags(html) : html }}
      className={`${noDefaultClassName ? "" : "html-renderer"} ${className}`}
    />
  );
};

export default HtmlRenderer;
