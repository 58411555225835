export const extractInnerText = (html: string) => {
  const div = document.createElement("div");
  div.innerHTML = html;

  const childrenArray: HTMLElement[] = [].slice.call(div.childNodes);

  return childrenArray
    .map((line) => {
      return line.innerText || line.textContent;
    })
    .join("<br/>");
};

const extractedTags = ["picture"];

export const extractInnerTags = (html: string) => {
  const div = document.createElement("div");
  div.innerHTML = html;

  const childrenArray: HTMLElement[] = [].slice.call(div.childNodes);

  let isExtractedTag = false;
  return childrenArray
    .map((line) => {
      const currentParentTag = addParentTag(line);

      if (extractedTags.find(htmlIncludeCloseTag(line)) !== undefined) {
        isExtractedTag = false;
        return currentParentTag(line.innerText || line.textContent);
      }

      if (
        isExtractedTag ||
        extractedTags.find(htmlIncludeOpenTag(line)) !== undefined
      ) {
        isExtractedTag = true;
        return currentParentTag(line.innerText || line.textContent);
      }

      return currentParentTag(line.innerHTML);
    })
    .join("");
};

export function htmlIncludeOpenTag(htmlLine: HTMLElement) {
  return (tag: string): boolean => {
    const openTag = `<${tag}`;
    return Boolean(
      htmlLine.innerText.includes(openTag) ||
        htmlLine.innerHTML.includes(openTag) ||
        htmlLine.textContent?.includes(openTag)
    );
  };
}

function htmlIncludeCloseTag(htmlLine: HTMLElement) {
  return (tag: string): boolean => {
    const closeTag = `</${tag}>`;
    return Boolean(
      htmlLine.innerText.includes(closeTag) ||
        htmlLine.innerHTML.includes(closeTag) ||
        htmlLine.textContent?.includes(closeTag)
    );
  };
}

function addParentTag(parentHtmlElement: HTMLElement) {
  return (html: string | null): string => {
    return `<${parentHtmlElement.nodeName}>${html}</${parentHtmlElement.nodeName}>`;
  };
}
