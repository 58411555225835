import { Box, Button, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from "react";
import { FULL_DATE_FORMAT } from '../../../../common/constants/timedate';
import {
    calculateDeposit,
    formatCustomFees,
    formatDuration,
    formatPrice,
    formatReservationDate,
    getReservationTotalPrice,
    slotToTime,
    totalSumFees,
} from "../../../../common/utils/formats";
import {
    GiftCardBalanceDto,
    GiftCardBalanceRequestDto,
    GiftCardPaymentRequestDto,
} from "../../../../server/src/dto/giftCard.dto";
import { PackageNameDto } from "../../../../server/src/dto/packageName.dto";
import { ReservationDto } from "../../../../server/src/dto/reservation.dto";
import { ReservationAddonDto } from "../../../../server/src/dto/reservationAddon.dto";
import { VenueDto } from "../../../../server/src/dto/venue.dto";
import { AddonType, DepositPercentageType, FeeType } from "../../../../server/src/entities/enums";
import AccountCircleIcon from '../../../assets/account-circle.svgr';
import CalendarMonthIcon from '../../../assets/calendar-month.svgr';
import EditPenIcon from '../../../assets/edit-pen.svgr';
import ExpandIcon from '../../../assets/expand-icon.svgr';
import ScheduleIcon from '../../../assets/schedule.svgr';
import Loader from '../common/Loader/Loader';
import OrderSummaryCard from './OrderSummaryCard';
import OrderSummaryLine from './OrderSummaryLine';
import OrderSummaryTag from './OrderSummaryTag';

import HTMLRenderer from '../../../../common/components/HTMLRenderer/HTMLRenderer';
import CloseLargeIcon from '../../../assets/close-large.svgr';
import { useViewport } from '../../hooks/responsive';
import { formatDate } from '../MakePackageReservation/utils';
import CouponCodes from './CouponCodes';
import { getAgeBasedGroups } from './utils';
import '../../../../common/components/OrderSummary/orderSummary.scss';
interface Props {
    onEdit: () => void;
    onContinue?: () => void;
    reservation?: ReservationDto;
    venue?: VenueDto;
    completed?: boolean;
    actionText?: string;
    isMobile?: boolean;
    isHideDuration?: boolean;
    currentPackage?: PackageNameDto;
    showGiftCard?: boolean;
    giftBalance?: GiftCardBalanceDto;
    getGiftCardBalance?: (cardParams: GiftCardBalanceRequestDto) => void;
    giftBalanceError?: string;
    addGiftCard?: (cardParams: GiftCardPaymentRequestDto) => void;
    giftCardAmount?: number;
    showDiscounts?: boolean;
    applyCouponCode?: (couponCodes: string[]) => void;
    isSkipPayment?: boolean;
    isUpdateReservation?: boolean;
    oldDeposit?: number;
    reservationAddons?: ReservationAddonDto[];
    twelveHourClockFormat: boolean;
    notShowEdit?: boolean;
    notShowContinue?: boolean;
    isLoading?: boolean
    onCloseModal?: () => void;
    isSummaryExpanded?: boolean;
    isModal?: boolean;
    isHandleProgressAddons?: boolean;
    errorCouponCode?: string;
}

export const OrderSummary = ({
    reservation,
    venue,
    completed = false,
    onEdit,
    isMobile,
    isHideDuration,
    giftCardAmount,
    showDiscounts,
    applyCouponCode,
    isSkipPayment,
    isUpdateReservation,
    oldDeposit,
    reservationAddons,
    twelveHourClockFormat,
    notShowEdit,
    isLoading,
    onCloseModal,
    isSummaryExpanded,
    isModal,
    isHandleProgressAddons,
    currentPackage,
    errorCouponCode
}: React.PropsWithChildren<Props>) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(isSummaryExpanded === undefined ? true : false);
    const collapsibleRef = useRef<HTMLDivElement>(null);
    const { isTabletScreen } = useViewport()
    const toggleCollapse = () => {
        setIsExpanded(!isExpanded);
    };
    useEffect(() => {
        if (collapsibleRef.current) {
            collapsibleRef.current.style.transition = 'max-height 0.3s ease-out';
            collapsibleRef.current.style.overflow = 'hidden';
            collapsibleRef.current.style.maxHeight = isExpanded ? `${collapsibleRef.current.scrollHeight}px` : '0';
        }
    }, [isExpanded, isLoading]);


    if (!reservation || !venue) {
        return null; // show error
    }

    const durationText = formatDuration(
        reservation.duration,
        reservation.timeSlotDuration
    );
    const discountAmount = reservation.discountAmount || 0;
    let guests = reservation.guests
    if (reservationAddons && reservationAddons.length > 0) {
        reservationAddons.forEach(addon => {
            if (addon.addonType === AddonType.guestCount) {
                guests = guests + addon.quantity
            }
        })
    }
    let deposit = calculateDeposit({
        reservation,
        giftCardAmount: giftCardAmount ? +giftCardAmount : 0,
        isUpdateReservation: !!isUpdateReservation,
        isUpdateWithVenueChange: venue?.id !== reservation?.venueId
    });

    let totalPaid = !!oldDeposit ? +oldDeposit : 0;
    let modificationFeeInDollars = reservation.modificationFee || 0;
    const isReceiptMode = onCloseModal && !isHandleProgressAddons
    const totalPrice = getReservationTotalPrice(reservation, isReceiptMode);
    const total = Math.round((+totalPrice - +reservation.payed) * 100) / 100;
    if (isUpdateReservation) {
        if (deposit <= 0) {
            isSkipPayment = true;
        } else {
            isSkipPayment = false;
        }
    }

    const dueNow = formatPrice(deposit, reservation.currency, true)
    const totalDue = formatPrice(total, reservation.currency, true)
    const depositParamValue =
        +reservation.deposit <= +reservation.depositParam
            ? +reservation.deposit
            : +reservation.depositParam;
    let depositParamText =
        reservation.depositType === FeeType.PERCENT
            ? `${reservation.depositParam}%`
            : `${formatPrice(depositParamValue, reservation.currency, true)}`;

    const isPriceBasedDeposit = reservation.depositType !== FeeType.PERCENT

    const customFees = formatCustomFees(reservation.customFees, reservation.currency);

    let customFeeElements = null;
    if (customFees.length > 0) {
        customFeeElements = customFees.map(({ name, value }) => (
            <OrderSummaryLine title={name} price={value} />

        ));
    }
    const reservationDepositPercent =
        reservation.depositType === FeeType.PERCENT
            ? `${reservation.depositParam}%`
            : '100%';
    const reservationAddonsPriceElements =
        (((reservationAddons && reservationAddons.length > 0) || (reservation.addons && reservation.addons.length > 0)))
            ? (((onCloseModal && isHandleProgressAddons) || isUpdateReservation) ? [...(reservation.addons || [])
                .filter(add => !(reservationAddons?.some(resAdd => resAdd.name === add.name))), ...(reservationAddons || []),]
                : reservationAddons || []).map((reservationAddon) => {
                    const totalPrices = reservationAddon?.modifiers?.reduce((sum, modifier) => {
                        const modifierTotal = modifier.options.reduce((optionSum, option) => {
                            return optionSum + Number(option.price);
                        }, 0);

                        return sum + modifierTotal;
                    }, 0);


                    const totalPricesOptions = reservationAddon?.options?.reduce((sum, option) => {
                        return sum + parseFloat(option.price.toString());
                    }, 0);
                    let summaryString = '';
                    reservationAddon?.modifiers?.forEach((modifier, modifierIndex) => {
                        modifier.options.forEach((option, optionIndex) => {
                            summaryString += option.name;
                            if (optionIndex !== modifier.options.length - 1) {
                                summaryString += ', ';
                            }
                        });
                        if (reservationAddon?.modifiers && modifierIndex !== reservationAddon?.modifiers?.length - 1) {
                            summaryString += '\n';
                        }
                    });
                    let isChosenReservation = false;

                    if (!!onCloseModal && isHandleProgressAddons) {
                        isChosenReservation = true;
                    } else if (isUpdateReservation && reservation.addons?.some(add => add.name === reservationAddon.name) && reservationAddons?.some(add => add.name === reservationAddon.name)) {
                        isChosenReservation = false;
                    } else if ((onCloseModal && !isHandleProgressAddons) || (isUpdateReservation && reservation.addons?.some(addon => addon.addonId === reservationAddon.addonId))) {
                        isChosenReservation = true;
                    }



                    const getOptionsTitlesString = () => {
                        const modifiersOption = reservationAddon?.modifiers?.map(modifier =>
                            modifier.options.map(option => option.name)
                        ).reduce((acc, val) => acc.concat(val), []);
                        const optionsOption = reservation.addons?.map(item => item.options?.map(option => option.name).join(', '))
                            .filter(nameString => nameString)

                        const allTitles = isChosenReservation ? optionsOption : modifiersOption

                        return allTitles?.join(', ');
                    }


                    const withModifiers = isChosenReservation ?
                        (reservationAddon.options && reservationAddon.options.length > 0)
                        : (reservationAddon?.modifiers?.length && reservationAddon?.modifiers?.length > 0 && reservationAddon?.modifiers?.some(modifier => modifier.options.length > 0))

                    const addonDepositPercent = reservationAddon.depositPercentage === DepositPercentageType.usePackageDeposit ? reservationDepositPercent : '0%'
                    const addonPrice =
                        Math.round(
                            (reservationAddon.price) * reservationAddon.quantity * 100
                        ) / 100 + (((totalPrices || totalPricesOptions)) ? ((isChosenReservation ? totalPricesOptions : totalPrices)! * reservationAddon.quantity) : 0);




                    let addonName = `${reservationAddon.quantity} x ${reservationAddon.name}`;
                    return (

                        <OrderSummaryCard key={reservationAddon.addonId} title={addonName} price={formatPrice(addonPrice, reservation.currency, true)} subTitle={withModifiers ? getOptionsTitlesString() : (isPriceBasedDeposit ? '' : `Due now ${addonDepositPercent}`)} subTitleAdditional={withModifiers ? (isPriceBasedDeposit ? '' : `Due now ${addonDepositPercent}`) : ''} />

                    );
                })
            : null;

    return (

        <>

            <Box className={`order-summary order-summary-v2  ${isMobile ? "mobile" : ""} ${isTabletScreen ? 'tablet' : ""} ${isModal ? 'modal' : ""}`} >
                <Loader open={isLoading as boolean} />
                <Box
                    className={
                        `${`${isMobile ? "mobile" : ""}`} order-summary-inner-v2 order-summary-header-v2 ${isMobile ? "mobile" : ""} ${isTabletScreen ? "tablet" : ""}`
                    }
                >
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={'16px'}>
                        <Typography variant="h1" className="order-summary-title no-margin" >
                            Order Summary
                        </Typography>
                        {((onCloseModal) && !isMobile) && <Button className='gallery-btn' onClick={onCloseModal}>
                            <CloseLargeIcon />
                        </Button>}
                    </Box>
                    <Typography variant="subtitle1" className="order-summary-subTitle" >
                        {isReceiptMode ? reservation.packageName : venue?.name}
                    </Typography>
                    <Typography variant="subtitle2" className="order-summary-subTitle-alt" >
                        {venue.address} <br />
                        {venue.address2 && <>{venue.address2} <br /></>}
                        <>{`${venue.city}, ${venue.state} ${venue.zipCode}`}</>
                    </Typography>

                    <Box className="order-summary-tags">
                        <Box className="order-summary-date">
                            <OrderSummaryTag withoutMargin icon={<CalendarMonthIcon />} title={isTabletScreen ? formatDate(reservation.date, isTabletScreen) : formatReservationDate(reservation, FULL_DATE_FORMAT)}
                            />
                            {!notShowEdit ? <Button onClick={onEdit} style={{ minWidth: 0, padding: '8px', borderRadius: '4px' }}>
                                <EditPenIcon />
                            </Button> : null}
                        </Box>
                        <OrderSummaryTag icon={<ScheduleIcon />} title={`${slotToTime(
                            reservation?.slots,
                            reservation.timeSlotDuration,
                            twelveHourClockFormat,
                            reservation.timeSlotShifting
                        )} `} />
                        <OrderSummaryTag wrap withoutMargin icon={<AccountCircleIcon />} title={`${reservation.guests} Guest${reservation.guests > 1 ? 's' : ''} ${getAgeBasedGroups(reservation, currentPackage)}`} />
                    </Box>
                </Box>
                {(!!showDiscounts &&
                    !!applyCouponCode) && <CouponCodes reservation={reservation} deposit={deposit} isSkipPayment={isSkipPayment} applyCouponCode={applyCouponCode} error={errorCouponCode} />}


                <Box className={`order-summary-summary ${isTabletScreen ? 'tablet' : ''}`} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05) !important' }}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ cursor: 'pointer' }} onClick={toggleCollapse}>
                        <Typography variant="subtitle1" className="order-summary-subTitle no-margin" >
                            Summary
                        </Typography>
                        <ExpandIcon style={{ transform: isExpanded ? 'rotate(0deg)' : 'rotate(-180deg)', transition: 'transform 0.3s ease', cursor: 'pointer' }} />
                    </Box>

                    <div ref={collapsibleRef}>

                        <OrderSummaryCard title={reservation.packageName || 'Reservation'} price={formatPrice(
                            reservation.price,
                            reservation.currency,
                            true
                        )} subTitle={!isHideDuration ? durationText : `Due now ${depositParamText}`} subTitleAdditional={isHideDuration ? '' : `Due now ${depositParamText}`} />

                        {reservationAddonsPriceElements}

                        <OrderSummaryCard withoutSub withoutBorder title='Subtotal' price={formatPrice(
                            reservation.price + reservation.addonsPrice,
                            reservation.currency,
                            true
                        )} />
                        {customFees && customFees.length > 0 ? <Box className={`order-summary-summary-fee ${isTabletScreen ? 'tablet' : ''}`}>
                            <Typography variant="subtitle1" className="order-summary-subTitle" style={{ marginBottom: '14px' }} >
                                Fees
                            </Typography>

                            {customFeeElements}


                        </Box> : null}
                    </div>


                </Box>

                <Box className={`order-summary-summary ${isTabletScreen ? 'tablet' : ''}`} style={{ paddingBottom: '12px' }}>
                    <Typography variant="subtitle1" className="order-summary-subTitle" style={{ marginBottom: '14px' }} >
                        Total
                    </Typography>

                    <OrderSummaryLine title='Subtotal' price={
                        formatPrice(
                            reservation.price + reservation.addonsPrice,
                            reservation.currency,
                            true
                        )} />
                    {customFees && customFees.length > 0 ? <OrderSummaryLine title='Fees' price={formatPrice(totalSumFees(customFees), reservation.currency, true)} /> : null}
                    {discountAmount && discountAmount > 0 ? (
                        <OrderSummaryLine title={`Discount ${reservation.discounts ? `(${reservation.discounts})` : ''}`} price={`-${formatPrice(discountAmount, reservation.currency, true)}`} />
                    ) : null}
                    {reservation.discount > 0 ? (
                        <OrderSummaryLine title={reservation?.discountName || "Discount"} price={`-${formatPrice(reservation.discount, reservation.currency,
                            true)}`} />
                    ) : null}
                    {reservation.tax ? <OrderSummaryLine title='Tax' price={formatPrice(reservation.tax, reservation.currency, true)} /> : null}
                    {modificationFeeInDollars ? <OrderSummaryLine title='Modification Fee' price={formatPrice(
                        modificationFeeInDollars,
                        reservation.currency,
                        true
                    )} /> : null}
                    {!currentPackage?.hideDepositLineItem && <OrderSummaryLine title='Deposit' price={depositParamText} />}

                    {!isUpdateReservation && <OrderSummaryLine title='Grand Total' price={formatPrice(totalPrice, reservation.currency, true)} isPriceBold isTitleBold />}

                    {(totalPaid || isUpdateReservation) && <OrderSummaryLine title='Total Paid' price={"-" + formatPrice(totalPaid, reservation.currency, true)} isPriceBold isTitleBold />}


                </Box>
                <Box className={`order-summary-summary ${isTabletScreen ? 'tablet' : ''}`}>

                    <Box className='order-summary-card-header' marginBottom={'24px'}>

                        <Typography variant="subtitle1" className="order-summary-title-large">
                            {isReceiptMode ? 'Total Due' : 'Due Now'}
                        </Typography>

                        <Typography variant="subtitle1" className="order-summary-title-large">
                            {isReceiptMode ? totalDue : dueNow}
                        </Typography>
                    </Box>
                    {!completed &&
                        (<Box mt={'8px'} mb={'8px'}>
                            <HTMLRenderer className="information" html={reservation.textBox} />
                        </Box>)}



                </Box>

            </Box >
        </>

    );
};
