import React from "react";
import Input from '../../common/Input/Input';
import SelectWithIcon from '../../common/SelectWithIcon/SelectWithIcon';
import DatePicker2 from "../../common/Input/DatePicker";
import MultiSelect from "../../common/SelectWithIcon/MultiSelect";
import { QuestionType } from "../../../../../server/src/entities/enums";
import dayjs from "dayjs";
import { QUESTION_DATE_FORMAT, QUESTION_DATE_MONTH_FORMAT } from "../../../../../common/constants/timedate";

interface CustomFieldRendererProps {
    customQuestion: any;
    customField: string;
    customFieldLabel: string;
    customFieldValue: string | undefined;
    changeReservationCustomFieldsInformation: (fieldKey: string, newInformation: string) => void;
    errorCustomFields: string[];
    isMandatory: boolean;
    index: number;
}

const CustomFieldRenderer: React.FC<CustomFieldRendererProps> = ({
    customQuestion,
    customField,
    customFieldLabel,
    customFieldValue,
    changeReservationCustomFieldsInformation,
    errorCustomFields,
    isMandatory,
    index
}) => {
    switch (customQuestion.questionType) {
        case QuestionType.string:
            return (
                <Input
                    key={customField}
                    label={customFieldLabel}
                    name={customField}
                    value={customFieldValue || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationCustomFieldsInformation(customField, e.target.value || "")}
                    autoComplete={"off"}
                    errorInput={errorCustomFields[index]}
                    mandatory={isMandatory}
                />
            );

        case QuestionType.dropdown:
            const dropdownOptions = customQuestion.options?.split(',') || [];
            return (
                <SelectWithIcon
                    key={customField}
                    truncateLabel
                    placeholder=""
                    label={customFieldLabel}
                    options={dropdownOptions}
                    onChange={(e) => changeReservationCustomFieldsInformation(customField, e.target.value as string || "")}
                    value={customFieldValue || ""}
                    mandatory={isMandatory}
                    errorSelect={errorCustomFields[index]}
                />
            );

        case QuestionType.date:
            return (
                <DatePicker2
                    key={customField}
                    autoOk
                    label={customFieldLabel}
                    name={customField}
                    value={customFieldValue}
                    placeholder={QUESTION_DATE_FORMAT}
                    onChange={(date: any) => changeReservationCustomFieldsInformation(customField, date ? dayjs(date).format(QUESTION_DATE_FORMAT) : "")}
                    format={QUESTION_DATE_FORMAT}
                    errorInput={errorCustomFields[index]}
                    mandatory={isMandatory}
                />
            );

        case QuestionType.dateMonth:
            return (
                <DatePicker2
                    key={customField}
                    autoOk
                    label={customFieldLabel}
                    name={customField}
                    value={customFieldValue}
                    placeholder={QUESTION_DATE_MONTH_FORMAT}
                    onChange={(date: any) => changeReservationCustomFieldsInformation(customField, date ? dayjs(date).format(QUESTION_DATE_MONTH_FORMAT) : "")}
                    format={QUESTION_DATE_MONTH_FORMAT}
                    errorInput={errorCustomFields[index]}
                    mandatory={isMandatory}
                />
            );

        case QuestionType.multiSelect:
            return (
                <MultiSelect
                    key={customField}
                    label={customFieldLabel}
                    options={customQuestion.options?.split(',').map((option: string) => ({
                        label: option,
                        value: option
                    })) || []}
                    value={customFieldValue?.split(',') || undefined}
                    onChange={(e) => changeReservationCustomFieldsInformation(customField, (e.target.value as string[]).join(','))}
                    errorSelect={errorCustomFields[index]}
                    applyOptions={(selected: string[]) => {
                        changeReservationCustomFieldsInformation(customField, selected.join(','))
                    }}
                />
            );

        case QuestionType.email:
            return (
                <Input
                    key={customField}
                    label={customFieldLabel}
                    name={customField}
                    value={customFieldValue || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationCustomFieldsInformation(customField, e.target.value || "")}
                    autoComplete={"off"}
                    errorInput={errorCustomFields[index]}
                    mandatory={isMandatory}
                />
            );

        default:
            return null;
    }
};

export default CustomFieldRenderer;
