import React, { createContext, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { State } from "../store/types";

export const MAX_MOBILE_WIDTH = 769
export const MAX_MOBILE_WIDTH_NEW_DESIGN = 993
export const MAX_MIDDLE_WIDTH = 993
export const MAX_WIDTH = 1249
export const ViewportContext = createContext({});

export const ViewportProvider = ({ children }: any): React.ReactElement => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ width, height }}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => {
  const uiConfig = useSelector((state: State) => state.uiState.uiConfig)
  const { width, height }: any = useContext(ViewportContext);

  const MAX_MOBILE = MAX_MOBILE_WIDTH_NEW_DESIGN

  const isPhone = /iPhone|iPod|Android/i.test(navigator.userAgent)
  const isTablet = /iPad/i.test(navigator.userAgent)
  const isMobile = width < MAX_MOBILE || isPhone;
  const isMiddleScreen = width < MAX_MIDDLE_WIDTH || isTablet;
  const isTabletScreen = (width > MAX_MOBILE && width < MAX_WIDTH) || isTablet;
  const isMaxScreen = width > MAX_WIDTH && (!isMobile || !isMiddleScreen);
  return { isMobile, isMiddleScreen, isMaxScreen, isTabletScreen };
};
