import { createSelector } from "reselect";
import { UIState, State, STEP, UIConfigDto } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: UIState = Object.freeze({
  bootstrapped: false,
  infoState: STEP.INIT,
});

export default createReducer<UIState>(initialState, {
  [ActionTypeEnum.SetInfoState]: (step: STEP) => (state: UIState) => ({
    ...state,
    infoState: step,
  }),
  [ActionTypeEnum.GetUIConfig]: () => (state: UIState) => ({
    ...state,
    bootstrapped: false,
  }),
  [ActionTypeEnum.GetUIConfigSuccess]: (uiConfig: UIConfigDto) => (state: UIState) => ({
    ...state,
    uiConfig,
    bootstrapped: true,
  }),
  [ActionTypeEnum.GetUIConfigFailure]: () => (state: UIState) => ({
    ...state,
    uiConfig: undefined,
    bootstrapped: false,
  }),
});

export const selectUI = (state: State) => state.uiState;
export const selectInfoState = createSelector(
  selectUI,
  (state) => state.infoState
);
export const selectUIConfig = createSelector(
  selectUI,
  (state) => state.uiConfig
);
export const selectBootstrapped = createSelector(
  selectUI,
  (state) => state.bootstrapped
);
