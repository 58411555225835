import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useViewport } from '../../../hooks/responsive';

interface CustomSwiperProps {
    children: React.ReactNode[];
    notShowButtons?: boolean;
    showReservationTime?: boolean,
    width?: string | number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        container: {
            position: 'relative',
            width: '100%',
        },
        swipeableViews: {
            overflow: 'hidden',
        },
        swipeableViewsMobile: {
            overflow: 'hidden',
            width: '115vw',

        },
        swipeableViewsMobileDuration: {
            overflow: 'hidden',
            width: '129vw',

        },
        swipeableViewsMobileLast: {
            marginLeft: '-15vw',

        },
        swipeableViewsMobileDurationLast: {
            marginLeft: '-29vw',


        },
        buttonContainer: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(calc(-50% + 10px))',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
        button: {
            background: '#FFF',
            backgroundColor: '#FFF',
            boxShadow: '0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04)',
            border: '1px solid rgba(0, 0, 0, 0.05)',
            width: '32px',
            height: '32px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '32px',
            borderRadius: '50%',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        disabledButton: {
            color: '#CCC',
        }
    })
);

const CustomSwiper: React.FC<CustomSwiperProps> = ({ children, notShowButtons, showReservationTime, width }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState<number>(0);
    const { isMobile, isTabletScreen } = useViewport();
    const swipeableViewsRef = useRef<HTMLDivElement>(null);
    // let touchStartX = 0;
    // let touchEndX = 0;
    // let touchStartY = 0;
    // let touchEndY = 0;
    // let momentum = 0;
    // let lastTouchEnd = 0;

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    // const handleTouchStart = (e: TouchEvent) => {
    //     touchStartX = e.touches[0].pageX;
    // };

    // const handleTouchMove = (e: TouchEvent) => {
    //     touchEndX = e.touches[0].pageX;
    //     const deltaX = touchEndX - touchStartX;

    //     if (Math.abs(deltaX) > Math.abs(e.touches[0].pageY - touchStartX)) {
    //         // Prevent vertical scrolling when swiping horizontally
    //         e.preventDefault();
    //     }
    // };

    // const handleTouchEnd = () => {
    //     const deltaX = touchEndX - touchStartX;
    //     const swipeThreshold = 50; // Adjust this value as needed

    //     if (deltaX > swipeThreshold) {
    //         handleBack();
    //     } else if (deltaX < -swipeThreshold) {
    //         handleNext();
    //     }
    // };

    // useEffect(() => {
    //     const swipeableViews = swipeableViewsRef.current;
    //     if (swipeableViews) {
    //         swipeableViews.addEventListener('touchstart', handleTouchStart);
    //         swipeableViews.addEventListener('touchmove', handleTouchMove, { passive: false });
    //         swipeableViews.addEventListener('touchend', handleTouchEnd);
    //     }
    //     return () => {
    //         if (swipeableViews) {
    //             swipeableViews.removeEventListener('touchstart', handleTouchStart);
    //             swipeableViews.removeEventListener('touchmove', handleTouchMove);
    //             swipeableViews.removeEventListener('touchend', handleTouchEnd);
    //         }
    //     };
    // }, []);


    const styleProps = (!isMobile) ? { style: { width, maxWidth: width } } : {};

    return (
        <div className={classes.container} style={!isMobile ? { width: width, maxWidth: width } : {}} ref={isTabletScreen ? null : swipeableViewsRef}>
            <SwipeableViews
                // {...(isMobile ? { slideStyle: { width: '300%' }, containerStyle: { width: '33%' } } : {})}
                index={activeStep}
                onChangeIndex={handleStepChange}
                className={`${classes.swipeableViews}`}

                {...styleProps}     >
                {children}
            </SwipeableViews>
            {
                !notShowButtons && (
                    <>
                        <Button
                            onClick={handleBack}
                            className={classes.button}
                            style={{ ...(isMobile ? { top: '-32px', right: '40px' } : { left: '-16px' }), }}
                            disabled={activeStep === 0}
                        >
                            <ArrowBack style={{ fontSize: '16px' }} />
                        </Button>
                        <Button
                            onClick={handleNext}
                            className={classes.button}
                            style={{ right: '-16px', ...(isMobile ? { top: '-32px', right: 0 } : {}), }}
                            disabled={activeStep === children.length - 1}
                        >
                            <ArrowForward style={{ fontSize: '16px' }} />
                        </Button>
                    </>
                )
            }
        </div >
    );
};

export default CustomSwiper;
