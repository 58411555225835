import { Box, Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import HtmlRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import { formatPrice } from "../../../../common/utils/formats";
import defaultImage from "../../../assets/default-image.jpg";
import PlusIcon from "../../../assets/plus-icon.svg";
import PlusTabletIcon from "../../../assets/plus-tablet-icon.svg";
import { setSelectedPlanAction, subscribeToPlanAction } from "../../actions/membership-actions";
import { useViewport } from "../../hooks/responsive";
import useAccordion from "../../hooks/useAccordion";
import ButtonMain from "../common/ButtonMain/ButtonMain";
import SwipeableBottomDrawer from "../common/SwipeableDrawer/SwipeableBottomDrawer";
import { MembershipPlan } from "./entity";
import { selecAuthtError, selectAuthenticated, selectAuthError, selectIsAuthRequestInProgress } from "../../reducers/auth-reducer";
import { State } from "../../store/types";
import { pushUrlPathAction } from "../../actions/reservation-actions";
import membership, { selectError, selectPlan } from "../../reducers/membership";
import NoAvailiableGuests from "../PackageCard/NoAvailiableGuests";

interface OwnProps {
  plan: MembershipPlan;
  isAnnual: boolean;
  isSelected: boolean;
  onSelectPlan: (planId: string) => void;
}

const mapStateToProps = (state: State) => ({
  authenticated: selectAuthenticated(state),
  selectedPlan: selectPlan(state),
  error: selectAuthError(state),
  isRequestInProgress: selectIsAuthRequestInProgress(state),
});

const mapDispatchToProps = {
  subscribeToPlan: subscribeToPlanAction,
  pushUrlPath: pushUrlPathAction,
  setSelectedPlan: setSelectedPlanAction
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = OwnProps & ConnectedProps<typeof connector>;

const MemberShipCard = ({ plan, isAnnual, subscribeToPlan, authenticated, pushUrlPath, setSelectedPlan, isSelected, onSelectPlan, error, selectedPlan, isRequestInProgress }: Props) => {
  const { accordionDivRef, isShowMore, setIsShowMore } = useAccordion();
  const { isMobile, isTabletScreen, isMiddleScreen } = useViewport();

  useEffect(() => {
    setIsShowMore(isSelected);
  }, [isSelected]);

  const renderPrice = () => {
    const price = formatPrice(
      (isAnnual ? plan.annualRate : plan.monthlyRate) || 0,
      plan.currency,
      false
    );

    return (
      <Typography className="package-card__title-v2 package-card__price-v2">
        {price}
        <span className="package-card__price-method">
          /{isAnnual ? "year" : "month"}
        </span>
      </Typography>
    );
  };

  const renderButton = () => {
    return (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleShowMoreInfo();
        }}
        className={`btn-v2 ${false ? "loading" : ""} ${isTabletScreen ? "tablet" : "desktop"
          }`}
        disabled={false}
      >
        <img
          src={isTabletScreen ? PlusTabletIcon : PlusIcon}
          alt="PlusIcon"
          style={{
            transform: isShowMore ? "rotate(45deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Button>
    );
  };

  const renderDesktopView = () => {
    return (
      <>
        <Box
          className={`package-card__image-container-v2 ${isMobile ? "mobile" : ""
            } ${isTabletScreen ? "tablet" : ""}`}
        >
          <img
            src={plan.image || defaultImage}
            alt={plan.name}
            className={`package-card__image-v2 ${isMobile ? "mobile" : ""}`}
            style={isShowMore ? { borderBottomLeftRadius: 0 } : {}}
          />
          {/*<span className='package-card__tag'>NEW</span>*/}
        </Box>
        <Box
          className={`package-card__content-v2 ${isMobile ? "mobile" : ""} ${isTabletScreen ? "tablet" : ""
            }`}
          position={"relative"}
        >
          <Box mb={isMobile ? "12px" : 0}>
            <Box className="package-card__content-top-v2">
              <Typography className="package-card__title-v2 ellipsis">
                {plan.name}
              </Typography>
              {renderPrice()}
            </Box>
            <Box maxWidth={"394px"}>
              <HtmlRenderer
                className="root-text truncate-text-4"
                html={plan.shortDescription}
              />
            </Box>
          </Box>
          <Box
            position={"absolute"}
            right={isMobile ? "20px" : "32px"}
            bottom={isMobile ? "20px" : "32px"}
          >
            {renderButton()}
          </Box>
        </Box>
      </>
    );
  };

  const renderActiveView = () => {
    const handleSignUp = () => {
      if (authenticated) {
        subscribeToPlan(plan, isAnnual);
      } else {
        pushUrlPath('/reservation-info/account', {
          membership: plan.id,
          isAnnual
        });
      }
    };
    return (
      <div
        ref={accordionDivRef}
        style={{
          transition: "height 0.3s ease-in-out",
          overflowY: isMobile ? "auto" : "hidden",
          overflowX: "hidden",
        }}
      >
        <Box p={"32px"}>
          <Box mb={"44px"}>
            <HtmlRenderer className="root-text" html={plan.longDescription} />
          </Box>
          <ButtonMain isMobileAttached={isMobile} onClick={handleSignUp} >
            Sign up
          </ButtonMain>
        </Box>
      </div>
    );
  };

  const handleShowMoreInfo = () => {
    onSelectPlan(plan.id);
    setSelectedPlan(plan);
  };

  const membershipCardOffset = "100px";

  const memberShipIsActive = isShowMore;

  return (
    <>
      <article
        className={classNames("package-card-v2", {
          "is-active": memberShipIsActive,
          "is-mobile": isMiddleScreen,
        })}
        style={{
          scrollMarginTop: membershipCardOffset,
          cursor: !isShowMore ? "pointer" : "default",
        }}
        onClick={!isShowMore ? handleShowMoreInfo : () => { }}
      >
        <Box className={`package-card-v2-inside ${isMobile ? "mobile" : ""}`}>
          {renderDesktopView()}
        </Box>
        {!isMobile ? (
          renderActiveView()
        ) : (
          <SwipeableBottomDrawer open={isShowMore} onClose={handleShowMoreInfo}>
            {renderActiveView()}
          </SwipeableBottomDrawer>
        )}
      </article>
      {(error && selectedPlan?.id === plan.id) ? <NoAvailiableGuests noSpace text={error} /> : null}
    </>
  );
};

export default connector(MemberShipCard);
