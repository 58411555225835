import React, { ReactNode, useState } from 'react'
import { useViewport } from '../../../hooks/responsive'
import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import MastercardIcon from '../../../../assets/payment/mastercard.svgr'
import VisaIcon from '../../../../assets/payment/visa.svgr'
import AmericanExpressIcon from '../../../../assets/payment/american-express.svgr'
import DiscoverIcon from '../../../../assets/payment/discover.svgr'

interface Props {
    children: JSX.Element
    show: boolean;
    cardNumbers: {
        cardEndsWith: string;
        cardType: string;
        id: string
    }[];
    selectedCard: string | null;
    setSelectedCard: (method: string | null) => void;
}

const getCardIcon = (cardType: string) => {
    switch (cardType) {
        case 'visa':
            return <VisaIcon />;
        case 'mastercard':
            return <MastercardIcon />;
        case 'american_express':
            return <AmericanExpressIcon />;
        case 'discover':
            return <DiscoverIcon />;
        default:
            return null;
    }
};


const StripeManageCards = ({ children, show, cardNumbers, selectedCard, setSelectedCard }: Props): JSX.Element | null => {
    const { isMobile } = useViewport()
    const [isContentDisplaying, setIsContentDisplaying] = useState(false)


    const handleCardSelect = (cardEndsWith: string) => {
        setSelectedCard(cardEndsWith);
        if (isContentDisplaying) {
            setIsContentDisplaying(false);
        }
    };
    const handleContentDisplaying = () => {
        setIsContentDisplaying(!isContentDisplaying)
        setSelectedCard(null)
    }
    if (!show) return children

    return (
        <Box>
            <Box mb={'16px'}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={'16px'}>
                    <Typography className={isMobile ? 'heading-h1' : 'heading-h5'}>Pay with saved card</Typography>
                </Box>
                <Box mb={'40px'}>
                    <RadioGroup
                        value={selectedCard}
                        onChange={(event) => handleCardSelect(event.target.value)}
                    >
                        {cardNumbers.map((card) => (
                            <FormControlLabel
                                key={card.id}
                                control={<Radio color='default' value={card.id} style={{
                                    color: '#212B36'
                                }} />}
                                label={
                                    <Box display={'flex'} style={{ gap: '12px' }}>
                                        {getCardIcon(card.cardType)}
                                        <Typography className='body-big'>•••• •••• •••• {card.cardEndsWith}</Typography>
                                    </Box>
                                }
                                style={{ marginBottom: '16px', borderBottom: '1px solid rgba(0, 0, 0, 0.10)', paddingBottom: '16px' }}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            </Box>
            <Box mb={'32px'}>

                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={'16px'}>
                    <Typography className={isMobile ? 'heading-h1' : 'heading-h5'}>Pay with new card</Typography>
                </Box>
                <FormControlLabel
                    control={
                        <Radio
                            checked={isContentDisplaying}
                            onChange={handleContentDisplaying}
                            color='default'
                            style={{
                                color: '#212B36'
                            }}
                        />
                    }
                    label={<Box display={'flex'} style={{ gap: '8px' }}>
                        <VisaIcon />
                        <MastercardIcon />
                        <AmericanExpressIcon />
                        <DiscoverIcon />
                    </Box>}
                />
            </Box>
            {isContentDisplaying ? children : null}

        </Box>
    )
}

export default StripeManageCards