import { ProfileDto, Guest } from "../../../store/types";

export type Payments = any;
export type Card = any;
export type CashAppPay = any;
export type AfterpayClearpay = any;
export type ChargeVerifyBuyerDetails = any;
export type StoreVerifyBuyerDetails = any;
export type CashAppPayButtonOptions = any;
export type AfterpayButtonOptions = any;
export type BillingContact = any;

export async function initializeCard(
    ref: React.MutableRefObject<HTMLDivElement | null>,
    payments: Payments
) {
    const card = await payments.card();
    if (ref.current) {
        await card.attach(ref.current);
    }
    return card;
}

export function buildPaymentRequest(payments: Payments, amount: string) {
    const paymentRequest = payments.paymentRequest({
        countryCode: "US",
        currencyCode: "USD",
        total: {
            amount,
            label: "Total",
        },
    });
    return paymentRequest;
}

type InitializeCashAppArgs = {
    ref: React.MutableRefObject<HTMLDivElement | null>;
    payments: Payments;
    redirectURL: string;
    amount: string;
};

export async function initializeCashApp({
    payments,
    redirectURL,
    ref,
    amount,
}: InitializeCashAppArgs) {
    const paymentRequest = buildPaymentRequest(payments, amount);
    const cashAppPay = await payments.cashAppPay(paymentRequest, { redirectURL });
    const buttonOptions: CashAppPayButtonOptions = {
        shape: "semiround",
        width: "full",
    };

    if (ref.current) {
        await cashAppPay.attach(ref.current, buttonOptions);
    }
    return cashAppPay;
}

type InitializeAfterpayArgs = {
    selector: string;
    payments: Payments;
    amount: string;
    useCustomButton?: boolean;
};

export async function initializeAfterpay({
    amount,
    payments,
    selector,
    useCustomButton = false,
}: InitializeAfterpayArgs) {
    const paymentRequest = buildPaymentRequest(payments, amount);
    const afterpay = await payments.afterpayClearpay(paymentRequest);
    const buttonOptions: AfterpayButtonOptions = {
        useCustomButton,
    };
    await afterpay.attach(selector, buttonOptions);
    return afterpay;
}

// status is either SUCCESS or FAILURE;
export function displayPaymentResults(
    ref: React.MutableRefObject<HTMLDivElement | null>,
    status: "SUCCESS" | "FAILURE"
) {
    if (!ref.current) return;
    const statusContainer = ref.current;
    if (status === "SUCCESS") {
        statusContainer.classList.remove("is-failure");
        statusContainer.classList.add("is-success");
    } else {
        statusContainer.classList.remove("is-success");
        statusContainer.classList.add("is-failure");
    }

    statusContainer.style.visibility = "visible";
}

export async function tokenize(
    paymentMethod: Card | AfterpayClearpay
): Promise<string> {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK" && tokenResult.token) {
        return tokenResult.token;
    }

    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    if (!tokenResult.token) {
        errorMessage += ` and token result is undefined`;
    }
    if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
    }
    throw new Error(errorMessage);
}

export async function verifyBuyer(
    verificationDetails: ChargeVerifyBuyerDetails,
    payments: Payments,
    token: string
) {
    try {
        const verificationResults = await payments.verifyBuyer(
            token,
            verificationDetails
        );
        if (!verificationResults) {
            throw new Error("Verify Buyer failed");
        }

        return verificationResults.token;
    } catch (error) {
        console.warn(error);
        if (error instanceof Error) {
            throw new Error(error.message);
        }
        throw new Error("Verify Buyer Error");
    }
}

export function makeBillingContact(
    client?: ProfileDto,
    guest?: Guest
): BillingContact {
    return {
        givenName: client?.firstName || guest?.firstName,
        familyName: client?.lastName || guest?.lastName,
        email: client?.email || guest?.email,
        phone: client?.phone || guest?.phone,
    };
}