import { ButtonProps, makeStyles } from "@material-ui/core";
import React from "react";
import ButtonMain from "../../common/ButtonMain/ButtonMain";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "black",
    borderRadius: "4px",
    color: "white",
    letterSpacing: "-0.28px",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    width: "100%",
    textTransform: "none",
    height: "56px",
  },
  span: {
    marginRight: "8px",
  },
}));

const PaymentButton = ({ ...props }: ButtonProps) => {
  const classes = useStyles();
  return (
    <ButtonMain
      {...props}
      className={classes.button}
      style={{ backgroundColor: "black", borderColor: "black" }}
    >
      <span className={classes.span}>Pay with</span> {props.children}
    </ButtonMain>
  );
};

export default PaymentButton;
