import { Box } from '@material-ui/core'
import React from 'react'
import { useViewport } from '../../hooks/responsive'

interface Props {
    children: React.ReactNode

}

const MembershipContainer = ({ children }: Props) => {
    const { isMobile } = useViewport()

    return (
        <Box display={'flex'} flexDirection={'column'} style={{ gap: '28px' }} mt={'68px'} p={isMobile ? '10px 20px' : ''}>
            {children}
        </Box>
    )
}

export default MembershipContainer