interface ParsedUrlSearchQuery {
  [key: string]: string;
}
export const parseUrlQuery = (text?: string): ParsedUrlSearchQuery => {
  if (!text || text.length === 0) {
    return {};
  }
  const parsed = text.slice(1).split("&");
  const result = parsed.reduce((acc: ParsedUrlSearchQuery, item: string) => {
    const pair = item.split("=");
    acc[pair[0]] = decodeURIComponent(pair[1]);
    return acc;
  }, {});
  return result;
};

export const toUrlQuery = (data: { [key: string]: any }) => {
    const pairs = Object.keys(data).map(key=>`${key}=${encodeURIComponent(data[key])}`);
    return pairs.join('&')
};

export const parseUrlHash = (data?: string) => {
  if (!data || data.length === 0) {
    return '';
  }
  return decodeURIComponent(data);
};

export const toUrlHash = (data: string) => {
  return encodeURIComponent(data)
};
