import React, { FC, ReactNode } from 'react'
import { Box, Typography } from '@material-ui/core';

interface Props {
    icon: ReactNode,
    title: string,
    withoutMargin?: boolean,
    wrap?: boolean
}

const OrderSummaryTag: FC<Props> = ({ icon, title, withoutMargin, wrap }) => (
    <Box className="order-summary-tag" style={{ marginBottom: withoutMargin ? '0' : '8px' }}>
        {icon}
        <Typography variant="subtitle2" className="order-summary-subTitle-alt without-margin" style={wrap ? {} : { whiteSpace: 'nowrap' }}>
            {title}
        </Typography>
    </Box>
)

export default OrderSummaryTag