import { getLocation, getSearch, goBack } from "connected-react-router";
import { mergeStyleSets } from "office-ui-fabric-react";
import React, { useEffect, useRef } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { pushUrlPathAction } from "../../actions/reservation-actions";
import { backToPackagesAction, backToReservationAction } from "../../actions/venue-actions";
import { useViewport } from "../../hooks/responsive";
import { selectAuthenticated, selectGuest } from "../../reducers/auth-reducer";
import {
    selectPaymentCompleted,
    selectReservation,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { selectCurrentPackage, selectVenue } from "../../reducers/venues";
import { STEP, State } from "../../store/types";
import Footer from "../common/Footer/Footer";
import Header from "../common/Header/Header";
import MultiStep from "../common/MultiStep/MultiStep";
import Account from "./Account/Account";
import Addons from "./Addons/Addons";
import Confirmation from "./Confirmation/Confirmation";
import Payment from "./Payment/Payment";
import Reservation from "./Reservation/Reservation";
import "./reservationInfo.scss";

const mapDispatchToProps = {
    pushUrlPath: pushUrlPathAction,
    goBack,
    backToReservation: backToReservationAction,
    backToPackages: backToPackagesAction,
};

const mapStateToProps = (state: State) => ({
    reservation: selectReservation(state),
    authenticated: selectAuthenticated(state),
    paymentCompleted: selectPaymentCompleted(state),
    loc: getLocation(state),
    uiConfig: selectUIConfig(state),
    guest: selectGuest(state),
    searchParams: getSearch(state),
    currentPackage: selectCurrentPackage(state),
    activeVenue: selectVenue(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const ReservationInfo = ({
    pushUrlPath,
    goBack,
    reservation,
    authenticated,
    paymentCompleted,
    backToReservation,
    loc,
    uiConfig,
    guest,
    searchParams,
    currentPackage,
    activeVenue,
    backToPackages
}: Props) => {
    const { isMobile, isTabletScreen } = useViewport();
    const top = useRef<HTMLDivElement>(null);
    const diffStep = activeVenue && activeVenue?.addons.length > 0 ? 0 : 1;
    const getActiveStep = (pathname: string) => {
        console.log("-activeStep-", paymentCompleted, pathname, authenticated);
        if (pathname.includes("/addons") && !diffStep) {
            return STEP.ADDONS;
        } else if (!(authenticated || guest) || pathname.includes("/account")) {
            return STEP.ACCOUNT;
        } else if (pathname.includes("/reservation-confirm")) {
            return STEP.RESERVATION - diffStep;
        } else if (!paymentCompleted && pathname.includes("/payment")) {
            return STEP.PAYMENT - diffStep;
        } else if (
            paymentCompleted &&
            (pathname.includes("/confirmed") || pathname.includes("/payment"))
        ) {
            return STEP.CONFIRMATION - diffStep;
        } else {
            return STEP.INIT;
        }
    };
    const activeStep = getActiveStep(loc.pathname);
    console.log(loc, 'loc')
    useEffect(() => {
        console.log("-activeStep-", activeStep, reservation);
        if (activeStep === STEP.INIT || !reservation.venueId && !loc.pathname.includes('/reservation-info/account') && !loc.search.includes('session_id')) {
            const isPackageReservationMode = !!currentPackage || uiConfig?.isPackageReservationMode;
            pushUrlPath(isPackageReservationMode ? "/package" : "/reservation");
            return;
        }
    }, [pushUrlPath, activeStep]);
    useEffect(() => {
        if (top?.current) {
            window.scrollTo(0, top.current.offsetTop);
        }
    }, [top, activeStep]);
    const handler = (step: STEP) => () => {
        if (step === STEP.ACCOUNT) {
            return pushUrlPath("/account");
        } else if (step === STEP.ADDONS && !diffStep) {
            return pushUrlPath("/reservation-info/addons", {
                venue: reservation.venueName,
            });
        } else if (step === STEP.RESERVATION - diffStep) {
            return pushUrlPath("/reservation-info/reservation-confirm", {
                venue: reservation.venueName,
            });
        } else {
            return backToReservation();
        }
    };
    let steps = [
        { label: STEP.INIT, name: "Selection", handler: handler(STEP.INIT) },
        {
            label: STEP.ACCOUNT,
            name: "Account",
            handler: handler(STEP.ACCOUNT),
        },
        {
            label: STEP.RESERVATION - diffStep,
            name: "Details",
            handler: handler(STEP.RESERVATION - diffStep),
        },
        {
            label: STEP.PAYMENT - diffStep,
            name: "Payment",
            handler: handler(STEP.INIT),
        },
        {
            label: STEP.CONFIRMATION - diffStep,
            name: "Confirmation",
            handler: handler(STEP.INIT),
        },
    ];
    if (!diffStep) {
        steps = [
            { label: STEP.INIT, name: "Selection", handler: handler(STEP.INIT) },
            {
                label: STEP.ADDONS,
                name: "Add Ons",
                handler: handler(STEP.ADDONS),
            },
            {
                label: STEP.ACCOUNT,
                name: "Account",
                handler: handler(STEP.ACCOUNT),
            },
            {
                label: STEP.RESERVATION,
                name: "Details",
                handler: handler(STEP.RESERVATION),
            },
            {
                label: STEP.PAYMENT,
                name: "Payment",
                handler: handler(STEP.INIT),
            },
            {
                label: STEP.CONFIRMATION,
                name: "Confirmation",
                handler: handler(STEP.INIT),
            },
        ];
    }
    const handleBackButton = () => {
        if (!!diffStep && activeStep === STEP.RESERVATION - diffStep) {
            // if (lastLocation?.pathname.includes("/account")) {
            //   return push("/account");
            // } else {
            return backToReservation();
            // }
        } else if (activeStep === STEP.CONFIRMATION - diffStep) {
            console.log('confirmation to packages')
            return backToPackages();
        } else {
            return goBack();
        }
    };
    const theme = mergeStyleSets({
        backgroundColor: {
            background: isMobile ? 'white' : uiConfig?.bodyBackgroundColor,
        },
    });
    const showReservation = !loc.pathname.includes('account');
    return (
        <div className={`reservationInfo-container ${theme.backgroundColor}`} ref={top}>
            <Header showReservation={showReservation} handleBackButton={handleBackButton} />
            <div className={`reservationInfo-inner-container ${isMobile ? 'mobile' : ''}`}>
                {!isMobile && !isTabletScreen && showReservation && (<MultiStep activeStep={activeStep} steps={steps} />)}
                <Switch>
                    <Route exact path="/reservation-info/account" component={Account} />
                    <Route
                        exact
                        path="/reservation-info/addons"
                        component={Addons}
                    />
                    <Route
                        exact
                        path="/reservation-info/reservation-confirm"
                        component={Reservation}
                    />
                    <Route exact path="/reservation-info/payment" component={Payment} />
                    <Route
                        exact
                        path="/reservation-info/confirmed"
                        component={Confirmation}
                    />
                    {uiConfig?.isPackageReservationMode ? (
                        <Redirect to="/package" />
                    ) : (
                        <Redirect to="/reservation" />
                    )}
                </Switch>
            </div >
            <Footer className={isMobile ? 'mobile-footer' : ''} />
        </div >
    );
};

export default connector(ReservationInfo);
