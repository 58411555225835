import { ActionTypeEnum, AppThunk } from ".";
import { get } from "./api";
import { STEP, UIConfigDto } from "../store/types";
import { selectInfoState } from "../reducers/ui-reducer";
import { confirmReservationAction, pushUrlPathAction } from "./reservation-actions";
import { backToReservationAction } from "./venue-actions";

const UI_CONFIG_URL = "/api/ui-config";

export const setPrevInfoStateAction = (prev?: STEP): AppThunk => {
  return (dispatch, getState) => {
    const current = selectInfoState(getState());
    let next = prev;
    if (!prev) {
      next = STEP.INIT;
      if (current === STEP.RESERVATION) {
        next = STEP.ACCOUNT;
      } else if (current === STEP.PAYMENT) {
        next = STEP.RESERVATION;
      } else {
        next = STEP.INIT;
      }
    }
    if (next === STEP.INIT) {
      dispatch(backToReservationAction());
    } else if (next === STEP.ACCOUNT) {
      dispatch(confirmReservationAction(false));
      // dispatch(push("/account"));
    } else if (next === STEP.RESERVATION) {
      dispatch(confirmReservationAction(false));
      dispatch(pushUrlPathAction("/reservation-info"));
    }

    dispatch({ type: ActionTypeEnum.SetInfoState, payload: next });
  };
};

export const getUIConfigAction = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.GetUIConfig });
      const response = await get(UI_CONFIG_URL);
      const config: UIConfigDto = response.data;

      const fav = document.querySelector("head link[rel~='icon']");
      if (fav && config.homeFavicon) {
        (fav as any).href = config.homeFavicon;
      }
      const title = document.querySelector("head title");
      if (title && config.htmlTitle) {
        title.textContent = config.htmlTitle;
      }

      dispatch({
        type: ActionTypeEnum.GetUIConfigSuccess,
        payload: config,
      });
    } catch (e) {
      console.log("config error", e);
      dispatch({
        type: ActionTypeEnum.GetUIConfigFailure,
        payload: "Sorry. Something goes wrong, we'll fix it soon.",
      });
    }
  };
};
