import { Box, Typography } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';
import { maxTypographyStyle } from './utils';
interface Props {
    title: string,
    price: string,
    subTitle?: string,
    subTitleAdditional?: string,
    subTitleBottom?: string | ReactNode,
    withoutSub?: boolean,
    withoutBorder?: boolean
}



const OrderSummaryCard: FC<Props> = ({ title, subTitle, price, subTitleAdditional, subTitleBottom, withoutSub, withoutBorder }) => {


    return (
        <Box className='order-summary-card' style={{ borderBottom: withoutBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.05)' }}>
            <Box className='order-summary-card-header'>
                <Typography variant="subtitle2" className="order-summary-subTitle-alt without-margin" style={maxTypographyStyle}>
                    {title}
                </Typography>
                <Typography variant="subtitle2" className="order-summary-price" style={maxTypographyStyle}>
                    {price}
                </Typography>
            </Box>
            {!withoutSub && <Typography variant="subtitle2" className="order-summary-desc" style={maxTypographyStyle}>
                {subTitle} <br />
                {subTitleAdditional}
                {subTitleBottom && <br />}
                {subTitleBottom}

            </Typography>}
        </Box>)
}

export default OrderSummaryCard