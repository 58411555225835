import { Box, Button, Tab, Tabs } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getHash, getSearch, goBack, replace } from "connected-react-router";
import { maxBy } from "lodash";
import {
    IDropdownOption,
    Spinner,
    SpinnerSize
} from "office-ui-fabric-react";
import React, { useEffect, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import defaultImage from '../../../assets/default-image.jpg';
import {
    resetReservationAction,
    setReservationErrorAction,
    startBookingAction,
    updateReservationAction,
} from "../../actions/reservation-actions";
import {
    changeVenuePackageAction,
    changeVenuePackageWithReservationAction,
    parseHash,
    setCurrentPackageAction,
    toCustomTabNewDesignAction,
    toPackagesWithVenueNewDesignAction,
    toPartyWithVenueAction,
    toReservationWithVenueAction
} from "../../actions/venue-actions";
import { useViewport } from "../../hooks/responsive";
import useSettingColors from "../../hooks/useSettingColors";
import {
    selectIsUpdateReservation,
    selectRequestError,
    selectReservation,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import {
    selectAvailability,
    selectCurrentPackage,
    selectIsLoadingAvailability,
    selectIsLoadingVenue,
    selectIsLoadingVenues,
    selectVenue,
    selectVenues,
} from "../../reducers/venues";
import { PackageName, State } from "../../store/types";
import { getVenueImages } from "../../utils/venue";
import ContactUs from "../MakePackageReservation/ContactUs";
import GalleryModal from "../MakeReservation/GalleryModal";
import MakeReservationSummary from "../MakeReservation/MakeReservationSummary";
import PlanningEvent from "../MakeReservation/PlanningEvent";
import Footer from "../common/Footer/Footer";
import Header from "../common/Header/Header";
import './makePartyReservation.scss';
const mapDispatchToProps = {
    changeVenuePackage: changeVenuePackageAction,
    resetReservation: resetReservationAction,
    updateReservation: updateReservationAction,
    startBooking: startBookingAction,
    toPartyWithVenue: toPartyWithVenueAction,
    setReservationError: setReservationErrorAction,
    toReservationWithVenue: toReservationWithVenueAction,
    setCurrentPackage: setCurrentPackageAction,
    goBack,
    replace,
    changeVenuePackageWithReservation: changeVenuePackageWithReservationAction,
    toPackagesWithVenue: toPackagesWithVenueNewDesignAction,
    toCustomTab: toCustomTabNewDesignAction
};

const mapStateToProps = (state: State) => ({
    isLoadingVenues: selectIsLoadingVenues(state),
    isLoadingVenue: selectIsLoadingVenue(state),
    isLoadingAvailability: selectIsLoadingAvailability(state),
    activeVenue: selectVenue(state),
    venues: selectVenues(state),
    availability: selectAvailability(state),
    reservation: selectReservation(state),
    searchParams: getSearch(state),
    requestError: selectRequestError(state),
    uiConfig: selectUIConfig(state),
    currentPackage: selectCurrentPackage(state),
    isUpdateReservation: selectIsUpdateReservation(state),
    hashParams: getHash(state),

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

export const getMenuPackagePropsGuests: (
    count: number,
    selectedPackages?: PackageName[]
) => IDropdownOption[] = (
    count: number,
    selectedPackages?: PackageName[]
) => {
        const MORE_THAN_11 = 100;

        const max = maxBy(
            selectedPackages,
            (selectedPackage) => selectedPackage.maxGuests
        )?.maxGuests;
        const maxGuests = max && max > count ? max : count;
        return Array.apply(null, Array(maxGuests + 1)).map((_, index) => ({
            key: index === maxGuests ? MORE_THAN_11 : index + 1,
            text:
                index === 0
                    ? `${index + 1} Guest`
                    : `${index + 1}${index === maxGuests ? "+" : ""} Guests`,
        }));
    };

const MakePartyReservation = ({
    activeVenue,
    isLoadingVenues,
    isLoadingVenue,
    isLoadingAvailability,
    uiConfig,
    goBack,
    currentPackage,
    toPackagesWithVenue,
    venues,
    changeVenuePackage,
    changeVenuePackageWithReservation,
    updateReservation,
    reservation,
    searchParams,
    hashParams,
    isUpdateReservation,
    toCustomTab
}: Props) => {
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const { bodyColor } = useSettingColors()

    const top = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (top?.current) {
            window.scrollTo(0, top.current.offsetTop);
        }
    }, [top]);
    const [tabValue, setTabValue] = React.useState(1);

    const handleChangeTabValue = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
        if (newValue === 0) returnToPackages()
        if (newValue === 2) returnToCustom()
    };

    const { isMobile, isTabletScreen } = useViewport();

    const returnToPackages = () => {
        toPackagesWithVenue();
    };
    const returnToCustom = () => {
        toCustomTab()
    };
    const { date, guests, venueId, packageId } = reservation;
    const { packagesTag, singlePackage } = parseHash(hashParams);

    const selectVenueOption = (newVenueId?: number | string) => {
        const newVenue = venues.find((venue) => venue.id == newVenueId);
        if (newVenueId && newVenue) {
            const recountedGuests = newVenue.maxGuests >= guests ? guests : 1;
            if (!!packagesTag) {
                replace({ search: searchParams });
            }
            if (isUpdateReservation) {
                updateReservation({ slots: undefined, packageId: undefined });
                changeVenuePackageWithReservation({
                    venueId: newVenueId.toString(),
                    date,
                    guests: recountedGuests,
                    updatePackage: undefined,
                });
            } else {
                changeVenuePackage({
                    updatePackage: undefined,
                    venueId: newVenueId.toString(),
                    date,
                    guests: recountedGuests,
                    changeVenueWithoutPackage: true,
                });
            }
        }
    };
    const images = getVenueImages(activeVenue);
    const isWithImages = images && images.length > 0;
    return (
        <>
            {(isLoadingVenues || isLoadingAvailability) && (
                <div className="loading">
                    <Spinner size={SpinnerSize.large} />
                </div>
            )}
            <div
                className="make-package-reservation-container main-container main-container-v2"
                style={{ backgroundColor: bodyColor }}
                ref={top}
            >
                <Header handleBackButton={goBack} />
                <div className="makePackageReservation-inner-container main-content main-content-v2">
                    <div
                        className={`makePackageReservation-main-information-v2 main-content main-content-v2 ${isMobile ? "mobile" : ""
                            }`}
                        style={isTabletScreen ? { paddingBottom: 0 } : {}}
                    >
                        <div className={`makePackageReservation-reservation makePackageReservation-reservation-v2 ${isTabletScreen ? 'tablet' : ''}`}>
                            {!isMobile ? <>
                                <div className="makePackageReservation-reservation-title-v2">
                                    {activeVenue?.eventInquiryTitle || "Contact Us"}
                                </div>
                                {activeVenue?.packagesDescription && <HTMLRenderer
                                    html={activeVenue?.descriptionParty}
                                    className="makePackageReservation-reservation-text-v2"
                                />}</> : <Box position={'relative'} height={'290px'}>
                                {((isLoadingVenues || isLoadingAvailability)) ? (
                                    <Skeleton
                                        variant="rect"
                                        width={'100%'}
                                        height={'290px'}
                                        style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', transform: 'none' }}
                                    />
                                ) : (
                                    <img
                                        className="makePackageReservation-reservation-logo-v2"
                                        src={activeVenue?.venueImage || defaultImage}
                                        alt="venueImage"
                                    />
                                )}
                                {isWithImages && <Button className="makePackageReservation-reservation-btn-view-all-v2 body-small" onClick={() => setIsGalleryOpen(true)}>
                                    View All ({images.length})
                                </Button>}
                            </Box>}

                            <Box className={`makePackageReservation-reservation-header ${isMobile ? "mobile no-shadow" : ""
                                }`}>

                                <Tabs
                                    value={tabValue}
                                    indicatorColor="primary"
                                    textColor="inherit"
                                    onChange={handleChangeTabValue}
                                    variant="fullWidth"
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#212B36",
                                            height: '3px',
                                            bottom: '-1px'
                                        }
                                    }}
                                    style={{
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.10)'
                                    }}
                                >
                                    <Tab label={activeVenue?.packagesTabName || "Reservations"} className="makePackageReservation-tab" />
                                    <Tab label={activeVenue?.eventInquiryTabName || "Contact Us"} className="makePackageReservation-tab" />
                                    {activeVenue?.customTabEnable && <Tab label={activeVenue?.customTabTabName || 'Custom Tab'} className="makePackageReservation-tab" />}

                                </Tabs>
                                <Box className={`makePackageReservation-reservation-header-inside ${isMobile ? 'mobile' : ''}`}>
                                    <ContactUs />

                                </Box>
                            </Box>
                            {isMobile && <PlanningEvent mb={'32px'} activeVenue={activeVenue} />}
                            {isTabletScreen && <PlanningEvent
                                mt={'32px'}
                                maxWidth={'100% !important'}
                                descriptionProps={{
                                    style: {
                                        maxWidth: '100%'
                                    }
                                }}
                                activeVenue={activeVenue} />}
                        </div>
                        {!isMobile && (
                            <MakeReservationSummary venue={activeVenue} venueNames={venues} selectVenueOption={selectVenueOption} />)}
                    </div>
                </div>

                <Footer className={isMobile ? "mobile-footer" : ""} />
            </div >

            <GalleryModal onOpen={() => setIsGalleryOpen(true)} venue={activeVenue} onClose={() => setIsGalleryOpen(false)} open={isGalleryOpen} />

        </>
    );
};

export default connector(MakePartyReservation);
