import { Box, Button, Chip, InputAdornment, TextField } from '@material-ui/core';
import React, { FormEvent, useState } from 'react';
import { ReservationDto } from '../../../../server/src/dto/reservation.dto';
import ArrowForwardIcon from '../../../assets/arrow-forward.svgr';
interface Props {
    error?: string;
    reservation: ReservationDto,
    applyCouponCode?: (couponCodes: string[]) => void;
    deposit: number;
    isSkipPayment?: boolean;
}

const CouponCodes = ({ error, reservation, applyCouponCode, deposit, isSkipPayment }: Props) => {
    const [couponValue, setCouponValue] = useState('')
    const [errorCoupons, setErrorCoupons] = useState('')

    const [isClicked, setIsClicked] = useState(false);

    const handleAddCoupon = (e: FormEvent) => {
        e.preventDefault()
        setErrorCoupons('')

        if (couponValue == 'error-code') {
            setErrorCoupons('Enter a valid discount code')
        }
        else if (couponValue !== '') {
            const coupons = reservation.coupons ? reservation.coupons.map(c => c.code) : []
            const allCoupons = [...coupons, couponValue]

            applyCouponCode && applyCouponCode(allCoupons)
            setCouponValue('')

            setIsClicked(false)
        }
    }
    const handleTextFieldClick = () => {
        setIsClicked(true);
    };

    const handleTextFieldBlur = () => {
        if (couponValue === '') {

            setIsClicked(false);
        }
    };

    const handleDeleteCoupon = (couponItem: string) => {

        const coupons = reservation.coupons ? reservation.coupons.filter(coupon => coupon.code !== couponItem).map(c => c.code) : []
        applyCouponCode && applyCouponCode(coupons)
    }

    return (
        <Box className="order-summary-discount">
            <form onSubmit={handleAddCoupon} className='order-summary-discount-form'>
                <TextField
                    label="Promo Code or Gift Card"
                    variant="outlined"
                    margin="none"
                    fullWidth
                    error={!!error}
                    helperText={error}
                    size='small'
                    focused={isClicked}
                    value={couponValue}
                    disabled={deposit === 0 || !!isSkipPayment}
                    onChange={(e) => setCouponValue(e.target.value)}
                    style={{
                        border: 'none',
                        transition: 'all 0.3s ease',
                    }}
                    FormHelperTextProps={{
                        style: {
                            marginLeft: 0
                        }
                    }}
                    InputProps={{
                        style: {
                            backgroundColor: isClicked ? '#F6F7FA' : '#1A1A1A0D',
                            color: '#707983',
                            border: 'none',
                            width: '100%',
                            transition: 'width 0.15s ease',
                        },
                        onClick: handleTextFieldClick,
                        onBlur: handleTextFieldBlur,
                        endAdornment: (
                            <InputAdornment position="end" style={{ opacity: isClicked ? 1 : 0, transition: 'opacity 0.15s ease' }}>
                                <Button disabled={couponValue === ''} type='submit' onClick={() => setIsClicked(false)} style={{ background: !!error ? '#f44336' : couponValue !== '' ? '#3f51b5' : '#e0e0e0', padding: '3px', minWidth: 0 }}>
                                    <ArrowForwardIcon />
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />

                <Box display={'flex'} style={{ gap: '12px', flexWrap: 'wrap' }} marginTop={'12px'}>

                    {reservation.coupons?.map((coupon) => {
                        return <Chip key={coupon.code} size='small' style={{ borderRadius: '4px' }} label={coupon.name} onDelete={() => handleDeleteCoupon(coupon.code)} />
                    })}
                </Box>

            </form>
        </Box>
    )
}

export default CouponCodes