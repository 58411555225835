import { Box, Button, SwipeableDrawerProps } from '@material-ui/core'
import React from 'react'
import CloseIcon from '../../../assets/close.svgr'
import { useViewport } from '../../hooks/responsive'
import { Venue } from '../../store/types'
import SwipeableBottomDrawer from '../common/SwipeableDrawer/SwipeableBottomDrawer'
import { getVenueImages } from '../../utils/venue'
interface Props extends SwipeableDrawerProps {
    venue?: Venue,
    onClose: () => void,
    onShare?: () => void
}
const GalleryModal = ({ venue, onClose, onShare, ...props }: Props) => {
    const images = getVenueImages(venue);
    const { isMobile } = useViewport()
    return (
        <SwipeableBottomDrawer onClose={onClose} {...props}>
            <Box bgcolor={'white'} width={'100%'} height={'100%'} className={`gallery-modal ${isMobile ? 'mobile' : ''}`}>

                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'16px'}>
                    <Button className={`gallery-btn ${isMobile ? 'mobile' : ''}`} onClick={onClose}>
                        <CloseIcon />
                    </Button>
                    {/* <Button onClick={onShare}>
                        <ShareIcon />
                        <Typography className='gallery-btn-text'>Share</Typography>
                    </Button> */}
                </Box>

                {isMobile ? 
                    <Box display='flex' justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                        {images?.map((image) => (<img key={image} src={image} alt={venue?.name} className={`gallery-img ${isMobile ? 'mobile' : ''}`} />))}
                    </Box> :
                    <Box display='flex' justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gridGap={15}>
                        {images.map((imageUrl, i) => (
                            <img key={`${imageUrl}-${i}`} src={imageUrl} alt={venue?.name} className='gallery-img' />
                        ))}
                    </Box>}
            </Box>
        </SwipeableBottomDrawer>
    )
}

export default GalleryModal