import { IDropdownOption } from "office-ui-fabric-react";
import { VenueName } from "../store/types";
import { parseUrlQuery } from "./urlSearchQuery";

export const getVenueList = (venues: VenueName[], searchParams: string) => {
    const { venue } = parseUrlQuery(searchParams);
    const openedVenues = venues && venues.length > 0 ? venues.filter(item => !item.isComingSoon || item.name === venue) : [];
    const menuPropsLocations: IDropdownOption[] = openedVenues.length > 0 ? openedVenues.map(({ id, name }: { id: string, name: string }) => ({
      key: id,
      text: name,
    })) : [];
    return menuPropsLocations;
}