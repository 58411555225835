import { BoxProps, makeStyles } from '@material-ui/core';
import React from 'react';
import HtmlRenderer from '../../../../common/components/HTMLRenderer/HTMLRenderer';
import AppBox from './AppBox';

interface Props extends BoxProps {
    description: string;
    title?: string;
}



const useStyles = makeStyles((theme) => ({
    details: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
        color: '#3F4852'
    }
}));

const AboutVenue = ({ description, title, ...props }: Props) => {
    const classes = useStyles();

    return (
        <AppBox title={title || 'About Our Venue'} {...props} headerStyles={{ marginBottom: '8px' }}>
            <HtmlRenderer html={description} className={classes.details} />
        </AppBox>
    )
}

export default AboutVenue