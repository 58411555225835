import beer from '../assets/confirmation-icons/Beer.svg'
import bowling from '../assets/confirmation-icons/Bowling.svg'
import calendarConfirmed from '../assets/confirmation-icons/Calendar-Confirmed.svg'
import celebrate from '../assets/confirmation-icons/Celebrate.svg'
import cocktail from '../assets/confirmation-icons/Cocktail.svg'
import golfFlag from '../assets/confirmation-icons/Golf-Flag.svg'
import guestConfirmed from '../assets/confirmation-icons/Guest-Confirmed.svg'
import pinConfirmed from '../assets/confirmation-icons/Pin-Confirmed.svg'
import raceFlag from '../assets/confirmation-icons/Race-Flag.svg'
import racket from '../assets/confirmation-icons/Racket.svg'
import success from '../assets/confirmation-icons/Success.svg'
import thumbsUp from '../assets/confirmation-icons/Thumbs-Up.svg'
import ticket from '../assets/confirmation-icons/Ticket.svg'

export const confirmationPageIcons = {
  Default: "Default",
  beer: beer,
  bowling: bowling,
  calendarConfirmed: calendarConfirmed,
  celebrate: celebrate,
  cocktail: cocktail,
  golfFlag: golfFlag,
  guestConfirmed: guestConfirmed,
  pinConfirmed: pinConfirmed,
  raceFlag: raceFlag,
  racket: racket,
  success: success,
  thumbsUp: thumbsUp,
  ticket: ticket,
}
