import React, { useState, useEffect, useRef } from "react";
import { GiftCardBalanceDto, GiftCardBalanceRequestDto, GiftCardPaymentRequestDto } from "../../../server/src/dto/giftCard.dto";
import { InputField } from "../InputField/InputField";
import ConfirmGiftCard from "../ConfirmGiftCard";
import './giftCard.scss';
import GiftCardIcon from "../../assets/gift-card.svgr"

interface Props {
    isMobile: boolean;
    deposit: number;
    giftBalance?: GiftCardBalanceDto;
    getGiftCardBalance: (cardParams: GiftCardBalanceRequestDto) => void;
    giftBalanceError?: string;
    addGiftCard: (cardParams: GiftCardPaymentRequestDto) => void;
    giftCardAmount?: number;
    isSkipPayment?: boolean;
}

const GiftCard = ({
    isMobile,
    deposit,
    giftBalance,
    getGiftCardBalance,
    giftBalanceError,
    addGiftCard,
    giftCardAmount,
    isSkipPayment,
}: Props) => {
    const isGettingCardBalance = useRef(false);
    useEffect(() => {
        if (giftBalance && isGettingCardBalance.current) {
            isGettingCardBalance.current = false;
            setIsOpenGiftCardModal(true);
        }
    }, [giftBalance]);

    const [giftCardNumber, setGiftCardNumber] = useState<string>("");
    const [giftCardPin, setGiftCardPin] = useState<string>("");
    const [isOpenGiftCardModal, setIsOpenGiftCardModal] = useState<boolean>(false);
    const [showPinInput, setShowPinInput] = useState<boolean>(false);
    const [promoCodeApplied, setPromoCodeApplied] = useState<boolean>(false);
    const [errorGiftCard, setErrorGiftCard] = useState<string>("");

    const onClearErrors = () => {
        setErrorGiftCard("");
    }

    const onChangeGiftCardNumber = (value: string) => {
        if (!!errorGiftCard) {
            onClearErrors();
        }
        setGiftCardNumber(value);
    }
    const onChangeGiftCardPin = (value: string) => {
        if (!!errorGiftCard) {
            onClearErrors();
        }
        setGiftCardPin(value);
    }
    const onCloseModal = (giftCardTotal?: number) => {
        if (!!giftCardTotal && giftCardTotal > 0) {
            const newGiftCardParams = {
                cardNumber: giftCardNumber,
                pin: giftCardPin,
                amount: giftCardTotal,
            }
            addGiftCard(newGiftCardParams);
        }
        setIsOpenGiftCardModal(false);
    }

    const onApplyCard = () => {
        onClearErrors();
        if (!giftCardNumber) {
            setErrorGiftCard("Card number is required");
        } else if (isNaN(Number(giftCardNumber)) || giftCardNumber.length !== 19) {
            setErrorGiftCard("Please enter a valid card number");
        } else if (!giftCardPin) {
            setErrorGiftCard("Card pin is required");
        } else if (isNaN(Number(giftCardPin))) {
            setErrorGiftCard("Please enter a valid card PIN");
        }
        if (
            !(isNaN(Number(giftCardNumber)) || giftCardNumber.length !== 19) &&
            !(isNaN(Number(giftCardPin))) &&
            !!giftCardNumber &&
            !!giftCardPin
        ) {
            isGettingCardBalance.current = true;
            getGiftCardBalance({ cardNumber: giftCardNumber, pin: giftCardPin });
        }
    }

    const onClickApplyNumber = () => {
      if (!giftCardNumber || isNaN(Number(giftCardNumber))) {
        setErrorGiftCard("Please enter a valid card number");
        return
      }

      if (giftCardNumber.length < 19) {
        //attempt promo code
      } else {
        setShowPinInput(true);
      }
    }

    const onClickApplyPin = () => {
      if(!giftCardPin || isNaN(Number(giftCardPin))){
        setErrorGiftCard("Please enter a valid card PIN");
        return
      }

      isGettingCardBalance.current = true;
      getGiftCardBalance({ cardNumber: giftCardNumber, pin: giftCardPin });
    }


    return (
        <>
            <div className={`gift-card-form ${isMobile ? "mobile" : ""}`}>
                {
                  promoCodeApplied ? (
                    <div className="promo-code-applied">
                      <span className="promo-code-applied-text">{giftCardNumber} Applied</span>
                      <GiftCardIcon/>
                    </div>
                  ) : (
                    <div className={`gift-card-info-container ${isMobile ? "mobile" : ""}`}>
                      <div className="gift-card-info-fields">
                        {
                          !showPinInput ? (
                            <InputField
                              placeholder='Gift Card or Promo Code'
                              name='giftCardNumber'
                              className="gift-card-field card-number"
                              value={giftCardNumber || ""}
                              onChange={(_: any, text?: string) => onChangeGiftCardNumber(text || "")}
                              autoComplete={"off"}
                              disabled={deposit === 0 || !!isSkipPayment}
                              errorMessage={giftBalanceError || errorGiftCard}
                            />
                          ) : (
                            <InputField
                              placeholder='PIN'
                              name='giftCardPin'
                              className="gift-card-field card-pin"
                              value={giftCardPin || ""}
                              onChange={(_: any, text?: string) => onChangeGiftCardPin(text || "")}
                              autoComplete={"off"}
                              disabled={deposit === 0 || !!isSkipPayment}
                              errorMessage={giftBalanceError || errorGiftCard}
                            />
                          )
                        }
                      </div>
                      {
                        showPinInput ? (
                          <button
                            className="gift-card-button"
                            onClick={onClickApplyNumber}
                            disabled={showPinInput ? !giftCardPin : !giftCardNumber}
                          >
                            APPLY
                          </button>
                        ) : (
                          <button
                            className="gift-card-button"
                            onClick={onClickApplyPin}
                            disabled={showPinInput ? !giftCardPin : !giftCardNumber}
                          >
                            APPLY
                          </button>
                        )
                      }
                    </div>
                  )
                }
                {giftCardAmount && giftCardAmount > 0 ? (
                    <div className="gift-cards-amount-container">
                        <div className="gift-cards-amount">${giftCardAmount.toFixed(2)} GIFT CARD</div>
                    </div>
                ) : null}
            </div>
            {isOpenGiftCardModal && <ConfirmGiftCard
                onClose={(giftCardTotal?: number) => {
                    onCloseModal(giftCardTotal);
                }}
                deposit={deposit}
                giftBalance={giftBalance}
            />}
        </>
    );
};

export default GiftCard;