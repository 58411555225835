import React from 'react';
import { PrimaryButton, Props as PrimaryButtonProps } from "../../../../common/components/PrimaryButton/PrimaryButton";
import { UIConfigDto } from "../../store/types";
import './index.scss';

type Props = {
  uiConfig?: UIConfigDto;
} & PrimaryButtonProps;

const MobileFixedButton = (
  {
    uiConfig,
    children,
    ...props
  }: Props
) => {
  return (
    <div className='mobile-continue'>
      <PrimaryButton uiConfig={uiConfig} {...props}>
        {children}
      </PrimaryButton>
    </div>
  );
};

export const mobileFixedStyle = {
  position: 'fixed',
  bottom: '0',
  width: '100%',
  zIndex: 100,
  padding: '16px',
  backgroundColor: 'white',
  boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export default MobileFixedButton;