import React from 'react';
import './index.scss';
import warningIcon from '../../assets/alert/warning.svg';
import infoIcon from '../../assets/alert/info.svg';
import errorIcon from '../../assets/alert/error.svg';
import successIcon from '../../assets/alert/success.svg';
import closeIcon from '../../assets/alert/close.svg';

interface Props {
  type: 'success' | 'error' | 'warning' | 'info',
  title?: string,
  text: string,
  canClose?: boolean,
  onClose?: () => void,
  className?: string
}

const icons = {
  warning: warningIcon,
  info: infoIcon,
  error: errorIcon,
  success: successIcon
}

const Alert = (
  {
    type,
    title,
    text,
    canClose,
    onClose,
    className = ''
  } : Props
) => {
  return (
    <section className={`alert ${type} ${className} ${title ? '' : 'is-center-aligned'}`}>
      <img src={icons[type]} alt={type} className='alert-icon'/>
      <div className={`alert-body ${type}`}>
        {
          title && (
            <p className='alert-title'>
              {title}
            </p>
          )
        }
        <p className='alert-content'>
          {text}
        </p>
      </div>
      {
        canClose && (
          <button onClick={onClose} className='alert-close-btn'>
            <img src={closeIcon} alt="Close Alert"/>
          </button>
        )
      }
    </section>
  );
};

export const SuccessAlert = (props: Omit<Props, 'type'>) => <Alert type='success' {...props}/>
export const WarningAlert = (props: Omit<Props, 'type'>) => <Alert type='warning' {...props}/>
export const ErrorAlert = (props: Omit<Props, 'type'>) => <Alert type='error' {...props}/>
export const InfoAlert = (props: Omit<Props, 'type'>) => <Alert type='info' {...props}/>
