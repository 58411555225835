import Backdrop, { BackdropProps } from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'


const Loader = ({ ...props }: BackdropProps) => {
    return (
        <Backdrop style={{
            color: '#fff',
            zIndex: 2,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            borderRadius: '8px'
        }} {...props}>
            <CircularProgress style={{ color: '#212B36' }} />
        </Backdrop>
    )
}

export default Loader