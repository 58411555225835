import { AddonType, DepositPercentageType, Pricing } from "../entities/enums";
import { AddonModifierDto } from "./addonModifier.dto";
import { ReservationAddonOptionDto } from "./reservationAddonOption.dto";

export class ReservationAddonDto {
  id?: string;
  addonId: string;
  name: string;
  quantity: number;
  price: number;
  method: Pricing;
  addonType: AddonType;
  depositPercentage: DepositPercentageType;
  notes: string;
  modifiers?: AddonModifierDto[];
  options?: ReservationAddonOptionDto[]
}
