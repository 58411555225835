import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../../common/constants/timedate";
import { Reservation } from "../../../store/types";

export const getReservationTag = (reservation: Reservation) => {
    const venueDate = new Date(
        new Date().toLocaleString("en-US", { timeZone: reservation.timeZone })
    );

    const now = dayjs(venueDate).format(DATE_FORMAT);
    const currentTimeSlot = Math.floor(
        (venueDate.getHours() * 60 + venueDate.getMinutes()) / reservation.timeSlotDuration
    );

    const isCompleted = reservation.date === now
        ? reservation.slots <= currentTimeSlot
        : dayjs(reservation.date).isBefore(dayjs(venueDate));

    const isCancelled = reservation.status === "cancelled" || reservation.status === "cancelledNoRefund";
    const isCompletedStatus = isCompleted && !isCancelled;
    const isComingUpStatus = !isCompleted && !isCancelled;
    if (isCancelled) {
        return 'Cancelled';
    }

    if (isCompletedStatus) {
        return 'Completed';
    }

    if (isComingUpStatus) {
        return 'Upcoming';
    }

    return '';
};

export const getSubscriptionTag = (active: boolean) => {
    return active ? 'Active' : 'Inactive';
}

