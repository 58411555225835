import { ProfileDto } from '../../../../server/src/dto/profile.dto';
export { ProfileDto } from '../../../../server/src/dto/profile.dto';

export interface NewClient {
  firstName: string;
  lastName: string;
  phone: string;
  confirmEmail?: string;
  email: string;
  password: string;
  subscribeToOffers?: boolean;
  continueReservation?: boolean;
}
export interface UpdateClient {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

export interface Client extends NewClient {
  id: string;
  verified?: boolean
}

export interface Guest extends NewGuest {
  id: string;
}
export interface NewGuest {
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  subscribeToOffers?: boolean;
}

export interface AuthState {
  isRequestInProgress: boolean;
  authenticated: boolean;
  isPasswordChanged: boolean;
  isRecoverCompleted: boolean;
  client?: ProfileDto;
  guest?: Guest;
  expiresAt?: number;
  error?: string;
  verified?: boolean;
  isShowVerificationCodeModal?: boolean;
}
