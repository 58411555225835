export class ProfileDto {
    id: string;
    token: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    subscribeToOffers?: boolean;
    isClient?: boolean;
    verified?: boolean;
    tags: string[];
    notes: string;
    subscriptions: any[];
    discounts: any[];
    packageIdsForDiscount: any[];
}
