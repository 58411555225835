import React, { useState } from 'react';

import { Box, Button, Link, Menu, MenuItem, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { formatPhone } from '../../../../common/utils/formats';
import CallIcon from '../../../assets/call.svgr';
import LocationOnIcon from '../../../assets/location_on.svgr';
import PanZoomIcon from '../../../assets/pan-zoom.svgr';
import RestaurantIcon from '../../../assets/restaurant.svgr';
import { useViewport } from '../../hooks/responsive';
import { State, Venue, VenueName } from '../../store/types';
import { getVenueImages } from '../../utils/venue';
import LinkIcons from '../LinkIcons/LinkIcons';
import { useSelectWithIconStyles } from '../common/SelectWithIcon/SelectWithIcon';
import { VenueAddress } from '../common/VenueAddress/VenueAddress';
import GalleryModal from './GalleryModal';
import LinkWithIcon from './LinkWithIcon';
import PlanningEvent from './PlanningEvent';
import './makeReservation.scss'
interface Props {
    venue?: Venue;
    multiVenue?: boolean;
    venueNames?: VenueName[];
    selectVenueOption: (newVenueId?: number | string) => void;
    withoutPlanning?: boolean;
}

const MakeReservationSummary = ({ venue, venueNames, selectVenueOption, withoutPlanning }: Props) => {
    const venuePhone = formatPhone(venue?.phone);
    const [isGalleryOpen, setIsGalleryOpen] = React.useState(false);
    const classes = useSelectWithIconStyles()
    const { isTabletScreen, isMobile } = useViewport()
    const [venueMenuAnchor, setVenueMenuAnchor] = useState(null);
    const [selectedVenue, setSelectedVenue] = useState<VenueName>();
    const uiConfig = useSelector((state: State) => state.uiState.uiConfig);
    const images = getVenueImages(venue);

    const mapRef = `${venue?.mapUrl}`;

    const handleOpenVenueMenu = (event: any) => {
        setVenueMenuAnchor(event.currentTarget);
    };

    const handleCloseVenueMenu = () => {
        setVenueMenuAnchor(null);
    };

    const handleChangeVenue = (venueName: VenueName) => {
        setSelectedVenue(venueName);
        selectVenueOption(venueName.id);
        handleCloseVenueMenu();
    };

    const venuesForChange = venueNames?.filter(venueInList => !venueInList.isComingSoon);
    const showDemo = uiConfig?.isDemo && !uiConfig.isHideDemoMessage;
    const isWithImages = images && images.length > 0

    const isDesktop = !isMobile;
    return (
        <>
            <Box className={`panel-delimiter ${isDesktop ? 'v2' : ''}`}></Box>
            <Box className='makeReservation-summary' style={isTabletScreen ? { marginTop: '-48px', } : {}} >
                <Box className={`makeReservation-summary2 ${isTabletScreen ? 'tablet' : ''} ${isDesktop ? 'desktop' : ''}`} mb={'24px'}>
                    <Box className='makeReservation-summary-header' >
                        {venue?.venueImage ? <img
                            className="makeReservation-summary-logo"
                            src={venue?.venueImage}
                            alt="venueImage"
                            style={isTabletScreen ? { borderRadius: 0, height: '280px', width: '100%' } : {}}
                        /> : <Skeleton
                            variant="rect"
                            width={isTabletScreen ? '280px' : isMobile ? '100%' : '368px'}
                            height={isTabletScreen ? '280px' : '368px'}
                            style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', transform: 'none' }}
                        />}
                        {isTabletScreen && isWithImages && <Button className="makePackageReservation-reservation-btn-view-all-v2 body-small" onClick={() => setIsGalleryOpen(true)}>
                            View All ({images.length})
                        </Button>}
                        {!isTabletScreen && isWithImages && <Button className='makeReservation-summary-zoom' onClick={() => setIsGalleryOpen(true)}>
                            <PanZoomIcon />
                        </Button>}
                    </Box>

                    <Box p={isTabletScreen ? '20px 24px' : '32px'}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'} mb={'8px'}>
                            <Typography className={`makeReservation-summary-title ${isTabletScreen ? 'tablet' : ''}`}>{venue?.name}</Typography>
                            {venuesForChange && venuesForChange.length > 1 &&
                                <>
                                    <Link onClick={handleOpenVenueMenu}
                                        className='makeReservation-summary-link mb-2px'>
                                        Change
                                    </Link>

                                    <Menu
                                        anchorEl={venueMenuAnchor}
                                        keepMounted
                                        open={Boolean(venueMenuAnchor)}
                                        onClose={handleCloseVenueMenu}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        style={{ marginTop: '38px' }}
                                        classes={{
                                            paper: classes.menu,
                                            list: classes.menuList
                                        }}
                                    >
                                        {venuesForChange.map((venueName, index) => (

                                            <MenuItem onClick={() => handleChangeVenue(venueName)} className={classes.menuItem} key={venueName.id}>
                                                {venue?.name === venueName.name && <Check style={{ position: 'absolute', left: 5, top: '50%', transform: 'translateY(-50%)', fontSize: '16px' }} />}
                                                {venueName.name}
                                            </MenuItem>
                                        ))
                                        }
                                    </Menu >
                                </>
                            }

                        </Box >
                        <VenueAddress venue={venue} />
                        <LinkIcons mt={'16px'} twitterLink={venue?.twitterUrl} instLink={venue?.instagramUrl} fbLink={venue?.facebookUrl} tiktokLink={venue?.tikTokUrl} />
                    </Box >

                    <Box>
                        <LinkWithIcon icon={<LocationOnIcon />} link={mapRef} title='Get Directions' target='_blank' />

                        {venuePhone ? <LinkWithIcon icon={<CallIcon />} link={`tel:${venuePhone}`} title={venuePhone} /> : null}

                        {venue?.viewMenuUrl && <LinkWithIcon icon={<RestaurantIcon />} link={venue?.viewMenuUrl} title='View Menu' target='_blank' />}
                    </Box>

                </Box >
                {(!isTabletScreen && !withoutPlanning) ? <PlanningEvent activeVenue={venue} /> : null}
            </Box >
            <GalleryModal onOpen={() => setIsGalleryOpen(true)} open={isGalleryOpen} onClose={() => setIsGalleryOpen(false)} venue={venue} ><></></GalleryModal>
        </>
    )
}

export default MakeReservationSummary