import React from "react";
import { DefaultButton, IButtonProps, IFontWeight } from "office-ui-fabric-react";
import { color } from "../../constants/styles";

interface OwnProps {
  backgroundColor?: string;
  hoverColor?: string;
  textColor?: string;
}

type Props = OwnProps & IButtonProps;

const LinkButton = ({
  children,
  backgroundColor,
  hoverColor,
  textColor,
  ...other
}: Props) => {
  const bgColor = backgroundColor ? backgroundColor : "transparent";
  const bgColorHovered = hoverColor ? hoverColor : "rgba(80, 70, 228, 0.04)";
  const txtColor = textColor ? textColor : color.blue;

  const linkButtonStyle = {
    root: {
      height: 40,
      background: bgColor,
      color: txtColor,
      border: "1px solid transparent",
      borderRadius: "6px",
      margin: "0px 3px",
    },
    rootHovered: {
      border: "1px solid transparent",
      outline: "none",
      background: bgColorHovered,
      color: txtColor,
    },
    rootFocused: {
      border: "1px solid transparent",
      outline: "none",
      boxShadow: "rgba(80, 70, 228, 0.25) 0px 0px 0px 0.2rem",
      background: bgColor,
      color: txtColor,
    },
    rootPressed: {
      border: "1px solid transparent",
      outline: "none",
      boxShadow: "rgba(80, 70, 228, 0.25) 0px 0px 0px 0.2rem",
      background: bgColor,
      color: txtColor,
    },
    textContainer: {
      fontSize: "13px",
      fontWeight: '500' as IFontWeight,
      lineHeight: 22,
      letterSpacing: '0.15px',
      fontFamily: "Inter, sans-serif",
    },
  };
  return (
    <DefaultButton styles={linkButtonStyle} {...other}>
      {children}
    </DefaultButton>
  );
};

export default LinkButton