import { Box, FormHelperText, Typography } from "@material-ui/core";
import {
    ITextStyles,
    Icon,
    Link
} from "office-ui-fabric-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { rem } from "../../../../../common/utils/formats";
import { isValidEmail } from "../../../../../common/utils/validate";
import { useViewport } from "../../../hooks/responsive";
import ButtonMain from "../../common/ButtonMain/ButtonMain";
import Input from "../../common/Input/Input";
import useAccount2Styles from "../Account/useAccount2Styles";
import "./recover.scss";

interface Props {
    recover: (email: string) => void;
    isInProgress: boolean;
    isRecoverCompleted: boolean;
    resetRecoverStatus: () => void;
    toLogin: () => void;
    cleanError: () => void;
    error?: string;
    uiConfig: any;
}

const textStyles: ITextStyles = {
    root: {
        margin: "0px 0px 26px",
        fontSize: rem(38),
        fontWeight: 900,
    },
};

export const Recover = ({
    recover,
    error,
    isInProgress,
    toLogin,
    cleanError,
    isRecoverCompleted,
    resetRecoverStatus,
    uiConfig,
}: Props) => {
    const { isMobile } = useViewport();
    const classes = useAccount2Styles()
    const [username, setUserName] = useState<string | undefined>("");
    const [errorUsername, setErrorUsername] = useState<string>("");
    useEffect(() => {
        resetRecoverStatus();
        cleanError();
        return () => {
            resetRecoverStatus();
            cleanError();
        };
    }, [cleanError, resetRecoverStatus]);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (username && isValidEmail(username) == true) {
            recover(username);
            return;
        }
        if (!username) {
            setErrorUsername("This field is required.");
        }
        if (username && isValidEmail(username) == false) {
            setErrorUsername("Please enter a valid email address.");
        }
    };
    const onUserNameChange = (e: ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorUsername("");
        setUserName(e.target.value);
    };
    return (
        <Box mt={'42px'} maxWidth={isMobile ? '100%' : '400px'} marginX={'auto'} textAlign={'center'} width={isMobile ? '100%' : '400px'} px={isMobile ? '24px' : 0}>
            <Typography className={`heading-h1 ${classes.title}`}>Forgot Password?</Typography>
            <Typography className="body-big">
                Enter your email address and we’ll send you a link to reset your password.
            </Typography>
            <form onSubmit={onSubmit}>

                {!isRecoverCompleted ?
                    <>
                        <Box display={'flex'} flexDirection={'column'} style={{ gap: '16px' }} my={'32px'}>
                            <Input type="email" errorInput={errorUsername} label="Email Address" placeholder='Enter your email' value={username} onChange={onUserNameChange} fullWidth />
                            {error && <FormHelperText className={`caption ${classes.error}`}>{error}</FormHelperText>}
                        </Box>
                        <ButtonMain type="submit" isMobileAttached={isMobile} disabled={isInProgress}>Continue</ButtonMain>
                    </>
                    :
                    <Box className="completed" my={'32px'}>
                        <Box className="completed-icon">
                            <Icon
                                iconName="CheckMark"
                                className="completed-icon-1"
                                aria-hidden="true"
                            />
                        </Box>
                        <Box className="completed-label">
                            You’re almost done - we’ve just sent you an email with a link to reset your password.
                        </Box>
                    </Box>
                }


                <Link className={`body-big ${classes.link} ${!isMobile && classes.returnLink}`} onClick={toLogin}>Return to Login</Link>
            </form>


        </Box>
    );
};
