export const openPopup = (url: string, onCloseCb?: CallableFunction, windowWidth = 1280, windowHeight = 800 ): Window | null => {
  const {
    screen: { width, height },
  } = window;
  const left = (width - windowWidth) / 2;
  const top = (height - windowHeight) / 2;
  const newWindow = window.open(
    url,
    "popup",
    `popup=true,width=${windowWidth},height=${windowHeight},left=${left},top=${top}`
  );
  
  const timerId = setInterval((newWindow) => {
    const isPopupClosed = !newWindow || newWindow.closed
    if (isPopupClosed) {
      clearInterval(timerId)
      onCloseCb?.()
    }
  }, 1000, newWindow)

  return newWindow
}