import React, { LegacyRef, } from 'react'
import { Modal, Box, ModalProps, makeStyles, Typography, Button } from '@material-ui/core'
import NoAvailiableGuests from '../../PackageCard/NoAvailiableGuests';
import ButtonMain from '../../common/ButtonMain/ButtonMain';
import { useViewport } from '../../../hooks/responsive';
import SwipeableBottomDrawer from '../../common/SwipeableDrawer/SwipeableBottomDrawer';
import { VenueDto } from '../../../../../server/src/dto/venue.dto';
import CloseIcon from '../../../../assets/close.svgr';
import Loader from '../../common/Loader/Loader';
interface Props extends ModalProps {
    containerRef: LegacyRef<HTMLDivElement>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    resNumber: string;
    title?: string;
    description?: string;
    caption?: string;
    buttonText?: string;
    onMainButtonClick: () => void;
    activeVenue?: VenueDto;
    onClose: () => void;
    fee?: string | number;
    isLoading?: boolean;
    selectOptions?: {
        key: string;
        text: string;
    }[]
}

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(243, 243, 245, 0.80)',
    },
}));


const AccountReservationHelperModal = ({ containerRef, setOpen, resNumber, title, description, caption, buttonText, isLoading, activeVenue, onMainButtonClick, onClose, fee, selectOptions, ...props }: Props) => {
    const classes = useStyles()
    const boxShadow = '0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04);'
    const handleNevermind = () => {
        setOpen(false)
    }
    const { isMobile } = useViewport()

    const renderContent = () => {
        return <Box
            className={classes.container}

        >
            <Loader open={isLoading as boolean} />

            <Box display={'flex'} flexDirection={'column'} maxHeight={'99vh'} style={{ overflowY: 'auto' }} borderRadius={'8px'}>
                <div ref={containerRef}>
                    <Box p={isMobile ? '24px' : '32px'}
                        pt={isMobile ? '0' : '32px'} bgcolor={'white'} width={'400px'} borderRadius={'4px'} boxShadow={boxShadow} display={'flex'} flexDirection={'column'} style={{ gap: '16px' }}>

                        <Box>
                            <Typography className='heading-h3' >
                                {title || 'Modify Reservation'}
                            </Typography>
                            <Typography className='root-text bold' >
                                {resNumber}
                            </Typography>
                        </Box>

                        <Typography className='root-text'>
                            {description || 'You will be redirected to the reservation page. In order to modify your reservation your will need to go through checkout again. If your changes result in a price difference you will either make a payment or recieve a refund for the difference.'}
                        </Typography>

                        {(!!fee) && <NoAvailiableGuests text={caption || 'Based on your new selection there may be an additional price difference.'} />}

                        <Box display={'flex'} mt={'8px'} style={{ gap: '16px' }}>
                            <ButtonMain outline onClick={handleNevermind}>Nevermind</ButtonMain>
                            <ButtonMain onClick={onMainButtonClick}>
                                {buttonText || 'Modify'}
                            </ButtonMain>
                        </Box>

                    </Box>

                </div>
            </Box>
        </Box>
    }

    if (isMobile) {
        return <SwipeableBottomDrawer onClose={onClose} open={props.open} isMakeReservation activeVenue={activeVenue}>
            <Box p={'32px 24px'} pb={'24px'}>
                <Button className='gallery-btn' onClick={onClose}>
                    <CloseIcon />
                </Button>
            </Box>
            {renderContent()}
        </SwipeableBottomDrawer>
    }

    return (

        <Modal {...props} style={{ zIndex: 99999999 }} className="addon-modal">
            {renderContent()}
        </Modal>
    )
}

export default AccountReservationHelperModal