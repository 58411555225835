import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../store/types';
import { VenueDto } from '../../../../../server/src/dto/venue.dto';
import { useViewport } from '../../../hooks/responsive';
import { Box, PaperProps } from '@material-ui/core';

const useStyles = makeStyles({
  drawer: {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    boxShadow: '0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04)'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  activeVenue?: VenueDto;
  isMakeReservation?: boolean
}


const SwipeableBottomDrawer = ({ open, onClose, children, activeVenue, isMakeReservation }: Props) => {
  const classes = useStyles();
  const uiConfig = useSelector((state: State) => state.uiState.uiConfig);
  const showDemo = uiConfig?.isDemo && !uiConfig.isHideDemoMessage;
  const { isMobile } = useViewport()
  const showSandBoxPayment = !showDemo && (activeVenue?.sandBoxPayment) && !window.location.pathname.includes('select-location')

  const withDemo = showDemo || showSandBoxPayment;


  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => { }}
      classes={{ paper: classes.drawer }}
      className='v2'
      PaperProps={
        {
          style: {
            height: !isMobile ? '95%' : isMakeReservation ? 'auto' : withDemo ? `calc(100% - 60px)` : 'calc(100% - 50px)',
            marginTop: !isMobile ? '5%' : isMakeReservation ? 'auto' : withDemo ? '60px' : '60px',
            overflow: 'visible',
            backgroundColor: 'white',
          }
        }
      }
    >
      <Box overflow={'auto'} height={'100%'} style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px' }}>
        {children}
      </Box>
    </SwipeableDrawer>
  );
};

export default SwipeableBottomDrawer;
