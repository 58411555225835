import React from "react";

type HTMLRendererWithScriptProps = {
  html?: string;
  className?: string;
};
const HTMLRendererWithScript = ({
  html = "",
  className = "",
}: HTMLRendererWithScriptProps) => {
  const divRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (html && divRef.current) {
      const node = document.createRange().createContextualFragment(html);
      divRef.current.appendChild(node);
    }
  }, [html]);

  return <div ref={divRef} className={className} />;
};

export default HTMLRendererWithScript;
