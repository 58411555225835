import { Box, Button, ButtonProps, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useViewport } from '../../../hooks/responsive';
import { State } from '../../../store/types';

interface Props extends ButtonProps {
    children: ReactNode;
    style?: React.CSSProperties;
    outline?: boolean;
    isMobileAttached?: boolean;
    buttonClassName?: string;
}

const useStyles = makeStyles({
    btnV2: {
        borderRadius: '4px',
        padding: '14px',
        width: '100%',
        border: 'none',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '28px',
        color: 'white',
        transition: 'all .3s ease',
        boxShadow: 'none',
        textTransform: 'none',

        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
        '&:disabled': {
            opacity: 0.7
        }
    },
    tablet: {
        maxHeight: '48px',

    }
});
const ButtonMain = ({ children, style, outline, isMobileAttached, buttonClassName, ...props }: Props) => {
    const uiConfig = useSelector((state: State) => state.uiState.uiConfig)

    const classes = useStyles()
    const { isTabletScreen } = useViewport()

    const defaultBlack = '#2E3A48'
    const defaultWhite = '#FFFFFF'

    const buttonStyle = {
        backgroundColor: outline ? defaultWhite : uiConfig?.mainButtonColor,
        color: outline ? defaultBlack : uiConfig?.mainButtonTextColor,
        border: `1px solid ${outline ? defaultBlack : uiConfig?.mainButtonColor}`,
        ...style
    }

    if (isMobileAttached) return <Box position={'fixed'} bottom={0} left={0} p={'24px'} width={'100%'} bgcolor={'white'} zIndex={100}>
        <Button className={classes.btnV2} style={buttonStyle} {...props}  >
            {children}
        </Button>
    </Box>


    return (
        <Button className={`${classes.btnV2} ${isTabletScreen ? classes.tablet : ""} ${buttonClassName}`} style={buttonStyle} {...props}  >
            {children}
        </Button>
    )
}

export default ButtonMain