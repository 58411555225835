import { Box, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import ActiveStepIcon from '../../../../assets/active-step.svgr';
import CheckedStepIcon from '../../../../assets/checked-step.svgr';
import InActiveStepIcon from '../../../../assets/inactive-step.svgr';
import { backToReservationAction } from "../../../actions/venue-actions";
import { useViewport } from "../../../hooks/responsive";
import { STEP } from "../../../store/types";
import "./multiStep.scss";
interface Step {
    label: number;
    name: string;
    handler: () => void;
}

interface MultiStepProps {
    activeStep: STEP;
    steps: Step[];
}
const mapDispatchToProps = {
    backToReservation: backToReservationAction,
};
const connector = connect(null, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & MultiStepProps;

interface StepProps {
    label: string;
    passed: boolean;
    active: boolean;
    onClick?: () => void;
}

const Step: React.FunctionComponent<StepProps> = ({
    label,
    active,
    passed,
    onClick,
}) => (
    <Box display={'flex'} mr={'4px'}>
        {passed ? (
            <IconButton
                onClick={onClick}
                className="focusMenuButton passed-btn-v2"
            >
                <CheckedStepIcon />
            </IconButton>
        ) : (
            active ? <ActiveStepIcon /> : <InActiveStepIcon />
        )}
    </Box>
);

const MultiStep = ({ activeStep, steps, backToReservation }: Props) => {
    const { isMobile } = useViewport();
    const handler = (step: Step, activeStep: number) => {
        if (step.label === STEP.INIT || activeStep === STEP.CONFIRMATION) {
            return backToReservation();
        }
        step.handler();
    };
    return (
        <Box className="reservationInfo-menu" mb={'16px'}>
            {steps.map((step) => (
                <React.Fragment key={step.label}>
                    <Step
                        label={`${step.label}`}
                        active={activeStep === step.label}
                        passed={activeStep > step.label}
                        onClick={
                            activeStep > step.label
                                ? () => handler(step, activeStep)
                                : () => null
                        }
                    />
                    {!isMobile && <Typography className={`step-name-v2 ${activeStep < step.label ? 'active' : 'inactive'}`}>{step.name}</Typography>}
                    {step.label !== steps.length && <hr className="line v2" />}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default connector(MultiStep);
