import React from "react";
import { Box, makeStyles, Theme, Tooltip, TooltipProps, Typography } from "@material-ui/core";

const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
        color: '#212121',
    },
    tooltip: {
        backgroundColor: '#212121',
        padding: '8px 12px',
        borderRadius: '4px',
        color: '#EFEFEF',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
    },
}));

const convertToMultiline = (text: string) => {
    return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
};


function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} title={<Box textAlign={'center'} >{convertToMultiline(props.title as string)}</Box>} />;
}

export default BootstrapTooltip;