import { Question } from '../dto/questionSetting.dto';
import { CustomFieldsType, QuestionType } from '../entities/enums';
import { getArrayFromString } from './common';

export interface customFieldsOptions {
  [key: string]: {
    fieldsName: string;
    text: string;
    type: CustomFieldsType;
  }
}

export const convertQuestionsToCustomFields = (questions: Question[]): customFieldsOptions => {
  const customFields: customFieldsOptions = {};

  questions.forEach((question) => {
    const key = question.internalName;
    customFields[key] = {
      fieldsName: question.internalName || '',
      text: question.displayName || '',
      type: convertQuestionTypeToCustomFieldsType(question.questionType),
    };
  });

  return customFields;
};

const convertQuestionTypeToCustomFieldsType = (questionType: QuestionType): CustomFieldsType => {
  switch (questionType) {
    case QuestionType.string:
      return CustomFieldsType.String;
    case QuestionType.dropdown:
      return CustomFieldsType.Selector;
    case QuestionType.multiSelect:
      return CustomFieldsType.Selector;
    default:
      return CustomFieldsType.String;
  }
};

// add to host app when changing
export const customFields: customFieldsOptions = {
  occasion: {
    fieldsName: 'Occasion',
    text: 'What`s the occasion?',
    type: CustomFieldsType.Occasion,
  },
  guestOfHonorName: {
    fieldsName: 'Guest of honor name',
    text: 'Guest of honor name',
    type: CustomFieldsType.String,
  },
  guestOfHonorBirthdate: {
    fieldsName: 'Guest of honor birthdate',
    text: 'Guest of honor birthdate',
    type: CustomFieldsType.Date,
  },
  howManyChaperones: {
    fieldsName: 'How many chaperones',
    text: 'How many non-racers or chaperones will there be?',
    type: CustomFieldsType.Number,
  },
  groupName: {
    fieldsName: 'Group name',
    text: 'Group name',
    type: CustomFieldsType.String,
  },
  address: {
    fieldsName: 'Address',
    text: 'Address',
    type: CustomFieldsType.String,
  },
  playerDetailsForSteltronic: {
    fieldsName: 'Player Details (Steltronic)',
    text: '',
    type: CustomFieldsType.PlayerDetailsForSteltronic,
  },
  playerDetailsNoShoesForSteltronic: {
    fieldsName: 'Player Details no shoes (Steltronic)',
    text: '',
    type: CustomFieldsType.PlayerDetailsNoShoesForSteltronic,
  },
  playerDetailsNoBumpersForSteltronic: {
    fieldsName: 'Player Details no bumpers (Steltronic)',
    text: '',
    type: CustomFieldsType.PlayerDetailsNoBumpersForSteltronic,
  },
  playerDetailsNoShoesNoBumpersSteltronic: {
    fieldsName: 'Player Details no shoes no bumpers (Steltronic)',
    text: '',
    type: CustomFieldsType.PlayerDetailsNoShoesNoBumpersSteltronic,
  },
  pizzaSelection: {
    fieldsName: 'Pizza Selection',
    text: 'Choose your pizza',
    type: CustomFieldsType.Selector,
  },
  playerDetailsForTripleSeat: {
    fieldsName: 'Player Details',
    text: '',
    type: CustomFieldsType.PlayerDetailsForTripleSeat,
  },
  guestNames: {
    fieldsName: 'Guest Names',
    text: '',
    type: CustomFieldsType.GuestNames,
  },
  gameSelection: {
    fieldsName: 'Game selection (lawn)',
    text: 'What game do you want to play first?',
    type: CustomFieldsType.GameSelection,
  },
  gameSelectionForStadium: {
    fieldsName: 'Game selection (Stadium)',
    text: 'What game do you want to play?',
    type: CustomFieldsType.GameSelectionForStadium,
  },
  diningSelection: {
    fieldsName: 'Dining Option',
    text: 'When do you want to eat?',
    type: CustomFieldsType.DiningSelection,
  },
  zipCode: {
    fieldsName: 'ZIP Code',
    text: 'ZIP Code',
    type: CustomFieldsType.String,
  },
  birthday: {
    fieldsName: 'Birthday',
    text: 'Birthday',
    type: CustomFieldsType.Birthday,

  },
  howDidYouHearAboutUs: {
    fieldsName: 'How did you hear about us?',
    text: 'How did you hear about us?',
    type: CustomFieldsType.String,
  },
}

type WithFeeFields = {
  serviceFee: string;
  serviceFeeName: string;
  serviceFeeType: string;
  taxForServiceFee: string;
};

type PackageWithFeeFields = {
  serviceCharge: string;
  serviceChargeName: string;
  serviceChargeType: string;
  taxForServiceCharge: string;
};

export const getFeeAsArray = (fee: WithFeeFields, rexPackage?: PackageWithFeeFields) => {
  if (rexPackage) {
    const serviceCharge = getArrayFromString(rexPackage.serviceCharge);
    return {
      serviceCharge,
      feesNames: getArrayFromString(rexPackage.serviceChargeName),
      feesTypes: getArrayFromString(rexPackage.serviceChargeType),
      taxForServiceCharge: getArrayFromString(rexPackage.taxForServiceCharge),
    };
  }
  const serviceCharge = getArrayFromString(fee.serviceFee);
  return {
    serviceCharge,
    feesNames: getArrayFromString(fee.serviceFeeName),
    feesTypes: getArrayFromString(fee.serviceFeeType),
    taxForServiceCharge: getArrayFromString(fee.taxForServiceFee),
  };
};
