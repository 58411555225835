import { useEffect, useRef, useState } from 'react';

const useAccordion = () => {
    const accordionDivRef = useRef<HTMLDivElement>(null);
    const [isShowMore, setIsShowMore] = useState(false);

    useEffect(() => {
        const updateAccordionHeight = () => {
            const contentDiv = accordionDivRef.current;

            if (contentDiv) {
                // Get all child elements inside the accordion
                const childElements = Array.from(contentDiv.children) as HTMLElement[];

                // Calculate total height including padding and margins
                const totalHeight = childElements.reduce(
                    (acc, element) => acc + element.offsetHeight,
                    0,
                );

                accordionDivRef.current!.style!.height! = isShowMore
                    ? `${totalHeight}px`
                    : '0';
            }
        };
        if (isShowMore) {
            updateAccordionHeight();
        } else {
            accordionDivRef.current?.style.setProperty('height', '0');
        }
    }, [accordionDivRef?.current?.innerHTML, isShowMore]);

    return { accordionDivRef, isShowMore, setIsShowMore };
};

export default useAccordion;
