import React, { useEffect, useCallback } from "react";
import { getSearch } from "connected-react-router";
import dayjs from "dayjs";
import { State } from "../../store/types";
import { connect, ConnectedProps } from "react-redux";
import { getClientAction } from "../../actions/auth-actions";
import { initialLoadVenuesAction } from "../../actions/venue-actions";
import { parseUrlQuery } from "../../utils/urlSearchQuery";
import { DATE_FORMAT } from "../../../../common/constants/timedate";

const mapDispatchToProps = {
  getClient: getClientAction,
  initialLoadVenues: initialLoadVenuesAction,
};
const mapStateToProps = (state: State) => ({
  searchParams: getSearch(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const AuthManager: React.FunctionComponent<Props> = ({
  searchParams,
  getClient,
  initialLoadVenues,
}: Props) => {
  const init = useCallback(() => {
    const { venue, date, guests, currentPackage, future, sp: targetPackage } = parseUrlQuery(searchParams);
    const dateShifting = future && !isNaN(+future) ? +future : 0;
    const parsedDate =
      date && date !== "undefined" ? date : dayjs().add(dateShifting, 'day').format(DATE_FORMAT);
    const resGuests = venue ? parseInt(guests, 10) || 1 : 1;
    const isPackages = window.location.pathname.includes('/package');
    initialLoadVenues({
      venue,
      date: parsedDate,
      guests: resGuests,
      isPackages,
    });
    getClient();
  }, [initialLoadVenues, getClient, searchParams]);
  useEffect(() => {
    init();
  }, []);
  return null;
};

export default connector(AuthManager);
