import { Box, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { useViewport } from '../../hooks/responsive';
import { State } from '../../store/types';
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    skeleton: {
        borderRadius: '4px',
        height: '64px',
        minHeight: '64px',
        maxHeight: '64px',
        width: '100%',
        backgroundColor: theme.palette.grey[200],
        transform: 'none',
    },
    tablet: {
        height: '48px',
        minHeight: '48px',
        maxHeight: '48px',
    }
}));
const TimeButtonsSkeletons = () => {
    const classes = useStyles();
    const { isMobile, isTabletScreen } = useViewport()
    const uiConfig = useSelector((state: State) => state.uiState.uiConfig)
    const showReservationTime = uiConfig?.showReservationTime || false;

    const elementNumber = (isMobile || showReservationTime) ? 6 : 12;
    let columns = (isMobile || showReservationTime) ? 2 : 4;

    const elementsNumber = Array.from({ length: elementNumber }, (_, index) => index + 1);

    return (
        <Box display={'grid'} gridTemplateColumns={'1fr '.repeat(columns)} gridGap={'11px'} width={'100%'}>
            {elementsNumber.map((element) => (
                <Skeleton
                    key={element}
                    className={`${classes.skeleton} ${isTabletScreen ? classes.tablet : ''}`}
                />
            ))}
        </Box>
    )
}

export default TimeButtonsSkeletons