import { CurrencyType } from "../../../store/types";

export const getEnabledPaymentMethods = (stripeAdditionalMethods: string, currency: CurrencyType): string[] => {
    if (!stripeAdditionalMethods) return [];
    const methods = stripeAdditionalMethods
        .split(';')
        .map(method => method.split(':'))
        .filter(([name, value]) => value.trim() === 'true')
        .map(([name]) => name.trim());


    if (currency !== CurrencyType.EUR) {
        return methods.filter(method => method !== 'iDeal');
    }
    return methods
};

export const convertToStripeMethod = (method: string): string | null => {
    switch (method) {
        case 'Afterpay':
            return 'afterpay_clearpay';
        case 'Amazon':
            return 'amazon_pay';
        case 'Cash App':
            return 'cashapp';
        case 'iDeal':
            return 'ideal';
        case 'Affirm':
            return 'affirm';
        case 'Klarna':
            return 'klarna';
        case 'Bank ACH':
            return 'us_bank_account';
        default:
            return 'card';
    }
};

export const availableMethods = [
    // 'Link',
    // 'Apple Pay',
    // 'Google',
    'Amazon',
    'Cash App',
    'iDeal',
    'Affirm',
    'Klarna',
    'Bank ACH',
]


export type StripeSavedCard = {
    id: string;
    object: string;
    allow_redisplay: string;
    billing_details: {
        address: {
            city: string | null;
            country: string | null;
            line1: string | null;
            line2: string | null;
            postal_code: string;
            state: string | null;
        };
        email: string | null;
        name: string;
        phone: string | null;
    };
    card: {
        brand: string;
        checks: {
            address_line1_check: string | null;
            address_postal_code_check: string;
            cvc_check: string;
        };
        country: string;
        display_brand: string;
        exp_month: number;
        exp_year: number;
        fingerprint: string;
        funding: string;
        generated_from: string | null;
        last4: string;
        networks: {
            available: string[];
            preferred: string | null;
        };
        three_d_secure_usage: {
            supported: boolean;
        };
        wallet: string | null;
    };
    created: number;
    customer: string;
    livemode: boolean;
    metadata: Record<string, any>;
    type: string;
};

