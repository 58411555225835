import { getSearch, goBack } from "connected-react-router";
import { ActionTypeEnum, AppThunk } from ".";
import { selectClient } from "../reducers/auth-reducer";
import { selectVenue } from "../reducers/venues";
import { ProfileDto, NewClient, NewGuest, UpdateClient } from "../store/types";
import {
  getGuestToken,
  getToken,
  getWithAuth,
  post,
  postWithAuth,
  setGuestToken,
  setToken,
} from "./api";
import {
  RESERVATION_URL,
  confirmReservationAction,
  pushUrlPathAction,
} from "./reservation-actions";
import { parseUrlQuery } from "../utils/urlSearchQuery";
import { subscribeToPlanAction } from "./membership-actions";
import { selectPlan } from "../reducers/membership";

const AUTH_URL = "/api/auth";

export interface Credentials {
  username: string;
  password: string;
  continueReservation?: boolean;
}
export type ClientWithToken = ProfileDto & { token: string };



export const loginAction = (credentials: Credentials): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const venue = selectVenue(getState());
      const plan = selectPlan(getState());
      const searchParams = getSearch(getState());
      const query = parseUrlQuery(searchParams);
      const isMemberships = !!query?.membership
      dispatch({ type: ActionTypeEnum.AuthRequest });
      const response = await post(`${AUTH_URL}/login`, credentials);
      const { token, ...client } = response.data;
      dispatch({
        type: ActionTypeEnum.AuthSetClient,
        payload: client,
      });
      setToken(token);
      setGuestToken("");
      if (isMemberships && plan) {
        dispatch(subscribeToPlanAction(plan, query?.isAnnual === "true"));
      }
      else if (credentials.continueReservation) {
        dispatch(pushUrlPathAction("/reservation-info/reservation-confirm"));
      }
      else {
        dispatch(goBack());
      }
      dispatch({
        type: ActionTypeEnum.AuthtorizeSession,
        payload: client,
      });
    } catch (e) {
      console.log("login error", e);
      dispatch({
        type: ActionTypeEnum.AuthFailure,
        payload:
          "Sorry. It looks like the user name or password is incorrect. Please try again.",
      });
    }
  };
};

export const loginCancellationAction = (credentials: Credentials): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.AuthRequest });
      const response = await post(`${AUTH_URL}/login`, credentials);
      const { token, ...client } = response.data;
      setToken(token);
      setGuestToken("");
      dispatch({
        type: ActionTypeEnum.AuthSuccess,
        payload: client,
      });
    } catch (e) {
      console.log("login cancellation error", e);
      dispatch({
        type: ActionTypeEnum.AuthFailure,
        payload:
          "Sorry. It looks like the user name or password is incorrect. Please try again.",
      });
    }
  };
};

export const recoverAction = (email: string): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.AuthRecoverRequest });
      const response = await post(`${AUTH_URL}/forget-password`, { email });
      const { status } = response.data;
      dispatch({
        type: ActionTypeEnum.AuthRecoverSuccess,
        payload: status,
      });
    } catch (e) {
      console.log("recover error", e);
      dispatch({
        type: ActionTypeEnum.AuthRecoverFailure,
        payload: "recover password error",
      });
    }
  };
};

export const resetRecoverStatusAction = () => ({
  type: ActionTypeEnum.AuthResetRecoverStatus,
});

export const getClientAction = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.AuthRequest });
      if (!getToken() && !getGuestToken()) {
        dispatch({
          type: ActionTypeEnum.AuthFailure,
          payload: "",
        });
        return;
      }
      const response = await getWithAuth(`${AUTH_URL}/profile`);
      const { token, ...client } = response.data;
      dispatch({
        type: ActionTypeEnum.AuthSuccess,
        payload: client,
      });
      setToken(token);
      setGuestToken("");
    } catch (e) {
      console.log("get profile error", e);
      dispatch({
        type: ActionTypeEnum.AuthFailure,
        payload: "",
      });
    }
  };
};

export const registerAction = (newClient: NewClient): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const venue = selectVenue(getState());
      const plan = selectPlan(getState());
      const searchParams = getSearch(getState());
      const query = parseUrlQuery(searchParams);
      const isMemberships = !!query?.membership
      dispatch({ type: ActionTypeEnum.AuthRequest });
      const response = await post(`${AUTH_URL}/register`, newClient);
      const { token, ...client } = response.data;
      dispatch({
        type: ActionTypeEnum.AuthSetClient,
        payload: client,
      });
      setToken(token);
      setGuestToken("");
      if (isMemberships && plan) {
        dispatch(subscribeToPlanAction(plan, query?.isAnnual === "true"));
      }
      if (newClient.continueReservation) {
        dispatch(pushUrlPathAction("/reservation-info/reservation-confirm"));
      } else {
        dispatch(goBack());
      }
      dispatch({
        type: ActionTypeEnum.AuthtorizeSession,
      });
    } catch (e) {
      console.log("registration error", e);
      dispatch({
        type: ActionTypeEnum.AuthFailure,
        payload: "Registration error. Please try another email.",
      });
    }
  };
};

export const continueAsGuestAction = (newGuest: NewGuest): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const venue = selectVenue(getState());
      dispatch({ type: ActionTypeEnum.GetGuest });
      const response = await post(`${AUTH_URL}/register-guest`, newGuest);
      const { token, ...guest } = response.data;
      dispatch({
        type: ActionTypeEnum.GetGuestSuccess,
        payload: guest,
      });
      setGuestToken(token);
      setToken("");
      dispatch(pushUrlPathAction("/reservation-info/reservation-confirm"));
    } catch (e) {
      console.log("guest registration error", e);
      dispatch({
        type: ActionTypeEnum.GetGuestFailure,
        payload: "Guest registration error. Please try another email.",
      });
    }
  };
};

export const authWithReservationToken = async ({
  id,
  resToken,
  dispatch,
}: {
  id: string;
  resToken: string;
  dispatch: any;
}) => {
  try {
    dispatch({ type: ActionTypeEnum.ReservationAuthRequest });
    const response = await post(`${RESERVATION_URL}/auth-with-token`, {
      token: resToken,
      id,
    });
    const { token, ...user } = response.data;

    dispatch({
      type: ActionTypeEnum.ReservationAuthSuccess,
      payload: user,
    });
    if (user.isClient) {
      setToken(token);
      setGuestToken("");
    } else {
      setGuestToken(token);
      setToken("");
    }
  } catch (e) {
    console.log("get authWithReservationToken error", e);
    dispatch({
      type: ActionTypeEnum.ReservationAuthFailure,
      payload: "",
    });
    dispatch(logoutAction());
  }
};

export const authWithReservationTokenAction = (
  id: string,
  resToken: string
): AppThunk => {
  return async (dispatch) => {
    return authWithReservationToken({
      id,
      resToken,
      dispatch,
    });
  };
};

export const logoutAction = (): AppThunk => {
  return async (dispatch) => {
    setToken("");
    dispatch({ type: ActionTypeEnum.Logout });
  };
};

export const logoutAsGuestAction = (): AppThunk => {
  return async (dispatch) => {
    setGuestToken("");
    dispatch({ type: ActionTypeEnum.LogoutAsGuest });
  };
};

export const updateProfileAction = (updatedClient: UpdateClient): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.UpdateProfileRequest });
      const response = await postWithAuth(`${AUTH_URL}/profile`, updatedClient);
      const { token, ...client } = response.data;
      dispatch({
        type: ActionTypeEnum.UpdateProfileSuccess,
        payload: client,
      });
    } catch (e) {
      console.log("update error", e);
      dispatch({
        type: ActionTypeEnum.UpdateProfileFailure,
        payload: "update failure",
      });
    }
  };
};

export const changePasswordAction = ({
  newPassword,
  currentPassword,
}: {
  newPassword: string;
  currentPassword: string;
}): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.ChangePasswordRequest });
      const response = await postWithAuth(`${AUTH_URL}/change-password`, {
        newPassword,
        currentPassword,
      });
      const { status } = response.data;
      dispatch({
        type: ActionTypeEnum.ChangePasswordSuccess,
        payload: status,
      });
    } catch (e) {
      console.log("change pass error", e);
      dispatch({
        type: ActionTypeEnum.ChangePasswordFailure,
        payload: "Unable to change password, please try again",
      });
    }
  };
};

export const resetPasswordAction = ({
  password,
  token,
}: {
  password: string;
  token: string;
}): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.ResetPasswordRequest });
      const response = await post(`${AUTH_URL}/reset-password`, {
        password,
        token,
      });
      const { status } = response.data;
      dispatch({
        type: ActionTypeEnum.ResetPasswordSuccess,
        payload: status,
      });
    } catch (e) {
      console.log("reset pass error", e);
      dispatch({
        type: ActionTypeEnum.ResetPasswordFailure,
        payload: "reset pass failure",
      });
    }
  };
};

export const cleanAuthErrorAction = () => ({
  type: ActionTypeEnum.CleanAuthError,
});

export const cleanIsShowVerificationCodeModalAction = () => ({
  type: ActionTypeEnum.SetIsShowVerificationCodeModal,
  payload: false,
});

export const getVerificationCodeMessageAction = (
  phone: string,
  venueId?: string
): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.GetVerificationCode });
      const response = await post(`${AUTH_URL}/phone-validation`, {
        phone,
        venueId,
      });
      dispatch({ type: ActionTypeEnum.GetVerificationCodeSuccess });
    } catch (e) {
      console.log("get verification code message error", e);
      dispatch({
        type: ActionTypeEnum.GetVerificationCodeFailure,
        payload: "get verification code message failure",
      });
    }
  };
};

export const confirmVerificationCodeAction = (
  phone: string,
  code: string,
  isUpdateClient: boolean,
  venueId?: string
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ActionTypeEnum.ConfirmVerification });
      const client = selectClient(getState());
      const clientId = !!isUpdateClient && !!client ? client.id : undefined;
      const response = await post(`${AUTH_URL}/confirm-phone-validation`, {
        phone,
        code,
        clientId,
        venueId,
      });
      dispatch({ type: ActionTypeEnum.ConfirmVerificationSuccess });
      dispatch(confirmReservationAction(true));
    } catch (e) {
      console.log("phone verification error", e);
      dispatch({
        type: ActionTypeEnum.ConfirmVerificationFailure,
        payload: "phone verification failure",
      });
    }
  };
};


export const clearAuthErrorAction = (): AppThunk => {
  return async (dispatch) => {
    dispatch({ type: ActionTypeEnum.CleanAuthError });
  };
}

