import React from "react";
import {Link} from "office-ui-fabric-react";
import logoIcon from "../../../../assets/poweredbyrex.svg";
import "./labelLink.scss";


const LabelLink = () => (
      <Link href="https://reservewithrex.com/?utm_source=powered-by" className="label-link" target="_blank">
      <img className="settings-icon" src={logoIcon} />
      </Link>
  );

export default LabelLink;
