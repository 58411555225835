import { Box, Typography } from '@material-ui/core';
import React from 'react';
import CustomTabs from './membershipTabs';
import { MembershipBilling } from '../../store/types/memberships';

interface Props {
    onAnnualPlanSelect: () => void;
    onMonthlyPlanSelect: () => void;
    title: string;
    description: string;
    selectedBilling: MembershipBilling;
    hideTabs?: boolean;
}

const MembershipsHeader = ({ onAnnualPlanSelect, onMonthlyPlanSelect, title, description, selectedBilling, hideTabs }: Props) => {

    const handleChangePlan = (plan: MembershipBilling) => {
        if (plan === MembershipBilling.MONTHLY) onMonthlyPlanSelect();
        if (plan === MembershipBilling.ANNUAL) onAnnualPlanSelect();
    }

    return (
        <Box mt={'46px'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
            <Typography className='heading-h1' style={{ marginBottom: '8px' }}>{title}</Typography>
            <Typography className='root-text' style={{ marginBottom: '30px', textAlign: 'center' }}>{description}</Typography>
            {!hideTabs ? <CustomTabs plan={selectedBilling} onChangePlan={handleChangePlan} /> : null}
        </Box>
    );
}

export default MembershipsHeader;
