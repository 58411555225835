import { Box, Button, ButtonProps, Typography } from '@material-ui/core'
import React from 'react'
import AmazonIcon from '../../../../assets/payment/amazon-pay.svgr'
import CashAppIcon from '../../../../assets/payment/cash-app.svgr'
import KlarnaIcon from '../../../../assets/payment/klarna.png'
import AffirmIcon from '../../../../assets/payment/affirm.svgr'
import IDealIcon from '../../../../assets/payment/ideal.png'
import BankACHIcon from '../../../../assets/payment/bankACH.svgr'
import AfterpayIcon from '../../../../assets/payment/afterpay-logo.svgr'
interface Props extends ButtonProps {
    children?: React.ReactNode,
    method?: string
}

const StripeCustomButton = ({ method, ...props }: Props) => {
    let icon;
    let styles = {};

    switch (method) {
        case 'Amazon':
            icon = <AmazonIcon width={'150px'} height={'34px'} />;
            styles = {
                backgroundColor: '#FF9900',
            }
            break;
        case 'Cash App':
            icon = <CashAppIcon width={'82px'} />;
            styles = {
                backgroundColor: 'black',
            }
            break;
        case 'iDeal':
            icon = <img src={IDealIcon} />
            styles = {
                backgroundColor: '#C06',
            }
            break;
        case 'Klarna':
            icon = <img src={KlarnaIcon} />
            styles = {
                backgroundColor: '#FFB1D2',
            }
            break;
        case 'Bank ACH':
            icon = <Box display={'flex'} alignItems={'center'} style={{ gap: '12px' }}> <BankACHIcon /> <Typography className='bank-ach'>Bank ACH</Typography></Box>;
            styles = {
                backgroundColor: '#000',
            }
            break;
        case 'Affirm':
            icon = <AffirmIcon width={'53px'} />;
            styles = {
                backgroundColor: '#4A4AF4',
            }
            break;
        case 'Afterpay':
            icon = <AfterpayIcon width={'150px'} />
            styles = {
                backgroundColor: "#B2FCE4"
            }
            break;
        default:
            icon = null;
            break;
    }


    return (
        <Button
            color="default"
            variant="text"
            style={{
                width: '100%',
                height: '48px',
                textTransform: 'none',
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...styles
            }}
            {...props}
        >
            {icon}
        </Button>
    )
}

export default StripeCustomButton