import { Box, Typography } from '@material-ui/core'
import React from 'react'
import DefaultContainer from '../../common/DefaultContainer/DefaultContainer'

import AmericanExpressIcon from '../../../../assets/payment/american-express.svgr'
import DiscoverIcon from '../../../../assets/payment/discover.svgr'
import MastercardIcon from '../../../../assets/payment/mastercard.svgr'
import VisaIcon from '../../../../assets/payment/visa.svgr'
import { useViewport } from '../../../hooks/responsive'

interface Props {
    children: React.ReactNode;
    isFortis?: boolean;
    isLoading?: boolean;
    isWithoutHeader?: boolean;
}
const PaymentContainer = ({ children, isFortis, isLoading, isWithoutHeader }: Props) => {
    const { isMobile } = useViewport()

    return (
        <DefaultContainer isMobile={isMobile} position={'relative'}>
            {isWithoutHeader ? null : <Box display={'flex'} justifyContent={'space-between'} alignItems={isMobile ? 'flex-start' : 'center'} mb={isMobile ? '24px' : '16px'} flexDirection={isMobile ? 'column' : 'row'} style={isMobile ? { gap: '16px' } : {}}>
                {(!isFortis) && <Typography className={isMobile ? 'heading-h1' : 'heading-h5'}>Credit Card</Typography>}
                {!isLoading && <Box display={'flex'} style={{ gap: '8px', ...(isFortis ? { position: 'absolute', right: isMobile ? 0 : '32px', top: isMobile ? '16px' : '44px' } : {}) }}>
                    <VisaIcon />
                    <MastercardIcon />
                    <AmericanExpressIcon />
                    <DiscoverIcon />
                </Box>}
            </Box>}
            {children}
        </DefaultContainer >
    )
}

export default PaymentContainer