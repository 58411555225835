import React, { FC, useState, useRef, CSSProperties, useEffect } from 'react';
import Arrow from '../../../assets/arrow-left.svgr';
import { useViewport } from '../../hooks/responsive';

export interface FilterValue {
    value: string;
}

interface Props {
    values: FilterValue[];
    filterValue: string;
    onFilterChange?: (selectedValue: string) => void;
    style?: CSSProperties
}

const FilterButtons: FC<Props> = ({ values, onFilterChange, filterValue, style }) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const { isMobile } = useViewport()

    const handleButtonClick = (value: string) => {
        if (onFilterChange) {
            onFilterChange(value);
        }
    };
    useEffect(() => {
        handleScroll()
    }, [])

    const handleScroll = () => {
        if (!scrollContainerRef.current) return;

        const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
        const offset = 3;

        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft <= scrollWidth - clientWidth - offset);
    };

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            const { scrollWidth, clientWidth } = scrollContainerRef.current;
            const rightMostScroll = scrollWidth - clientWidth - scrollContainerRef.current.scrollLeft;

            scrollContainerRef.current.scrollBy({
                left: rightMostScroll,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className="filter-buttons-container hide-scrollbar" style={style}>
            {!isMobile && <div onClick={scrollLeft} className={`left-arrow ${!showLeftArrow ? 'hidden' : ''}`}>
                <Arrow />
            </div>}
            <div onScroll={handleScroll} ref={scrollContainerRef} className="scroll-container hide-scrollbar" style={isMobile ? { paddingLeft: '24px' } : {}}>
                {values.map(({ value }) => (
                    <div
                        key={value}
                        onClick={() => handleButtonClick(value)}
                        className={`filter-button ${filterValue === value ? 'selected' : 'unselected'
                            }`}
                    >
                        <h3 style={{ whiteSpace: 'nowrap' }}>{value}</h3>
                    </div>
                ))}
            </div>
            {!isMobile && <div onClick={scrollRight} className={`right-arrow ${!showRightArrow ? 'hidden' : ''}`}>
                <Arrow />
            </div>}
        </div>
    );
};

export default FilterButtons;
