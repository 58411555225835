import { Box, BoxProps } from '@material-ui/core'
import React from 'react'
import FbIcon from '../../../assets/links/fb.svgr'
import InstIcon from '../../../assets/links/inst.svgr'
import TiktokIcon from '../../../assets/links/tiktok.svgr'
import TwitterIcon from '../../../assets/links/twitter.svgr'

interface Props extends BoxProps {
    fbLink?: string;
    instLink?: string;
    tiktokLink?: string;
    twitterLink?: string;
}
const LinkIcons = ({ fbLink, instLink, tiktokLink, twitterLink, ...props }: Props) => {
    if (!fbLink && !instLink && !tiktokLink && !twitterLink) return null
    return (
        <Box display={'flex'} alignItems={'center'} style={{ gap: '12px' }} {...props}>
            {fbLink && <a href={fbLink} target='_blank'>
                <FbIcon />
            </a>}
            {instLink && <a href={instLink} target='_blank'>
                <InstIcon />
            </a>}
            {tiktokLink && <a href={tiktokLink} target='_blank'>
                <TiktokIcon />
            </a>}
            {twitterLink && <a href={twitterLink} target='_blank'>
                <TwitterIcon />
            </a>}
        </Box>
    )
}

export default LinkIcons