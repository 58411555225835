import { UIConfigDto } from "../../../../server/src/dto/settings.dto";

export * from "../../../../server/src/dto/settings.dto";

export enum STEP {
  INIT = 1,
  ADDONS = 2,
  ACCOUNT = 3,
  RESERVATION = 4,
  PAYMENT = 5,
  CONFIRMATION = 6
}

export interface UIState {
  uiConfig?: UIConfigDto;
  bootstrapped: boolean;
  infoState: STEP;
}
