import { Pricing, DepositPercentageType, AddonType } from '../entities/enums';
import { AddonModifierDto } from './addonModifier.dto';
import { AddonsRelationsDto } from './addonsRelations.dto';

export class AddonDto {
  id: string;
  name: string;
  priority: number;
  description: string;
  shortDescription: string;
  required: boolean;
  minimumQuantity: number;
  maximumQuantity: number;
  price: number;
  depositPercentage: DepositPercentageType;
  image: string;
  method: Pricing;
  addonRelations: AddonsRelationsDto[];
  attributions: string;
  tags: string;
  addonType: AddonType;
  allowCustomerNotes: boolean;
  internalName: string;
  matchGuestCount: boolean;
  modifiers?: AddonModifierDto[];
  isMemberOnlyAddon: boolean;
  membershipPlanIds: string[]
}
