import React, { ChangeEvent, useEffect, useState } from "react";
import { isValidEmail } from "../../../../../common/utils/validate";
import { Credentials } from '../../../actions/auth-actions';
import { useViewport } from "../../../hooks/responsive";

import { Box, FormHelperText, Link, Typography } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import BoltIcon from '../../../../assets/bolt.svgr';
import { UIConfigDto } from "../../../store/types";
import ButtonMain from "../../common/ButtonMain/ButtonMain";
import ButtonWithIcon2 from "../../common/ButtonMain/ButtonWithIcon2";
import Input from "../../common/Input/Input";
import useAccount2Styles from "../Account/useAccount2Styles";
import "./login.scss";

interface Props {
    login: (credentials: Credentials) => void;
    isInProgress: boolean;
    toRecover: () => void;
    cleanError: () => void,
    continueReservation: boolean;
    error?: string;
    onCreateAccount: () => void;
    onCheckoutAsGuest: () => void;
    onRecover: () => void;
    onSignInWithPhone: () => void;
    showCheckoutAsGuest: boolean;
    uiConfig?: UIConfigDto
}

export const Login = ({
    login,
    toRecover,
    error,
    isInProgress,
    cleanError,
    continueReservation,
    onCreateAccount,
    onCheckoutAsGuest,
    onRecover,
    onSignInWithPhone,
    showCheckoutAsGuest,
    uiConfig
}: Props) => {
    const { isMobile } = useViewport();
    const classes = useAccount2Styles();
    const [username, setUserName] = useState<string | undefined>("");
    const [password, setPassword] = useState<string | undefined>("");
    const [errorUsername, setErrorUsername] = useState<string>("");
    const [errorPassword, setErrorPassword] = useState<string>("");
    useEffect(() => () => cleanError(), [cleanError])

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (
            username &&
            password &&
            isValidEmail(username) == true
        ) {
            login({ username, password, continueReservation });
            return;
        }
        if (!username) {
            setErrorUsername("This field is required.");
        }
        if (username && isValidEmail(username) == false) {
            setErrorUsername("Please enter a valid email address.");
        }
        if (!password) {
            setErrorPassword("This field is required.");
        }
    };
    const onUserNameChange = (e: ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorUsername("");
        setUserName(e.target.value);
    };
    const onPasswordChange = (e: ChangeEvent<{ name?: string | undefined; value: string; }>) => {
        setErrorPassword("");
        setPassword(e.target.value);
    }


    return (
        <Box maxWidth={isMobile ? '100%' : '400px'} marginX={'auto'} textAlign={'center'} width={isMobile ? '100%' : '400px'} px={isMobile ? '24px' : 0}>
            <Typography className={`heading-h1 ${classes.title}`}>Sign In</Typography>
            {!uiConfig?.hideCreateAccount && <Typography className="body-big">
                Don’t have an account?
                <Link className={classes.link} onClick={onCreateAccount}>Create an account</Link>
            </Typography>}
            <form onSubmit={onSubmit}>

                <Box display={'flex'} flexDirection={'column'} style={{ gap: '16px' }} my={'32px'}>
                    <Input type="email" errorInput={errorUsername} label="Email Address" placeholder='Enter your email' value={username} onChange={onUserNameChange} fullWidth />
                    <Input errorInput={errorPassword} isPassword label="Password" placeholder='Enter your password' value={password} onChange={onPasswordChange} fullWidth />
                    {error && <FormHelperText className={`caption ${classes.error}`}>{error}</FormHelperText>}
                    <Link className={`body-big ${classes.link} ${classes.textAlignLeft}`} onClick={toRecover}>Forgot password?</Link>
                </Box>


                <ButtonMain type="submit" isMobileAttached={isMobile} disabled={isInProgress}>Continue</ButtonMain>
            </form>

            <Box my={'32px'} position={'relative'}>
                <hr className={classes.lineThrough} />
                <Typography className={`body-small bold ${classes.or}`}>OR</Typography>
            </Box>

            <Box display={'flex'} flexDirection={'column'} style={{ gap: '20px' }}>
                {/* <ButtonWithIcon2 icon={<MobileFriendly style={{ color: '#212B36' }} />} onClick={onSignInWithPhone}>Sign in with phone</ButtonWithIcon2> */}
                {!uiConfig?.hideCreateAccount && <ButtonWithIcon2 icon={<Person style={{ color: '#212B36' }} />} onClick={onCreateAccount}>Create an account</ButtonWithIcon2>}
                {showCheckoutAsGuest && <ButtonWithIcon2 icon={<BoltIcon />} onClick={onCheckoutAsGuest}>Checkout as guest</ButtonWithIcon2>}
            </Box>
        </Box>
    );
};
