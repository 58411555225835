import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core'
import React from 'react'
import { VenueDto } from '../../../../server/src/dto/venue.dto'
import { useViewport } from '../../hooks/responsive'
import MaterialIcon from '../common/MaterialIcon/MaterialIcon'
import LinkWithIcon from './LinkWithIcon'

interface Props extends BoxProps {
    activeVenue?: VenueDto,
    descriptionProps?: TypographyProps
}


const PlanningEvent = ({ activeVenue, descriptionProps, ...props }: Props) => {
    const { isMobile, isTabletScreen } = useViewport();

    if (!activeVenue?.sidebarTitle && !activeVenue?.sidebarDescription) return null

    return (
        <Box className={`makeReservation-summary2 ${isMobile ? 'mobile' : ""}`} borderRadius={'8px'} {...props}>
            <Box p={isTabletScreen ? '24px' : '32px'}>
                <Typography className='makeReservation-summary-title v2'>{activeVenue?.sidebarTitle}</Typography>
                <Typography className='venue-address2 makeReservation-summary-desc' {...descriptionProps}>
                    {activeVenue?.sidebarDescription}
                </Typography>
            </Box>
            {activeVenue?.sidebarLinks.map((link, i) => (
                <LinkWithIcon noTopBorder={i !== 0} key={link + i} icon={<MaterialIcon icon={activeVenue?.sidebarIcons[i]} />} link={activeVenue?.sidebarUrls[i]} target="_blank" title={link} />
            ))}

        </Box>
    )
}

export default PlanningEvent