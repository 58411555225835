import { BoxProps } from '@material-ui/core';
import React, { useEffect } from 'react';
import NoAvailiableGuests from '../../PackageCard/NoAvailiableGuests';

interface Props extends BoxProps {
    error?: string;
}

const ErrorAlert = ({ error, ...props }: Props) => {
    const errorRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (errorRef.current) {
            errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [error])

    return (
        <div ref={errorRef}><NoAvailiableGuests noSpace text={error} {...props} /></div>
    )
}

export default ErrorAlert