import { createSelector } from "reselect";
import { ActionTypeEnum } from "../actions";
import {
  AddonQuantity,
  Availability,
  PackageName,
  State,
  Venue,
  VenueName,
  VenuesState,
} from "../store/types";
import { createReducer } from "./reducer-utils";
import { rexAnalytics } from "../utils/analytics";

const initialState: VenuesState = Object.freeze({
  venues: [],
  availability: {},
  isLoadingVenues: false,
  isLoadingVenue: false,
  isLoadingAvailability: false,
  currentPackage: undefined,
  addonQuantities: [],
  activeAddons: [],
  addons: [],
  isLoadingDateOrVenue: false,
  isLoadingPackageList: false,
});

export default createReducer<VenuesState>(initialState, {
  [ActionTypeEnum.GetVenues]: () => (state: VenuesState) => ({
    ...state,
    isLoadingVenues: true,
  }),
  [ActionTypeEnum.GetVenuesSuccess]:
    ({ venues, venue }: { venues: VenueName[]; venue: Venue }) =>
    (state: VenuesState) => {
      return {
        ...state,
        isLoadingVenues: false,
        venues,
        venue,
      };
    },
  [ActionTypeEnum.GetVenuesFailure]:
    (error: string) => (state: VenuesState) => ({
      ...state,
      isLoadingVenues: false,
      error,
    }),
  [ActionTypeEnum.GetVenue]:
    ({
      changePackageList,
      changeDateOrVenue,
    }: {
      changePackageList?: boolean;
      changeDateOrVenue?: boolean;
    }) =>
    (state: VenuesState) => ({
      ...state,
      isLoadingVenue: true,
      isLoadingPackageList: !!changePackageList,
      isLoadingDateOrVenue: !!changeDateOrVenue,
    }),
  [ActionTypeEnum.GetVenueSuccess]:
    ({ venue }: { venue: Venue }) =>
    (state: VenuesState) => ({
      ...state,
      isLoadingVenue: false,
      isLoadingDateOrVenue: false,
      isLoadingPackageList: false,
      venue,
    }),
  [ActionTypeEnum.GetVenueFailure]:
    (error: string) => (state: VenuesState) => ({
      ...state,
      isLoadingVenue: false,
      isLoadingDateOrVenue: false,
      isLoadingPackageList: false,
      error,
    }),
  [ActionTypeEnum.GetVenueWithAvailability]: () => (state: VenuesState) => ({
    ...state,
    isLoadingAvailability: true,
  }),
  [ActionTypeEnum.GetVenueWithAvailabilitySuccess]:
    ({ availability, venue }: { venue: Venue; availability: Availability }) =>
    (state: VenuesState) => ({
      ...state,
      isLoadingAvailability: false,
      availability,
      venue,
    }),
  [ActionTypeEnum.GetVenueWithAvailabilityFailure]:
    (error: string) => (state: VenuesState) => ({
      ...state,
      isLoadingAvailability: false,
      error,
    }),
  [ActionTypeEnum.LoadReservationSuccess]:
    ({ venue }: { venue: Venue }) =>
    (state: VenuesState) => {
      return {
        ...state,
        venue,
        error: undefined,
      };
    },
  [ActionTypeEnum.SetCurrentPackage]:
    (currentPackage?: PackageName) => (state: VenuesState) => {
      rexAnalytics.updateRexAnalyticsPackageName(currentPackage?.name);
      return {
        ...state,
        currentPackage,
      };
    },
  [ActionTypeEnum.SetAddonQuantities]:
    (addonQuantities: AddonQuantity[]) => (state: VenuesState) => ({
      ...state,
      addonQuantities,
    }),
  [ActionTypeEnum.SetActiveAddons]:
    (activeAddons: string[]) => (state: VenuesState) => ({
      ...state,
      activeAddons,
    }),
  [ActionTypeEnum.GetSquareAppId]:
    () =>
    (state: VenuesState): VenuesState => ({
      ...state,
      isLoadingVenue: true,
    }),
  [ActionTypeEnum.GetSquareAppIdSuccess]:
    () =>
    (state: VenuesState): VenuesState => ({
      ...state,
      isLoadingVenue: false,
    }),
  [ActionTypeEnum.GetSquareAppIdFailure]:
    (error?: string) =>
    (state: VenuesState): VenuesState => ({
      ...state,
      isLoadingVenue: false,
      error,
    }),
});

export const selectVenuesState = (state: State) => state.venues;
export const selectVenues = createSelector(
  selectVenuesState,
  (state: VenuesState) => state.venues
);
export const selectIsLoadingVenues = createSelector(
  selectVenuesState,
  (state: VenuesState) => state.isLoadingVenues
);
export const selectIsLoadingDateOrVenue = createSelector(
  selectVenuesState,
  (state: VenuesState) => state.isLoadingDateOrVenue
);
export const selectIsLoadingPackageList = createSelector(
  selectVenuesState,
  (state: VenuesState) => state.isLoadingPackageList
);
export const selectVenue = createSelector(
  selectVenuesState,
  (state: VenuesState) => state.venue
);
export const selectIsLoadingVenue = createSelector(
  selectVenuesState,
  (state: VenuesState) => state.isLoadingVenue
);
export const selectAvailability = createSelector(
  selectVenuesState,
  (state: VenuesState) => state.availability
);
export const selectIsLoadingAvailability = createSelector(
  selectVenuesState,
  (state: VenuesState) => state.isLoadingAvailability
);
export const selectCurrentPackage = createSelector(
  selectVenuesState,
  (state: VenuesState) => state.currentPackage
);

export const selectAddonsQuantities = createSelector(
  selectVenuesState,
  (state: VenuesState) => state.addonQuantities
);

export const selectActiveAddons = createSelector(
  selectVenuesState,
  (state: VenuesState) => state.activeAddons
);
