import React from "react";
import { IButtonProps, mergeStyleSets, IconButton } from "office-ui-fabric-react";
import { color } from "../../constants/styles";

const cancelButtonStyles = mergeStyleSets({
  root: {
    color: color.grey,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
    borderRadius: "6px",
  },
  rootHovered: {
    color: color.mainDark,
    backgroundColor: "rgba(80, 70, 228, 0.04)",
  },
});

const CancelButton = ({
  children,
  ...other
}: IButtonProps) => {
  
  return (
    <IconButton
      styles={cancelButtonStyles}
      iconProps={{ iconName: "Cancel" }}
      ariaLabel="Close popup modal"
      {...other}
    />
  );
};

export default CancelButton