import { Box, Button, Typography } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { FULL_DATE_FORMAT } from '../../../../../common/constants/timedate';
import { formatReservationDate, slotToTime } from '../../../../../common/utils/formats';
import { VenueDto } from '../../../../../server/src/dto/venue.dto';
import { FeeType, PaymentType } from '../../../../../server/src/entities/enums';
import AccountFilledIcon from '../../../../assets/account-filled.svgr';
import CalendarMonthIcon from '../../../../assets/calendar-month.svgr';
import defaultImage from '../../../../assets/default-image.jpg';
import { useViewport } from '../../../hooks/responsive';
import { Reservation, UIConfigDto } from '../../../store/types';
import { formatDate } from '../../MakePackageReservation/utils';
import AccountReservationHelperModal from './AccountReservationHelperModal';
import AccountReservationInfoModal from './AccountReservationInfoModal';
import ReservationStatusCaption from './ReservationStatusCaption';
import { getReservationTag } from './utils';

interface Props {
    reservation: Reservation;
    upcoming?: boolean;
    uiConfig?: UIConfigDto;
    showReservationModification: (id: string) => void;
    showReservationCancellation: (id: string) => void;
    activeVenue?: VenueDto;
    cancellationFeeType?: FeeType,
    cancellationFee?: string,
    cancelReservation?: (reservationId: string, reason: string) => void;
    isCancelLoading?: boolean;
    editReservation?: (reservation: Reservation) => void;
    feeForModification?: number,
    modificationFeeType?: FeeType,
    hideModification?: () => void;
}

const AccountReservation = ({ reservation, upcoming, uiConfig, cancelReservation, editReservation, activeVenue, cancellationFeeType, cancellationFee, showReservationCancellation, isCancelLoading, feeForModification, modificationFeeType, showReservationModification, hideModification }: Props) => {
    const { isMobile, isTabletScreen } = useViewport()
    const containerRef = useRef<HTMLDivElement>(null);

    const [isModifyModalOpen, setIsModifyModalOpen] = React.useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);


    useEffect(() => {
        // @ts-ignore
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // @ts-ignore
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const isUncaptured = reservation.status === "uncaptured";
    const isShowCancelReservationButton = reservation.allowCancellation && !isUncaptured;
    const isShowUpdateReservationButton = !isUncaptured &&
        reservation.paymentType !== PaymentType.sezzle &&
        reservation.allowModification;

    const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;

    const guestsText = reservation.guests > 1 ? `${reservation.guests} guests` : `${reservation.guests} guest`;
    const dateText = `${formatReservationDate(reservation, FULL_DATE_FORMAT)} at ${slotToTime(reservation.slots, reservation.timeSlotDuration, twelveHourClockFormat, reservation.timeSlotShifting)}`

    const feeInDollarsForCancel = cancellationFeeType === FeeType.PERCENT
        ? ((parseInt(cancellationFee || "") || 0) * (reservation?.deposit || 0) / 100).toFixed(2)
        : (parseInt(cancellationFee || "") || 0).toFixed(2);
    const feeInDollarsForModification = modificationFeeType === FeeType.PERCENT
        ? ((feeForModification || 0) * (reservation?.deposit || 0) / 100).toFixed(2)
        : (feeForModification || 0).toFixed(2);

    const cancellationAlertText = cancellationFeeType === FeeType.PERCENT
        ? `There is a ${cancellationFee}% charge ($${feeInDollarsForCancel}) for cancelling your reservation`
        : `There is a $${feeInDollarsForCancel} charge for cancelling your reservation`;

    const cancellationReasonsOptions = uiConfig?.cancellationReasons?.split(';').map(reason => ({
        key: reason,
        text: reason
    })) || [];
    let modificationFeeText = '';
    if (!!feeForModification) {
        modificationFeeText = modificationFeeType === FeeType.PERCENT
            ? `There is a ${feeForModification}% charge ($${feeInDollarsForModification}) for modifying your reservation. `
            : `There is a $${feeInDollarsForModification} charge for modifying your reservation. `;
    }
    const modificationAlertText = `${modificationFeeText}Based on your new selection there may be an additional price difference.`

    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsModifyModalOpen(false);
            setIsCancelModalOpen(false)
            setIsInfoModalOpen(false)
        }
    };

    const handleOpenModifyModal = () => {
        showReservationModification(reservation.id as string)
        setIsModifyModalOpen(true)
    }
    const handleOpenCancelModal = () => {
        showReservationCancellation(reservation.id as string)
        setIsCancelModalOpen(true);
    }
    const handleOpenInfoModal = () => {
        setIsInfoModalOpen(true)
    }

    const handleCancelReservation = () => {
        if (reservation && reservation.id && cancelReservation) {
            cancelReservation(reservation.id, cancellationReasonsOptions[0]?.key);
        }
    }

    const handleModifyReservation = () => {
        hideModification && hideModification();

        if (reservation && reservation.id && editReservation) {
            editReservation(reservation);
        }
    }

    return (
        <>
            <article className='package-card-v2'>

                <Box className={`package-card-v2-inside ${isMobile ? 'mobile' : ''}`}>

                    <Box className={`account-card__image-container-v2 ${isMobile ? 'mobile' : ''}`}>
                        <img src={reservation.venueImage || defaultImage} alt={reservation.number} className='package-card__image-v2' />
                    </Box>

                    <Box className={`account-card__content-v2 ${isMobile ? 'mobile' : ''}`}>
                        <Box>
                            <Box>
                                <Box className='package-card__content-top-v2'>
                                    <Typography className='package-card__title-v2  account-card__title-v2'>
                                        {reservation.venueName}
                                    </Typography>
                                    {(isMobile && !upcoming) && <ReservationStatusCaption status={getReservationTag(reservation)} />}
                                </Box>
                                <Typography className={`package-card__short-description-v2  account-card__subTitle-v2 ${isMobile ? 'mobile' : ''}`} >{reservation.number}</Typography>
                            </Box>
                            <Box className={`package-card__actions-v2`}>
                                <Box className='account-card-bottom-container'>
                                    <Box className='package-card__bottom'>
                                        <CalendarMonthIcon />
                                        <Typography className={`package-card__guests-v2`}>
                                            {formatDate(reservation.date, isMobile || isTabletScreen)}
                                        </Typography>
                                    </Box>

                                    {/* wrong icon sizing */}
                                    <Box className='package-card__bottom' >
                                        <AccountFilledIcon />
                                        <Typography className={`package-card__guests-v2`}>
                                            {guestsText}
                                        </Typography>
                                    </Box>

                                </Box>

                            </Box>
                        </Box>
                        <Box className={`account-card__right-container-v2  ${isMobile ? 'mobile' : ''}  ${(isTabletScreen && isShowUpdateReservationButton && isShowCancelReservationButton) ? 'tablet' : ''}`}>
                            {upcoming ? <>
                                {isShowUpdateReservationButton ? <Button className={`account-card__btn-v2 ${isMobile ? 'mobile' : ''}`} onClick={handleOpenModifyModal}>
                                    Modify
                                </Button> : null}
                                {isShowCancelReservationButton ? <Button className={`account-card__btn-v2 ${isMobile ? 'mobile' : ''}`} onClick={handleOpenCancelModal}>
                                    Cancel
                                </Button> : null}
                                <Button className={`account-card__btn-v2 ${isMobile ? 'mobile' : ''}`} onClick={handleOpenInfoModal} style={{ ...(!isShowCancelReservationButton && !isShowUpdateReservationButton && { marginTop: 'auto' }) }}>
                                    Info
                                </Button>
                            </> : <>
                                {!isMobile && <ReservationStatusCaption status={getReservationTag(reservation)} />}
                                <Button onClick={handleOpenInfoModal} className={`account-card__btn-v2 ${isMobile ? 'mobile' : ''}`} style={{ marginTop: 'auto' }}>
                                    Info
                                </Button>
                            </>}
                        </Box>
                    </Box>
                </Box>
            </article>
            <AccountReservationHelperModal
                resNumber={reservation.number!}
                setOpen={setIsModifyModalOpen}
                containerRef={containerRef}
                open={isModifyModalOpen}
                activeVenue={activeVenue}
                caption={modificationAlertText}
                onMainButtonClick={handleModifyReservation}
                fee={feeForModification}
                isLoading={isCancelLoading}
                onClose={() => setIsModifyModalOpen(false)}><></></AccountReservationHelperModal>
            <AccountReservationHelperModal
                resNumber={reservation.number!}
                setOpen={setIsCancelModalOpen}
                containerRef={containerRef}
                open={isCancelModalOpen}
                activeVenue={activeVenue}
                onMainButtonClick={handleCancelReservation}
                onClose={() => setIsCancelModalOpen(false)}
                title='Cancel Reservation'
                description='Are you sure you want to cancel your reservation?'
                caption={cancellationAlertText}
                buttonText='Cancel'
                fee={cancellationFee}
                isLoading={isCancelLoading}
                selectOptions={cancellationReasonsOptions}
            ><></></AccountReservationHelperModal>
            <AccountReservationInfoModal
                setOpen={setIsInfoModalOpen}
                containerRef={containerRef}
                open={isInfoModalOpen}
                onClose={() => setIsCancelModalOpen(false)}
                resNumber={reservation.number!}
                reservation={reservation}
                withVenueRequest
            ><></></AccountReservationInfoModal>
        </>
    )
}
export default AccountReservation