import React from "react";
import { Link } from "office-ui-fabric-react";
import { useViewport } from "../../../hooks/responsive";
import "./demoLink.scss";

interface DemoLinkProps {
  link: string;
  isSandBoxPayment?: boolean;
}

const DemoLink = ({
  link,
  isSandBoxPayment,
}: DemoLinkProps) => {
  const { isMobile } = useViewport();
  if (isSandBoxPayment) {
    return <Link href={link} className={`sand-box-link ${isMobile ? "mobile" : ""}`}>
      {isMobile
        ? (<><div>ⓘ Payments are in sandbox mode</div><div>and will not be processed.</div></>)
        : "ⓘ Payments are in sandbox mode and will not be processed."
      }
    </Link>
  }

  return (
    <Link href={link} className={`demo-link ${isMobile ? "mobile" : ""}`}>
      {isMobile
        ? (<><div>ⓘ This is a demo.</div><div>Click here to upgrade your plan.</div></>)
        : "ⓘ This is a demo. Click here to upgrade your plan."
      }
    </Link>
  );
};

export default DemoLink;
