import { Box, BoxProps, makeStyles, } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { useViewport } from '../../hooks/responsive';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '8px',
        width: '100%',
        display: 'flex',
        boxShadow: '0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04)'
    },
    skeleton: {
        backgroundColor: theme.palette.grey[200],
        transform: 'none',
        boxShadow: 'none',
    },
    skeletonImg: {

        height: '232px',
        minHeight: '232px',
        maxHeight: '232px',
        width: '232px',
        minWidth: '232px',
    },
    skeletonImgMobile: {

        height: '232px',
        minHeight: '232px',
        maxHeight: '232px',
        width: '100%',
        minWidth: '100%',
    },
    skeletonImgTablet: {
        height: '192px',
        minHeight: '192px',
        maxHeight: '192px',
        width: '192px',
        minWidth: '192px',
    },

    skeletonTitle: {
        height: '32px',
        minHeight: '32px',
        maxHeight: '32px',
        width: '100%',
    },
    skeletonPrice: {
        height: '32px',
        minHeight: '32px',
        maxHeight: '32px',
        width: '73px',
    },
    skeletonPriceHidden: {
        height: '32px',
        minHeight: '32px',
        maxHeight: '32px',
        width: '73px',

        visibility: 'hidden'
    },
    skeletonDescription: {
        height: '56px',
        minHeight: '56px',
        maxHeight: '56px',
        width: '100%',

    },
    skeletonDescriptionTablet: {
        height: '40px',
        minHeight: '40px',
        maxHeight: '40px',
        width: '100%',
    },
    skeletonBottom: {
        height: '48px',
        minHeight: '48px',
        maxHeight: '48px',
        width: '220px',
        minWidth: '220px',

    },
    skeletonBottomTablet: {
        height: '40px',
        minHeight: '40px',
        maxHeight: '40px',
        width: '220px',
        minWidth: '220px',
    },
    skeletonButton: {
        height: '48px',
        minHeight: '48px',
        maxHeight: '48px',
        width: '48px',
        minWidth: '48px',

    },
    skeletonButtonTablet: {
        height: '40px',
        minHeight: '40px',
        maxHeight: '40px',
        width: '40px',
        minWidth: '40px',
    }
}));

interface SkeletonElementProps {
    className: string;
}
const SkeletonElement: React.FC<SkeletonElementProps> = ({ className, }) => {
    const classes = useStyles();
    return <Skeleton className={`${classes.skeleton} ${className}`} />;
};

interface OwnProps {
    isMemberships?: boolean;
}

type Props = OwnProps & BoxProps;

const PackageSkeletons = ({ isMemberships, ...props }: Props) => {
    const elementNumber = 2;
    const classes = useStyles();
    const { isMobile, isTabletScreen } = useViewport()

    const elementsNumber = Array.from({ length: elementNumber }, (_, index) => index + 1);

    const containerSize = isMobile ? '100%' : isTabletScreen ? '192px' : '232px'
    return (
        <Box display={'flex'} flexDirection={'column'} style={{ gap: '32px' }} {...props}>
            {elementsNumber.map((element) => (
                <Box
                    key={element}
                    className={classes.card}
                    flexDirection={isMobile ? 'column' : 'row'}
                    minHeight={containerSize}
                    maxHeight={containerSize}
                    height={containerSize}
                >
                    <SkeletonElement className={isMobile ? classes.skeletonImgMobile : isTabletScreen ? classes.skeletonImgTablet : classes.skeletonImg} />
                    <Box p={isTabletScreen ? '24px' : '32px'} width={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                        <Box mb={isMobile ? '12px' : 0}>
                            <Box display={'flex'} style={{ gap: '16px' }} mb={'16px'}>
                                <SkeletonElement className={classes.skeletonTitle} />
                                <SkeletonElement className={classes.skeletonPrice} />
                            </Box>
                            <Box display={'flex'} style={{ gap: '16px' }} mb={'16px'}>
                                <SkeletonElement className={isTabletScreen ? classes.skeletonDescriptionTablet : classes.skeletonDescription} />
                                <SkeletonElement className={classes.skeletonPriceHidden} />
                            </Box>
                        </Box>
                        <Box>
                            <Box display={'flex'} style={{ gap: '8px' }} mb={'16px'} justifyContent={'space-between'}>
                                {isMemberships ? <Box /> : <SkeletonElement className={isTabletScreen ? classes.skeletonBottomTablet : classes.skeletonBottom} />}
                                <SkeletonElement className={isTabletScreen ? classes.skeletonButtonTablet : classes.skeletonButton} />
                            </Box>
                        </Box>

                    </Box>
                </Box>
            ))}
        </Box>
    )
}

export default PackageSkeletons