import React from "react";
import { DefaultButton, IButtonProps, IFontWeight } from "office-ui-fabric-react";
import { color } from "../../constants/styles";

const GrayButton = ({
  children,
  ...other
}: IButtonProps) => {
  const grayButtonStyle = {
    root: {
      height: 30,
      background: "rgba(80, 70, 228, 0.04)",
      color: color.dark1,
      border: "1px solid transparent",
      borderRadius: "6px",
      margin: "0px 3px",
      padding: 0,
      minWidth: 50,
      maxWidth: 50,
    },
    rootHovered: {
      border: "1px solid transparent",
      outline: "none",
      background: color.grey1,
      color: color.dark1,
    },
    rootFocused: {
      border: "1px solid transparent",
      outline: "none",
      boxShadow: "rgba(80, 70, 228, 0.25) 0px 0px 0px 0.2rem",
      background: "rgba(80, 70, 228, 0.04)",
      color: color.dark1,
    },
    rootPressed: {
      border: "1px solid transparent",
      outline: "none",
      boxShadow: "rgba(80, 70, 228, 0.25) 0px 0px 0px 0.2rem",
      background: "rgba(80, 70, 228, 0.04)",
      color: color.dark1,
    },
    textContainer: {
      fontSize: "12px",
      fontWeight: '400' as IFontWeight,
      lineHeight: 18,
      letterSpacing: '0.15px',
      fontFamily: "Inter, sans-serif",
    },
  };
  return (
    <DefaultButton styles={grayButtonStyle} {...other}>
      {children}
    </DefaultButton>
  );
};

export default GrayButton