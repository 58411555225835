export const color = {
  mainDark: "#000000",
  dark1: "#1E212A",
  dark2: "#121111",
  mainLight: "#ffffff",
  grey: "#506176",
  grey1: "#E1E3EA",
  grayText2: "#323130",
  grayText3: "#afafaf",
  grayDarkText: "#2a2a2a",
  grayBorder2: "#bfbfbf",
  grayBorder3: "#dcdcdc",
  grayBorder5: "#8a8886",
  grayBackground: "#f9f9f9",
  grayBackground4: "#f1f1f1",
  red: "#e21837",
  red3: "#D01632",
  blue: "#0624FF",
  grayBackground2: "#F6F7FA",
  text2: '#212B36'
};
