import { Box, Button, Modal, ModalProps, makeStyles } from '@material-ui/core';
import React, { LegacyRef, useEffect, } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import CloseIcon from '../../../../assets/close.svgr';
import { getVenueAction } from '../../../actions/venue-actions';
import { useViewport } from '../../../hooks/responsive';
import { selectGiftCardAmount, selectIsUpdateReservation, selectReservationAddons } from '../../../reducers/reservation';
import { selectUIConfig } from '../../../reducers/ui-reducer';
import { selectCurrentPackage, selectVenue } from '../../../reducers/venues';
import { Reservation, State } from "../../../store/types";
import SwipeableBottomDrawer from '../../common/SwipeableDrawer/SwipeableBottomDrawer';
import { OrderSummary } from '../../OrderSummary/OrderSummary';
interface Props extends ModalProps {
    containerRef?: LegacyRef<HTMLDivElement>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    resNumber: string;
    title?: string;
    description?: string;
    caption?: string;
    buttonText?: string;
    reservation: Reservation;
    onClose: () => void;
    isSummaryExpanded?: boolean;
    withVenueRequest?: boolean;
}

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(243, 243, 245, 0.80)',
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const mapDispatchToProps = {
    getVenue: getVenueAction,
};

const mapStateToProps = (state: State) => ({
    venue: selectVenue(state),
    uiConfig: selectUIConfig(state),
    giftCardAmount: selectGiftCardAmount(state),
    currentPackage: selectCurrentPackage(state),
    reservationAddons: selectReservationAddons(state),
    activeVenue: selectVenue(state),
    isUpdateReservation: selectIsUpdateReservation(state),

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ConnectedProp = ConnectedProps<typeof connector> & Props;


const AccountReservationInfoModal = ({ containerRef, isUpdateReservation, setOpen, resNumber, title, description, caption, buttonText, uiConfig, giftCardAmount, reservationAddons, reservation, getVenue, venue, currentPackage, onClose, activeVenue, isSummaryExpanded, withVenueRequest, ...props }: ConnectedProp) => {
    const classes = useStyles()
    const { isMobile, isTabletScreen } = useViewport();
    const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
    const isHideDuration = uiConfig?.hideDuration || currentPackage?.hidePackageDuration;
    useEffect(() => { if (withVenueRequest && !venue) getVenue(reservation.venueId) }, [reservation.venueId, withVenueRequest])
    const renderContent = () => {
        return <Box
            className={`${classes.container} ${!isMobile ? classes.flex : ''}`}
        >
            <Box display={'flex'} flexDirection={'column'} maxHeight={'99vh'} style={{ overflowY: isMobile ? 'visible' : 'auto' }} borderRadius={'8px'}>
                <div ref={containerRef}>
                    <OrderSummary
                        reservation={reservation}
                        venue={venue}
                        completed
                        onEdit={() => { }}
                        onCloseModal={() => setOpen(false)}
                        isHandleProgressAddons
                        actionText={uiConfig?.actionText}
                        isMobile={isMobile}
                        isHideDuration={isHideDuration}
                        giftCardAmount={giftCardAmount}
                        isUpdateReservation={isUpdateReservation}
                        oldDeposit={reservation?.payed}
                        currentPackage={currentPackage}
                        reservationAddons={reservationAddons}
                        twelveHourClockFormat={twelveHourClockFormat}
                        notShowEdit
                        isSummaryExpanded={isSummaryExpanded}
                        isModal={isTabletScreen}
                    />
                </div>
            </Box>
        </Box>
    }
    if (isMobile) {
        return (
            <SwipeableBottomDrawer open={props.open} onClose={onClose} activeVenue={activeVenue}>
                <Box p={'32px 24px'} pb={0}>
                    <Button className='gallery-btn' onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </Box>
                {renderContent()}
            </SwipeableBottomDrawer>
        )
    }
    return (
        <Modal {...props} style={{ zIndex: 99999999 }} className="addon-modal">
            {renderContent()}
        </Modal>
    )
}

export default connector(AccountReservationInfoModal)