import { Box, TextareaAutosize, TextareaAutosizeProps, makeStyles } from '@material-ui/core';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    textarea: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '28px',
        color: '#212B36',
        padding: '12px 18px',
        resize: 'none',
        backgroundColor: '#F6F7FA',
        border: '1px solid #F6F7FA',
        marginBottom: '16px',
        borderRadius: '4px',
        outline: 'none',
        transition: '0.2s all ease',
        '&::placeholder': {
            fontSize: '16px',
            lineHeight: '28px',
            color: '#3F4852',
            fontWeight: 400,
        },
        '&:focus': {
            border: '1px solid #0000001A',
            backgroundColor: '#EBEDF4',
        },
        '&:hover': {
            backgroundColor: '#EBEDF4',
        }
    },

    form: {
        backgroundColor: '#F6F7FA',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 23.5px',
        transition: 'all 0.2s ease',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: "#EBEDF4 !important",
        },
    },
    inputLabel: {
        marginLeft: '72px',
        // marginTop: '16px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '16px',
        color: '#3F4852',

        '& .MuiFormLabel-root.Mui-focused': {
            color: '#3F4852'
        },
    },
    focused: {
        marginTop: '16px',
        transform: 'translate(0, 12px) scale(0.75)',
        color: '#3F4852',

    },
    error: {
        color: '#EE4C1A',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
    }
}));

interface Props extends TextareaAutosizeProps {
    label?: string;
    fullWidth?: boolean;
    errorInput?: string;
    isPassword?: boolean
}

const TextArea2 = ({ label, fullWidth, errorInput, isPassword, ...props }: Props) => {
    const classes = useStyles();

    return (
        <Box display='flex' flexDirection='column' width={'100%'}>
            <TextareaAutosize minRows={4} className={classes.textarea} {...props} />
            {errorInput && <Box className={classes.error}>{errorInput}</Box>}
        </Box>)
}

export default TextArea2