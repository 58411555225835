import React from "react";
import { TextField, ITextFieldStyles } from "office-ui-fabric-react";
import { color } from "../../constants/styles";
import { rem } from "../../utils/formats";
import "../../styles/responsive.scss";

export const inputFieldStyle: ITextFieldStyles = {
  root: {
    width: "100%",
    padding: "20px 0 0",
  },
  fieldGroup: {
    height: rem(50),
    background: color.grayBackground,
    color: color.grayText2,
    border: `0.6px solid ${color.grayBorder2}`,
    borderRadius: 2,
    outline: 0,
  },
  errorMessage: {
    position: "absolute",
  },
  prefix: {},
  suffix: {},
  field: {
    paddingLeft: 18,
    fontSize: rem(16),
  },
  icon: {},
  description: {},
  wrapper: {},
  revealButton: {},
  revealSpan: {},
  revealIcon: {},
  subComponentStyles: {
    label: {
      root: {
        padding: "8px 0",
        fontSize: rem(16),
        color: color.grayDarkText,
      },
    },
  }
};

export const InputField = ({ ...other }) => {
  return <TextField styles={inputFieldStyle} className="focusableTextField" {...other} />;
};
