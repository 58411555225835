import { RouterState } from "connected-react-router";
import { AuthState } from "./auth";
import { MembershipState } from "./memberships";
import { ReservationState } from "./reservation";
import { UIState } from "./ui";
import { VenuesState } from "./venues";

export interface State {
  auth: AuthState;
  venues: VenuesState;
  reservation: ReservationState
  router: RouterState;
  uiState: UIState;
  membership: MembershipState
}

export * from "./auth";
export * from "./reservation";
export * from "./ui";
export * from "./urlSearchParams";
export * from "./venues";

