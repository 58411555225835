import { MembershipPlan } from "../../components/Memberships/entity";

export interface MembershipState {
  stripeClientSecret: string;
  stripePublishableKey: string;
  isRequestInProgress: boolean;
  plans: MembershipPlan[];
  selectedPlan?: MembershipPlan;
  error?: string;
  billing: MembershipBilling;
  singlePlan?: MembershipPlan;
}

export enum MembershipBilling {
  MONTHLY = 'monthly',
  ANNUAL = 'annual'
}
