import { makeStyles } from "@material-ui/core";

const useAccount2Styles = makeStyles((theme) => ({
    title: {
        marginBottom: '8px'
    },
    link: {
        color: '#2B5CBC',
        textDecorationLine: 'underline',
        marginLeft: '4px',
        cursor: 'pointer'
    },
    linkBlack: {
        textDecorationLine: 'underline',
        color: '#3F4852'
    },
    textAlignLeft: {
        textAlign: 'left',
    },
    or: {
        background: 'white',
        paddingInline: '8px',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 3,
    },
    lineThrough: {
        height: '1px',
        color: 'rgba(0, 0, 0, 0.05)',
        zIndex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',

    },
    error: {
        color: '#EE4C1A',
        fontSize: '12px'
    },
    procceding: {
        marginTop: '16px',
        marginBottom: '24px'
    },
    returnLink: {
        marginTop: '32px'
    },
    codeContainer: {
        width: '400px',
        display: 'flex',
        gap: '8px'
    },
    inputContainer: {
        color: '#212B36',
        textAlign: 'center',
        fontWeight: 450,
        lineHeight: '40px',
        fontSize: '28px',
        height: '64px',
        padding: '8px 10px',
        maxWidth: '57px',
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.10)',
        backgroundColor: '#F6F7FA',

        '&:focus-visible': {
            border: '1px solid rgba(0, 0, 0, 0.40)',
            outline: 'none',
        }
    }

}))

export default useAccount2Styles