import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { formatPriceBasedOnValue } from '../../../../../common/utils/formats'
import { AddonModifierDto } from '../../../../../server/src/dto/addonModifier.dto'
import { AddonOptionDto } from '../../../../../server/src/dto/addonOption.dto'
import { CurrencyType } from '../../../store/types'
import { ReservationAddonDto } from '../../../../../server/src/dto/reservationAddon.dto'
import { orderBy } from 'lodash'

interface Props {
    modifier: AddonModifierDto,
    currency: CurrencyType,
    selectedModifiers: AddonModifierDto[],
    setSelectedModifiers: (value: React.SetStateAction<AddonModifierDto[]>) => void;
}

const AddonSingleSelect = ({ modifier, currency, setSelectedModifiers, selectedModifiers }: Props) => {

    let singleSelectId: string | null = null
    let selectedModifier = selectedModifiers.find((mod) => mod.id === modifier.id)
    if(selectedModifier) {
        singleSelectId = selectedModifier.options[0].id
    }

    const handleChangeSingleSelect = (event: React.ChangeEvent<HTMLInputElement>, selectedId: string) => {
        const selectedOption = modifier.options.find(op => op.id === selectedId)  as AddonOptionDto
        if(!selectedModifier){
            setSelectedModifiers([...selectedModifiers, {...modifier, options: [selectedOption]}])
        }else{
            setSelectedModifiers(selectedModifiers.map(item=>item.id===modifier.id?{...modifier, options: [selectedOption]}:item))
        }
    }

    const options = orderBy(modifier.options||[], ['priority'], ['desc'])

    return (
        <RadioGroup
            aria-labelledby="Pcontrolled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={singleSelectId}
            onChange={handleChangeSingleSelect}
        >

            {options.map((option) => {
                return (
                    <Box
                        key={option.id}
                        display={'flex'}
                        borderBottom={'1px solid rgba(0, 0, 0, 0.05)'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        pt={'10px'}
                        pb={'10px'}
                    >
                        <FormControlLabel
                            value="end"
                            control={
                                <Radio
                                    color="default"
                                    style={{
                                        color: '#212B36'
                                    }}
                                    value={option.id} />
                            }
                            label={option.name}
                            labelPlacement="end"

                        />
                        <Typography style={{ fontSize: '16px', fontWeight: 400 }}>+{formatPriceBasedOnValue(option.price, currency)}</Typography>
                    </Box>
                );
            })}
        </RadioGroup>
    )
}

export default AddonSingleSelect