import React from "react";
import { DefaultButton, IButtonProps } from "office-ui-fabric-react";
import { rem } from "../../utils/formats";
import "../../styles/responsive.scss";

export type Props = {uiConfig: any} & IButtonProps;

export const PrimaryButton = ({ children, uiConfig, ...other }: Props) => {
  const mainButtonColor = uiConfig?.mainButtonColor;
  const mainButtonTextColor = uiConfig?.mainButtonTextColor;

  const primaryButtonStyle = {
    root: {
      height: rem(50),
      padding: 0,
      width: "100%",
      background: mainButtonColor,
      color: mainButtonTextColor,
      marginTop: 30,
      marginBottom: 30,
      border: "0",
    },
    textContainer: {
      fontSize: rem(16),
      fontFamily: "Roboto, bold",
    },
    rootHovered: {
      background: mainButtonColor,
      color: mainButtonTextColor,
      boxShadow:
        "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
      border: "0",
    },
    rootPressed: {
      border: "0",
    },
    rootDisabled: {
      opacity: 0.5,
    },
  };
  return (
    <DefaultButton
      className="focusableButton"
      styles={primaryButtonStyle}
      {...other}
    >
      {children}
    </DefaultButton>
  );
};

