import { useSelector } from "react-redux";
import { State } from "../store/types";

const useSettingColors = () => {
    const uiConfig = useSelector((state: State) => state.uiState.uiConfig);
    const bodyColor = uiConfig?.bodyBackgroundColor || '#F9F9FC'

    return {
        bodyColor
    };
}

export default useSettingColors