import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { ConnectedProps, connect } from "react-redux";
import { cancelReservationAction, editReservationAction, hideModificationAction, showReservationCancellationAction, showReservationModificationAction } from '../../../actions/reservation-actions';
import { useViewport } from '../../../hooks/responsive';
import { selectCancellationFee, selectCancellationFeeType, selectFeeForModification, selectIsRequestInProgress, selectModificationFeeType } from '../../../reducers/reservation';
import { selectUIConfig } from '../../../reducers/ui-reducer';
import { selectVenue } from '../../../reducers/venues';
import { Reservation, State } from '../../../store/types';
import AccountReservation from './AccountReservation';
import AccountReservationEmptyPlaceholder from './AccountReservationEmptyPlaceholder';
import { getReservationTag } from './utils';


const mapStateToProps = (state: State) => ({
    uiConfig: selectUIConfig(state),
    activeVenue: selectVenue(state),
    cancellationFee: selectCancellationFee(state),
    cancellationFeeType: selectCancellationFeeType(state),
    feeForModification: selectFeeForModification(state),
    modificationFeeType: selectModificationFeeType(state),
    isReqRequestInProgress: selectIsRequestInProgress(state)

});

const mapDispatchToProps = {
    showReservationModification: showReservationModificationAction,
    showReservationCancellation: showReservationCancellationAction,
    cancelReservation: cancelReservationAction,
    editReservation: editReservationAction,
    hideModification: hideModificationAction
};


const connector = connect(mapStateToProps, mapDispatchToProps);

type ConnectedProp = ConnectedProps<typeof connector>;

interface Props extends ConnectedProp {
    reservations: Reservation[]
}

const AccountReservations2 = ({ reservations, uiConfig, showReservationModification, showReservationCancellation, activeVenue, cancelReservation, cancellationFee, cancellationFeeType, isReqRequestInProgress, editReservation, feeForModification, modificationFeeType, hideModification }: Props) => {
    const { isMobile } = useViewport()

    const upComingReservations = reservations.filter((reservation) => getReservationTag(reservation) === 'Upcoming')
    const pastReservations = reservations.filter((reservation) => getReservationTag(reservation) !== 'Upcoming')

    return (
        <>
            <Box mb={isMobile ? '24px' : '48px'}>
                <Typography className="heading-h2 account-section-title">Upcoming Reservations</Typography>
                {upComingReservations.length && upComingReservations.length > 0 ? <Box display='flex' flexDirection={'column'} style={{ gap: '24px' }}>
                    {upComingReservations.map((reservation) => {
                        return <AccountReservation
                            key={reservation.id}
                            reservation={reservation}
                            upcoming
                            uiConfig={uiConfig}
                            showReservationCancellation={showReservationCancellation}
                            showReservationModification={showReservationModification}
                            activeVenue={activeVenue}
                            cancelReservation={cancelReservation}
                            cancellationFee={cancellationFee}
                            cancellationFeeType={cancellationFeeType}
                            isCancelLoading={isReqRequestInProgress}
                            editReservation={editReservation}
                            feeForModification={feeForModification}
                            modificationFeeType={modificationFeeType}
                            hideModification={hideModification} />
                    })}
                </Box> : <AccountReservationEmptyPlaceholder upcoming />}
            </Box>
            <Box mb={isMobile ? '24px' : '48px'}>
                <Typography className="heading-h2 account-section-title">Past Reservations</Typography>
                {pastReservations.length && pastReservations.length > 0 ? <Box display='flex' flexDirection={'column'} style={{ gap: '24px' }}>
                    {pastReservations.map((reservation) => {
                        return <AccountReservation
                            key={reservation.id}
                            reservation={reservation}
                            uiConfig={uiConfig}
                            showReservationCancellation={showReservationCancellation}
                            showReservationModification={showReservationModification}
                            activeVenue={activeVenue} />
                    })}
                </Box> : <AccountReservationEmptyPlaceholder />}
            </Box>
        </>
    )
}

export default connector(AccountReservations2)