import { PackageName, Pricing } from "../../store/types";
import { Accordion } from "./ThingsToKnow";


export function convertFaqsToArray(input: string): Accordion[] {
    const items: Accordion[] = [];

    const segments = input.split('|');

    for (const segment of segments) {
        const parts = segment.split('>>');
        if (parts.length === 2) {
            const heading = parts[0].trim();
            const details = parts[1].trim();

            if (heading !== '' || details !== '') {
                items.push({ heading, details });
            }
        }
    }

    return items;
}

export function formatDate(inputDate: string, isMobile?: boolean): string {
    if (inputDate === '') return '';
    let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    if (isMobile) {
        daysOfWeek = daysOfWeek.map(day => day.substring(0, 3));
        months = months.map(day => day.substring(0, 3));
    }


    const currentDate = new Date();
    const inputDateTime = new Date(`${inputDate}T00:00:00`);

    if (isNaN(inputDateTime.getTime())) {
        throw new Error('Invalid date format');
    }

    const isToday = (
        inputDateTime.getDate() === currentDate.getDate() &&
        inputDateTime.getMonth() === currentDate.getMonth() &&
        inputDateTime.getFullYear() === currentDate.getFullYear()
    );

    const dayOfWeek = daysOfWeek[inputDateTime.getDay()];
    const month = months[inputDateTime.getMonth()];
    const day = inputDateTime.getDate();

    if (isToday) {
        return `${dayOfWeek}, ${month} ${day} (Today)`;
    } else {
        return `${dayOfWeek}, ${month} ${day}`;
    }
}

export function getCurrentDateAsString(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const getAgeGroupsMinGuests = (currentPackage: PackageName, guests?: number): number => {
    const totalGuests = (currentPackage?.ageGroups &&
        (currentPackage?.method === Pricing.ageGroupsFlatRate ||
            currentPackage?.method === Pricing.ageGroupsPerHour))
        ? currentPackage.ageGroups.reduce((sum, ageGroup) => sum + ageGroup.minQty, 0)
        : (guests || currentPackage?.minGuests || 0);

    return totalGuests;
}
export const getGuestsWithAgeGroups = (currentPackage?: PackageName) => {
    const isAgeBased = (currentPackage?.ageGroups &&
        (currentPackage?.method === Pricing.ageGroupsFlatRate ||
            currentPackage?.method === Pricing.ageGroupsPerHour));

    if (!isAgeBased) return [];

    return currentPackage?.ageGroups?.map((group) => ({
        ageGroup: group.groupName,
        count: group.minQty,
    }))
}