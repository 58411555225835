import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    Typography,
    makeStyles
} from '@material-ui/core';
import { orderBy } from 'lodash';
import React, { ChangeEvent, Fragment, LegacyRef, useState } from 'react';
import { useSelector } from 'react-redux';
import HtmlRenderer from '../../../../../common/components/HTMLRenderer/HTMLRenderer';
import { AddonModifierDto } from '../../../../../server/src/dto/addonModifier.dto';
import { ReservationAddonDto } from '../../../../../server/src/dto/reservationAddon.dto';
import { VenueDto } from '../../../../../server/src/dto/venue.dto';
import { ModifierType } from '../../../../../server/src/entities/enums';
import CloseIcon from '../../../../assets/close.svgr';
import defaultImage from "../../../../assets/default-image.jpg";
import SelectIcon from '../../../../assets/select-icon.svg';
import TrashIcon from '../../../../assets/trash.svgr';
import { useViewport } from '../../../hooks/responsive';
import { Addon, CurrencyType, State } from '../../../store/types';
import ButtonMain from '../../common/ButtonMain/ButtonMain';
import TextArea2 from '../../common/Input/TextArea2';
import { mobileDropshodow } from '../../common/MobileAttachedButton/utils';
import SwipeableBottomDrawer from '../../common/SwipeableDrawer/SwipeableBottomDrawer';
import { AddonWithQuantity } from './AddonItem';
import AddonMultiSelect from './AddonMultiSelect';
import AddonSingleSelect from './AddonSingleSelect';
import { getSelectedModifiersAndOptions } from './utils';

interface Props {
    containerRef: LegacyRef<HTMLDivElement>;
    addon: Addon;
    price: string;
    quantityOptions: {
        key: string;
        text: string;
    }[];
    toggleSelected: (addon: Addon, isSelected: boolean, quantity: number, modifiers?: AddonModifierDto[], notes?: string) => void;
    setAddonsSelected: React.Dispatch<React.SetStateAction<AddonWithQuantity[]>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onRequestForSummary?: () => void;
    addonsSelected: ReservationAddonDto[];
    activeVenue?: VenueDto;
    open: boolean;
    onClose: () => void;
    reservationAddons: ReservationAddonDto[];

}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(243, 243, 245, 0.80)',
    },
    containerMobile: {
        width: '100%'
    },
    name: { fontSize: '24px', fontWeight: 600, maxWidth: '210px' },
    price: { fontSize: '24px', fontWeight: 600 },
    description: {
        color: '#3F4852', fontSize: '16px', fontWeight: 400, marginBottom: '16px'
    },
    modifierName: { color: '#3F4852', fontSize: '16px', fontWeight: 600 },
    choose: { color: '#212B36', fontSize: '14px', fontWeight: 400 },

    form: {
        backgroundColor: '#F6F7FA',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 24px',
        transition: 'all 0.2s ease',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: "#EBEDF4 !important",
        },
    },
    select: {
        color: '#212B36',
        fontWeight: 500,
        fontSize: '16px',
        width: '100%',
        borderBottom: 'none',
        backgroundColor: 'transparent !important',
        height: '56px',


    },
    inputLabel: {
        marginLeft: '72px',
        marginTop: '5px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '16px',
        color: '#3F4852'
    },
    focused: {
        marginTop: '10px',
    }
}));


const AddonSwipeableModal: React.FC<Props> = ({ containerRef, reservationAddons, addon, price, quantityOptions, toggleSelected, setAddonsSelected, activeVenue, setOpen, open, onClose, onRequestForSummary, addonsSelected, }) => {
    const uiConfig = useSelector((state: State) => state.uiState.uiConfig)
    const [selectedModifiers, setSelectedModifiers] = useState<AddonModifierDto[]>(addonsSelected.find(addonSelected => (addonSelected.addonId === addon.id) || (addonSelected.id === addon.id))?.modifiers || getSelectedModifiersAndOptions(addon, reservationAddons) || [])
    const currency = uiConfig?.currency || CurrencyType.USD;
    const { isMobile } = useViewport()

    const [quantity, setQuantity] = useState<string | null>(
        addon.matchGuestCount ?
            quantityOptions[0].text :
            addonsSelected.find(addonSelected => addonSelected.id === addon.id)?.quantity.toString() || '1'
    );
    const [quantityParam, setQuantityParam] = useState<{
        addonId: string;
        quantity: string;
    } | null>(null);
    const [notes, setNotes] = useState<string>(addonsSelected.find(addonSelected => addonSelected.id === addon.id)?.notes || '');
    const classes = useStyles();

    const handleChangeNotes = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value)
    }


    const handleClear = () => {
        // setAddonsSelected()
        toggleSelected(addon, false, 0, selectedModifiers)
        setAddonsSelected(addons => addons.filter(add => add.id !== addon.id))
        setQuantity('1')
        setSelectedModifiers([])

        setOpen(false);
        onRequestForSummary && onRequestForSummary()
    }
    const handleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        setQuantityParam({
            addonId: addon.id,
            quantity: event.target.value as string,
        })
        setQuantity(event.target.value as string)
    };


    const handleAdd = () => {
        toggleSelected(addon, true, Number(quantityParam?.quantity) || +quantityOptions[0].key, selectedModifiers, notes)
        setAddonsSelected(addons => [...addons, { ...addon, quantity: Number(quantity) }])

        setOpen(false);
        onRequestForSummary && onRequestForSummary()
    }

    const isAddingDisabled = (
        !quantity ||
        (selectedModifiers.some(modifier => modifier.options.length < (addon.modifiers?.find(mod => mod.id === modifier.id)?.minimum || 0))) || addon.modifiers?.some(modifier => modifier.minimum > 0 && !selectedModifiers.some(selectedModifier => selectedModifier.id === modifier.id))
    );
    const modifiers = orderBy(addon?.modifiers || [], ['priority'], ['desc']);

    return (
        <SwipeableBottomDrawer activeVenue={activeVenue} onClose={onClose} open={open}>
            <Box
                className={classes.containerMobile}
            >
                <Box display={'flex'} mt={'32px'} marginX={'24px'} justifyContent={'space-between'}>
                    <Button className='gallery-btn mobile' onClick={onClose}>
                        <CloseIcon />
                    </Button>
                    {(addonsSelected?.some(add => add.addonId === addon.id)) && <Button className='gallery-btn mobile' onClick={handleClear}>
                        <TrashIcon />
                    </Button>}
                </Box>
                <Box display={'flex'} flexDirection={'column'} borderRadius={'8px'} p="24px" pb={'120px'}>
                    <div ref={containerRef}>
                        <img src={addon.image || defaultImage} style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '8px' }} alt={addon.name} />
                        <Box bgcolor={'white'} width={'100%'} mt={'24px'}>
                            <Box display={'flex'} justifyContent={'space-between'} mb={'16px'}>
                                <Typography className={classes.name}>{addon.name}</Typography>
                                <Typography className={classes.price}>{price}</Typography>
                            </Box>
                            <HtmlRenderer className={classes.description} html={addon.description} />
                            {
                                modifiers.map(modifier =>
                                    <Fragment key={modifier.id}>
                                        <Box
                                            padding={'16px 24px'}
                                            bgcolor={'#F6F7FA'}
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            mb={'16px'}
                                        >
                                            <Box>
                                                <Typography className={classes.modifierName}>{modifier.name}</Typography>
                                                {modifier.required ? <>
                                                    {modifier.modifierType === ModifierType.multiSelect ?
                                                        <>
                                                            {(modifier.modifierType == ModifierType.multiSelect && modifier.minimum > 0) ? <Typography className={classes.choose}>Choose {modifier.minimum} to {modifier.maximum}</Typography> : null}
                                                            {(modifier.modifierType == ModifierType.multiSelect && modifier.minimum === 0) ? <Typography className={classes.choose}>Choose {modifier.maximum}</Typography> : null}
                                                        </>
                                                        : <>{(modifier.modifierType == ModifierType.singleSelect && modifier.minimum > 0) ? <Typography className={classes.choose}>Choose 1</Typography> : null}</>}
                                                </> : null}
                                            </Box>

                                            {modifier.required && <Box
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    color: '#3F4852',
                                                    padding: '2px 6px',
                                                    lineHeight: '24px'
                                                }}
                                            >
                                                Required*
                                            </Box>}
                                        </Box>

                                        <Box
                                            // overflow={'auto'} maxHeight={'240px'} 
                                            mb={'16px'} width={'350px'} pr={'20px'}>
                                            {modifier.modifierType === ModifierType.singleSelect ?
                                                <AddonSingleSelect selectedModifiers={selectedModifiers} setSelectedModifiers={setSelectedModifiers} currency={currency} modifier={modifier} /> :
                                                <AddonMultiSelect selectedModifiers={selectedModifiers} setSelectedModifiers={setSelectedModifiers} currency={currency} modifier={modifier} />}
                                        </Box>

                                    </Fragment>
                                )
                            }
                            {addon?.allowCustomerNotes && <TextArea2 placeholder='Type your notes here' value={notes as string} onChange={handleChangeNotes} />}

                            <Box position={'fixed'} width={'100%'} p={'24px'} bottom={0} left={0} bgcolor={'white'} style={{ filter: mobileDropshodow }}>

                                <Box display={'flex'} flexDirection={'row'} style={{ gap: '16px', height: '56px' }} className='v2'>
                                    <FormControl className={classes.form} style={{ padding: '11px 24px', width: '100%', cursor: 'pointer' }}>
                                        <InputLabel className={classes.inputLabel} classes={{ focused: classes.focused, shrink: classes.focused, }} style={{ marginLeft: '24px', }} variant="standard" htmlFor="uncontrolled-native">
                                            Quantity
                                        </InputLabel>
                                        <Select
                                            native
                                            value={quantity}
                                            onChange={handleChange}
                                            placeholder="Quantity"
                                            disableUnderline
                                            inputProps={{
                                                style: {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                            className={classes.select}
                                            IconComponent={() => <img src={SelectIcon} alt="SelectIcon" style={{ marginBottom: '12px' }} />}
                                        >
                                            {/* <option value={''} disabled >
                                            Quantity
                                        </option> */}
                                            {quantityOptions.map((quantity) =>
                                                <option key={+quantity.text} value={quantity.text}>{+quantity.text}</option>
                                            )}
                                        </Select>
                                    </FormControl>

                                    {/* <SelectWithIcon2 label='Quantity' width='100%' options={quantityOptions.map(q => q.text)} value={quantity}
                                    onChange={handleChange} /> */}
                                    <ButtonMain
                                        onClick={handleAdd}
                                        disabled={isAddingDisabled}
                                    >
                                        Add
                                    </ButtonMain>
                                </Box>
                            </Box>

                        </Box>
                    </div>
                </Box>
            </Box>
        </SwipeableBottomDrawer >
    );
};

export default AddonSwipeableModal;
