import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core';
import { maxTypographyStyle } from './utils';

interface Props {
    title: string,
    price: string,
    isTitleBold?: boolean,
    isPriceBold?: boolean,
}

const OrderSummaryLine: FC<Props> = ({ title, price, isTitleBold, isPriceBold }) => {
    return (
        <Box className='order-summary-card-header'>
            <Typography variant="subtitle2" className="order-summary-subTitle-alt " style={{ color: isTitleBold ? '#212B36' : '#3F4852', marginBottom: '4px', ...maxTypographyStyle }}>
                {title}
            </Typography>
            <Typography variant="subtitle2" className="order-summary-subTitle-alt without-margin" style={{ color: isPriceBold ? '#212B36' : '#3F4852', fontWeight: isPriceBold ? 600 : 400 }}>
                {price}
            </Typography>
        </Box>
    )
}

export default OrderSummaryLine