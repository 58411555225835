import React from "react";
import { DefaultButton, IButtonProps, IFontWeight } from "office-ui-fabric-react";
import { color } from "../../constants/styles";

interface OwnProps {
  textColor?: string;
}
type Props = OwnProps & IButtonProps;

const ColorButton = ({
  textColor,
  children,
  ...other
}: Props) => {
  const buttonColor = textColor ? textColor : color.blue;
  const borderColor = textColor ? textColor : 'rgba(6, 36, 255, 0.5)';
  const colorButtonStyle = {
    root: {
      height: 42,
      background: "transparent",
      color: buttonColor,
      border: `1px solid ${borderColor}`,
      borderRadius: "6px",
      margin: "0px 3px",
    },
    rootHovered: {
      border: `1px solid ${borderColor}`,
      outline: "none",
      background: "rgba(80, 70, 228, 0.04)",
      color: buttonColor,
    },
    rootFocused: {
      border: `1px solid ${borderColor}`,
      outline: "none",
      boxShadow: "rgba(80, 70, 228, 0.25) 0px 0px 0px 0.2rem",
      background: "transparent",
      color: buttonColor,
    },
    rootPressed: {
      border: `1px solid ${borderColor}`,
      outline: "none",
      boxShadow: "rgba(80, 70, 228, 0.25) 0px 0px 0px 0.2rem",
      background: "transparent",
      color: buttonColor,
    },
    textContainer: {
      fontSize: "13px",
      fontWeight: '500' as IFontWeight,
      lineHeight: 22,
      letterSpacing: '0.15px',
      fontFamily: "Inter, sans-serif",
    },
  };
  return (
    <DefaultButton styles={colorButtonStyle} {...other}>
      {children}
    </DefaultButton>
  );
};

export default ColorButton