import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';
import CheckBlackIcon from '../../../assets/check-black.svgr';
import { MembershipBilling } from '../../store/types/memberships';

interface Props {
    plan: MembershipBilling;
    onChangePlan: (plan: MembershipBilling) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    toggleButtonGroup: {
        borderRadius: '100px',
        border: '1px solid #79747E',
        overflow: 'hidden',

        '& .MuiToggleButton-root': {
            padding: '10px 12px',
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            textTransform: 'none',
            background: 'white',
            color: '#1D1B20',
            border: '1px solid #79747E',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    toggleButton: {
        minWidth: '147px',
        "&.Mui-selected, &.Mui-selected:hover": {
            color: 'white',
            backgroundColor: '#2E3A48'
        },
        '&:last-child': {
            borderRadius: '0px 100px 100px 0px',
        },
        '&:first-child': {
            borderRadius: '100px 0px 0px 100px',
        },
    }
}));

const MembershipTabs = ({ onChangePlan: onChange, plan }: Props) => {
    const classes = useStyles();

    const handlePlanChange = (event: React.MouseEvent<HTMLElement>, newPlan: MembershipBilling) => {
        if (newPlan !== null) {
            onChange(newPlan);
        }
    }

    const isMonthly = plan === MembershipBilling.MONTHLY;
    const isYearly = plan === MembershipBilling.ANNUAL;

    return (
        <ToggleButtonGroup exclusive value={plan} onChange={handlePlanChange} className={classes.toggleButtonGroup}>
            <ToggleButton value={MembershipBilling.MONTHLY} aria-label="monthly" className={classes.toggleButton}>
                <Box display={'flex'} style={{ gap: '8px' }}>
                    {isMonthly && <CheckBlackIcon />}
                    <Typography className={`m3-label-large`}>Billed monthly</Typography>
                </Box>
            </ToggleButton>
            <ToggleButton value={MembershipBilling.ANNUAL} aria-label="yearly" className={classes.toggleButton}>
                <Box display={'flex'} style={{ gap: '8px' }}>
                    <Typography className={`m3-label-large`}>Billed yearly</Typography>
                    {isYearly && <CheckBlackIcon />}
                </Box>
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

export default MembershipTabs;
