import { Typography } from '@material-ui/core'
import React, { ReactNode } from 'react'
import NoAvailiableGuests from '../PackageCard/NoAvailiableGuests'
import HtmlRenderer from '../../../../common/components/HTMLRenderer/HTMLRenderer'

interface Props {
    text: string | ReactNode
}

const VenueClosed = ({ text }: Props) => {
    const defaultMessage = <><span className='bold venue-closed-v2 span-red-v2'>Venue Closed</span>Online reservation are currently not available</>
    return (
        <NoAvailiableGuests mt={'24px'} borderRadius={'8px'} noSpace text={<Typography className='body-small venue-closed-v2'>{text || defaultMessage}</Typography>} />
    )
}

export default VenueClosed